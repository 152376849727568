import { LOCALEMAP } from "../constants";



export class LocaleService {

  private static instance: LocaleService;
  private localeData: any;

  public static init() {
    if (!LocaleService.instance) {
      LocaleService.instance = new LocaleService();
    }
    return LocaleService.instance;
  }

  private constructor() {
    this.localeData = {};
  }

  private getCookie(cname: any) {
    let name = cname + '=', decodedCookie = decodeURIComponent(document.cookie), ca = decodedCookie.split(';'), c;
    for (let i = 0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  public  getKeyByValue(object:any, value:any ){
    return Object.keys(object).find(key => object[key] === value);
  }

  public fetch() {
    var currentLocale = this.getCookie('locale') || 'en';
    var data = require(`../locales/${currentLocale}.json`);
    return data;
  }

  public getLocales() {
    return this.localeData;
  }

  public setLocales(currentLocaleData: any) {
    this.localeData = currentLocaleData;
  }

}
