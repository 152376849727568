import axios, { AxiosPromise } from "axios";
import { URLS, CLOUDIDS, INCIDENTPRIORITIES, API_KEY, REFRESH_TIME, MEMOIZE_TIME, LOCALEMAP } from '../constants';
import { parseDateToString, isToday, isYesterday, shortDate, hashCode } from '../utils';
import moment from "moment-timezone";
import { CasCadingFilterProp, Cloud, History, IncidentGroup, Message, Product, ServerHistory, ServerIncident, Status, EventType, Severity, CommunicationsHistory, EventSubscription, Incident, SubscriptionType, MessageType, Communications, Service, CustomerImpact, OperationsImpact, LocationImpact, StatusType } from '../proptype'
import { ServiceRegistry } from './service-registry';

declare global {
  interface Window {
    locale: any;
  }
}
export class EventService {
  private readonly cloudMapping: Array<Object> = [{
    id: CLOUDIDS.CREATIVE_CLOUD_ID,
    name: 'Creative Cloud',
    icon: 'creative_cloud',
    tab: 'creativeCloud'
  },
  {
    id: CLOUDIDS.EXPERIENCE_CLOUD_ID,
    name: 'Experience Cloud',
    icon: 'experience_cloud',
    tab: 'experienceCloud'
  },
  {
    id: CLOUDIDS.DOCUMENT_CLOUD_ID,
    name: 'Document Cloud',
    icon: 'document_cloud',
    tab: 'documentCloud'
  },
  {
    id: CLOUDIDS.ADOBE_SERVICES_ID,
    name: 'Adobe Services',
    icon: 'adobe_services',
    tab: 'adobeServices'
  },
  {
    id: CLOUDIDS.ADOBE_CLOUD_PLATFORM_ID,
    name: 'Adobe Experience Platform',
    icon: 'adobe_experience',
    tab: 'adobeExperiencePlatform'
  }];
  private static instance: EventService;
  private maintainences: Array<ServerIncident> = [];
  private incidents: Array<ServerIncident> = [];
  private globalCommRaw: Array<Communications> = [];
  private globalCommunications: Array<ServerIncident> = [];
  private messages: Message = {
    maintainences: {},
    incidents: {},
    globalComm: {}
  };
  private locales: any;
  private api_url: string;
  private registerCallback: Function | null;
  private intervalTimer: number | null;
  private _momoizeOngoingEvents: any = {};
  private _lastModified: string = '';
  private _expiryTime: any;
  private _promise: AxiosPromise | null | undefined;
  private _headPromise: Promise<string> | null | undefined;
  public static init() {
    if (!EventService.instance) {
      EventService.instance = new EventService();
    }

    return EventService.instance;
  }

  serviceRegistry_global: ServiceRegistry = ServiceRegistry.init();

  public setLocales(locales: any) {
    this.locales = locales;
  }

  private constructor() {
    this.api_url = URLS.EVENT_URL;
    this.intervalTimer = null;
    this.registerCallback = null;
  }

  private hasRightStatus(incident: ServerIncident) {
    if (!incident.status ||
      ![Status.Updated, Status.Canceled, Status.Closed, Status.Completed, Status.Opened, Status.Scheduled, Status.Started].includes(incident.status)
    ) {
      return false;
    }
    return true;
  }

  private hasRightHistoryStatus(latestMessage: History) {
    if (!latestMessage.status ||
      ![Status.Updated, Status.Canceled, Status.Closed, Status.Completed, Status.Opened, Status.Scheduled, Status.Started, Status.Discovery, Status.Dismissed].includes(latestMessage.status)
    ) {
      return false;
    }
    return true;
  }


  private getOngoingIncidentsByCloud(cloud: any, eventStatusFilter?: any): ServerIncident[] {
    var incidents: ServerIncident[] = [];
    [...this.incidents, ...this.maintainences, ...this.globalCommunications].forEach((incident: ServerIncident) => {
      if (!incident.clouds) return;
      // if (!this.hasRightStatus(incident)) {
      //   return;
      // }

      let clouds = Object.keys(incident.clouds);
      if ((incident.clouds && cloud === 'all') || (clouds.indexOf(cloud) !== -1) ||
        (cloud === CLOUDIDS.ADOBE_SERVICES_ID)) {

        if (incident.status !== Status.Scheduled) {
          if (!eventStatusFilter || (eventStatusFilter && eventStatusFilter.name === 'all')) {
            incidents.push(incident);
          }
          if (eventStatusFilter && eventStatusFilter.name !== "all" && -1 !== eventStatusFilter.values.indexOf(incident.status)) {
            incidents.push(incident);
          }
        }
      }

    });

    incidents.sort(function (a: any, b: any) {
      return b.startedOn - a.startedOn;
    });

    return incidents;
  }

  private priortisedStatusCloud(statusObj: any) {

    var that = this;

    let eventObjs = [],
      priortisedEvents = ['announcement', 'major', 'minor', 'potential', 'maintenance', 'closed_announcement', 'resolved', 'resolved_major', 'resolved_minor', 'resolved_potential', 'resolved_trivial', 'resolved_maintenance', 'canceled_maintenance'],
      countString;

    for (const element of priortisedEvents) {
      let currentEvent, count, localeString, loweredEvent = "", localeResult: any;
      currentEvent = element;
      loweredEvent = currentEvent.toLowerCase();

      if (statusObj.get(currentEvent)) {
        count = statusObj.get(currentEvent).length;
        if (currentEvent.indexOf('resolved') === -1 && currentEvent !== "closed_announcement") {
          if (currentEvent.indexOf('canceled') !== -1) {
            countString = (currentEvent === 'canceled_maintenance') ? '{canceledCount}' : '{count}';
            localeString = (currentEvent === 'canceled_maintenance') ? 'canceled_maintenance' : 'closedCSO';
          } else {
            localeString = (currentEvent === EventType.announcement) ? 'inProgressAnnouncement' : ((currentEvent === EventType.maintenance) ? 'maintenance_event' : 'inProgressCSO');
            countString = (currentEvent === EventType.maintenance) ? '{maintenanceCount}' : '{count}';
          }
        } else {
          if (currentEvent !== 'resolved_maintenance' && currentEvent.includes("_")) {
            loweredEvent = currentEvent.split("_")[1];
          }

          countString = (currentEvent === 'resolved_maintenance' || currentEvent === "closed_announcement") ? '{resolvedCount}' : '{count}';
          localeString = (currentEvent === "closed_announcement") ? 'closed_announcement' : ((currentEvent === 'resolved_maintenance') ? 'resolved_maintenance' : 'closedCSO');
        }
        if (count > 1) {
          localeString += 's';
        }
        localeResult = that.locales[localeString as keyof typeof that.locales]

        // Special case Italian language
        let italian_progress_announcements = false;
        if (window.locale === "it-it" && localeString === "inProgressAnnouncements" && count > 1) {
          italian_progress_announcements = true;
        }
        eventObjs.push({
          eventType: currentEvent.toLowerCase(),
          eventString: localeResult.replace(countString, count).replace('{eventType}', italian_progress_announcements ? that.locales["event_type_messages"] : loweredEvent === "trivial" ? that.locales[loweredEvent] : that.locales[loweredEvent + '_banner' as keyof typeof that.locales])
        });
      }
    }
    return eventObjs;
  }

  private getImpactedDetails(message: any, incident: any) {
    let impacts: any = {};
    if (message.serviceImpact) {
   
      if (message.serviceImpact.productOfferings && message.serviceImpact.productOfferings.length) {
        impacts.productOfferings = message.serviceImpact.productOfferings.map((offeringId: any) => {
          let offeringName = (incident.offerings && incident.offerings[offeringId]?.name) || '',
            services = incident.offerings && incident.offerings[offeringId]?.productServices,
            serviceString = '';
          if (services && services.length) {
            if(message?.serviceImpact && message?.serviceImpact?.productServices){
              services.filter((serviceId: any) => message.serviceImpact.productServices.includes(serviceId))
            }
            serviceString = services
              .map((serviceId: any) => (incident.services && incident.services[serviceId] && message.serviceImpact.productServices.includes(serviceId) ? (incident.services[serviceId]?.name) : ""))
              .filter((str: string) => str.trim())
              .join(', ');
          }
          if (serviceString && offeringName) {
            return offeringName + '(' + serviceString + ')';
          } else {
            return offeringName;
          }
        }).filter((str: string) => str.trim()).join(', ');
      }
      if (message.serviceImpact.productServices && message.serviceImpact.productServices.length) {
        impacts.impactedServices = message.serviceImpact.productServices
          .map((service: any) => (incident.services && incident.services[service]?.name) || '')
          .filter((str: string) => str.trim())
          .join(', ');
      }
      if (message.serviceImpact.productOfferings && message.serviceImpact.productOfferings.length && message.serviceImpact.productServices && message.serviceImpact.productServices.length) {
        var serviceIds: any = [];
        impacts.productOfferings = message.serviceImpact.productOfferings.map((offeringId: any) => {
          let offeringName = (incident.offerings && incident.offerings[offeringId]?.name) || '',
            services = incident.offerings && incident.offerings[offeringId]?.productServices,
            serviceString = '';
          if (services && services.length) {
            services.forEach((serviceId: any) => {
              if (incident.services && incident.services[serviceId]?.id) {
                if (message.serviceImpact?.productServices && message.serviceImpact?.productServices.includes(serviceId)) {
                  serviceIds.push(incident.services[serviceId]?.id);
                }
              }
            })
            serviceString = services
              .map((serviceId: any) => (incident.services && incident.services[serviceId] && message.serviceImpact.productServices.includes(serviceId) ? (incident.services[serviceId]?.name) : ""))
              .filter((str: string) => str.trim())
              .join(', ');
          }
          if (serviceString && offeringName) {
            return offeringName + '(' + serviceString + ')';
          } else {
            return offeringName;
          }
        }).filter((str: string) => str.trim()).join(', ');

        if (message.serviceImpact.productServices && message.serviceImpact.productServices.length) {
          impacts.impactedServices = message.serviceImpact.productServices
            .map((service: any) => {

              if (serviceIds.includes(incident.services[service].id) === false) {
                return (incident.services && incident.services[service]?.name) || '';
              } else {
                return '';
              }
            })
            .filter((str: string) => str.trim())
            .join(', ');
        }
        if (impacts.impactedServices) {
          impacts.productOfferingsAndServics = impacts.productOfferings.concat(", ", impacts.impactedServices)
        } else {
          impacts.productOfferingsAndServics = impacts.productOfferings
        }
      }
      if (message.locationImpact && message.locationImpact.serviceEnvironments && message.locationImpact.serviceEnvironments.length) {
        impacts.environmentImpacted = message.locationImpact.serviceEnvironments
          .map((env: any) => (incident.environments && incident.environments[env]?.name) || '')
          .filter((str: string) => str.trim())
          .join(', ');
      }
    }
    return impacts;
  }

  // case1: multiple products - done
  // case2: single product - done 
  // case3: maintenence - done
  private mapIncident(serverIncident: ServerIncident, productId?: string) {
    var incidents: Incident[] = [],
      incidentInit: Incident = {
        clouds: serverIncident.clouds,
        endedOn: 0,
        start: '',
        startedOn: 0,
        eventType: '',
        productId: '',
        incidentId: serverIncident.id,
        name: '',
        products: serverIncident.products,
        offerings: serverIncident.offerings,
        productsAssociated: serverIncident.products ? Object.values(serverIncident.products).map((product: Product) => { return { id: product.id, name: product.name } }) : [],
        productsString: serverIncident.products ? Object.values(serverIncident.products).map((product: Product) => product.name).sort().join(", ") : '',
        regionsInvolved: '',
        services: serverIncident.services,
        status: Status.Opened,
        previousStatus: Status.Opened,
        severity: '',
        updated: '',
        ended: '',
      }, incident: Incident = { ...incidentInit };

    if (serverIncident.type) {
      if (serverIncident.type === EventType.global.toString()) {
        return [this.updateGlobalCommProps(incident, serverIncident, "All Clouds", "", "All Products", "")];
      }
      else if (serverIncident.type === EventType.cloud.toString()) {
        for (const [cloud_id, cloud_details] of Object.entries(serverIncident.clouds)) {
          if (productId) {
            if (this.isParentCloud(productId, cloud_id)) {
              let mappedIncident = this.updateGlobalCommProps(incident, serverIncident, cloud_details.name, cloud_id, "All Products", "");
              incident = { ...incidentInit };
              if (mappedIncident) {
                incidents.push(mappedIncident);
              }
            }
          }
          else {
            let mappedIncident = this.updateGlobalCommProps(incident, serverIncident, cloud_details.name, cloud_id, "All Products", "");
            incident = { ...incidentInit };
            if (mappedIncident) {
              incidents.push(mappedIncident);
            }

          }

        }
        return incidents;
      }
      else if (serverIncident.type === EventType.product.toString()) {
        for (const [product_id, product_details] of Object.entries(serverIncident.products)) {

          if (productId) {
            if (product_id !== productId) {
              continue;
            }
          }
          let mappedIncident = this.updateGlobalCommProps(incident, serverIncident, product_details.cloudName || "", product_details.cloudId || "", product_details.name, product_id);
          incident = { ...incidentInit };
          if (mappedIncident) {
            incidents.push(mappedIncident);
          }
        }
        return incidents;
      }

    }
    let firstProduct: Product = serverIncident.products[Object.keys(serverIncident.products)[0]],
      latestMessage = this.getLatestHistory(firstProduct);

     if (productId) { // single product(i.e product page)
        if (serverIncident.products[productId]) {
          let mappedIncident = this.updateProps(incident, serverIncident.products[productId], serverIncident, !latestMessage.severity?true:false);
          if (mappedIncident) {
            return [mappedIncident];
          }
        }
      } else { // multiple product(i.e overview)
         for (const id of Object.keys(serverIncident.products)) {
          let mappedIncident = this.updateProps(incident, serverIncident.products[id], serverIncident, !latestMessage.severity?true:false)
          incident = { ...incidentInit };
          if (mappedIncident) {
            incidents.push(mappedIncident);
          }
        }
        return incidents;
      }
    return [];
  }

  private updateGlobalCommProps(incident: Incident, serverIncident: ServerIncident, cloudName: string, cloudId: string, product: string, productId: string) {
    incident.eventType = serverIncident.type || '';
    incident.startedOn = serverIncident.startedOn || 0;
    incident.endedOn = serverIncident.completedOn || 0;
    incident.start = parseDateToString(moment.unix(serverIncident.startedOn || 0), this.locales);
    incident.incidentId = serverIncident.id;
    incident.history = serverIncident.history;
    incident.cloudName = cloudName;
    incident.cloudId = cloudId;
    incident.name = product;
    incident.productId = productId;
    incident.status = serverIncident.status;
    incident.ended = parseDateToString(moment.unix(serverIncident.completedOn || 0), this.locales);

    let messageTimes: string[], latestMessage: History;

    if (cloudName === "All Clouds") {
      messageTimes = Object.keys(serverIncident.history || []);
      latestMessage = this.getLatestHistory(serverIncident);
    }
    else if (product === "All Products") {
      messageTimes = Object.keys(serverIncident.clouds[Object.keys(serverIncident.clouds)[0]].history || []);
      latestMessage = this.getLatestHistory(serverIncident.clouds[Object.keys(serverIncident.clouds)[0]]);
    }
    else {
      messageTimes = Object.keys(serverIncident.products[Object.keys(serverIncident.products)[0]].history || []);
      latestMessage = this.getLatestHistory(serverIncident.products[Object.keys(serverIncident.products)[0]]);
    }
    if (messageTimes.length > 1) {
      incident.updatedTime = latestMessage.messageTime;
      incident.updated = parseDateToString(moment.unix(latestMessage.messageTime), this.locales);
    }

    return incident;
  }

  private previousMessageStatus(firstProduct: Product) {
    let messageKeys: string[] = Object.keys(firstProduct.history).sort((a: string, b: string) => parseInt(b) - parseInt(a));
    if (firstProduct.history[parseInt(messageKeys[0])].status === Status.Canceled) {
      if (messageKeys.length > 1) {
        return firstProduct.history[parseInt(messageKeys[1])].status;
      }
      return false;
    }
  }

  private isCanceledMaintenaceAfterStart(firstProduct: Product, currentStatus: any) {
    if (currentStatus === Status.Canceled) {
      return Object.values(firstProduct.history)
        .filter(history => history.status === Status.Started).length > 0
    }
    return false;
  }


  private getStatus(product: any, type?: any){

    let histories: any = product.history;
    let filteredHistories: any = {};

    for (const [key, value] of Object.entries(histories)) {
      if ((value as any).status !== Status.Updated && (value as any).messageType !== MessageType.Custom) {
        filteredHistories[key] = value;
      }
    }
    let messageKeys: string[] = Object.keys(filteredHistories).sort((a: string, b: string) => parseInt(b) - parseInt(a));
    let index = (type === StatusType.Latest) ? 0 : 1;
    
    if((type===StatusType.Latest && messageKeys.length>0) || (type===StatusType.Previous && messageKeys.length>1) ){
       return histories[parseInt(messageKeys[index])]?.status || "";
    }

    return "";
  }

  //for CSO and CMR
  private updateProps(incident: Incident, product: Product, serverIncident: ServerIncident, isMaintenance?: boolean) {
    let messageTimes = Object.keys(product.history), latestMessage = this.getLatestHistory(product);
    incident.cloudName = Object.values(incident.clouds)
      .filter((cloud: Cloud) => cloud.cloudProducts.includes(product.id))
      .map((cloud: Cloud) => cloud.name).join(', ');
    incident.cloudId =  Object.values(incident.clouds)
      .filter((cloud: Cloud) => cloud.cloudProducts.includes(product.id))
      .map((cloud: Cloud) => cloud.id).join(', ');

    if (!incident.cloudName) { // no cloud associated
      return null
    }

    incident.environments = {};
    if (incident.environments && serverIncident?.environments) {
      incident.environments = serverIncident.environments;
    }


    let currentRegion = latestMessage?.locationImpact?.serviceRegions;
    if (currentRegion && currentRegion?.length) {
      incident.regions = {};
      currentRegion.forEach((reg: any) => {
        if (incident.regions && serverIncident.regions && serverIncident.regions[reg]) {
          incident.regions[reg] = serverIncident.regions[reg];
        }
      })
    }
    incident.regionsInvolved = incident.regions ? Object.values(incident.regions).map((region: any) => region.name).join(', ') : '';
    if (incident.regionsInvolved) {
      incident.regionsInvolved = incident.regionsInvolved.split(",").sort((a: any, b: any) => {
        return a.trim().localeCompare(b.trim(), undefined, { sensitivity: 'base' });
      }).join(", ");
    }
    incident.previousStatus=this.getStatus(product,StatusType.Previous) || "";
    if(isMaintenance){
      if(latestMessage?.startedOn){
        incident.startedOn = latestMessage.startedOn;
      }
      if(latestMessage?.completedOn){
         incident.endedOn = latestMessage.completedOn;
      }
      if(!latestMessage.startedOn && !latestMessage.completedOn){
        incident.startedOn = serverIncident.startedOn || 0;
        incident.endedOn = serverIncident.completedOn || 0;
      }
    }else{
      incident.startedOn = product.startedOn;
      incident.endedOn = product.endedOn;
    }
   
    incident.productId = product.id;
    incident.name = product.name;
    incident.history = product.history;

    incident.start = parseDateToString(moment.unix(incident.startedOn), this.locales);
    if(!isMaintenance){incident.severity = latestMessage.severity;}
    incident.eventType = (incident.severity || EventType.maintenance).toLowerCase();
    incident.type = incident.severity?"":serverIncident.type;
    let latestStatus = latestMessage.status === Status.Discovery ? Status.Opened : latestMessage.status === Status.Dismissed ? Status.Closed : latestMessage.status;
    incident.status = isMaintenance?latestMessage.status:incident.severity === Severity.Trivial ? Status.Closed : latestStatus;
    if (messageTimes.length > 1) {
      incident.updatedTime = latestMessage.messageTime;
      incident.updated = parseDateToString(moment.unix(latestMessage.messageTime), this.locales);
    }


    if(isMaintenance){
      if ((incident.status === Status.Completed||incident.status === Status.Canceled)|| serverIncident.completedOn || latestMessage?.completedOn ) {
          if(latestMessage?.completedOn){
            incident.ended = parseDateToString(moment.unix(latestMessage.completedOn||0), this.locales);
          }else{
             incident.ended = parseDateToString(moment.unix(serverIncident.completedOn||0), this.locales);
          }
         
        }

      if (serverIncident.scheduledDate || latestMessage.scheduledDate) {
          if(latestMessage?.scheduledDate){
            incident.scheduled = parseDateToString(moment.unix(latestMessage.scheduledDate), this.locales);
          }else{
            incident.scheduled = parseDateToString(moment.unix(serverIncident.scheduledDate || 0), this.locales, false);
          }
        }

        if (serverIncident.startedOn || latestMessage.startedOn) {
          if (messageTimes.length > 1 || incident.ended) {
            if(latestMessage?.startedOn){
              incident.start = parseDateToString(moment.unix(latestMessage.startedOn), this.locales);
            }else{
              incident.start = parseDateToString(moment.unix(serverIncident.startedOn || 0), this.locales, false);
            }
          } else {
            if(latestMessage?.startedOn){
              incident.start = parseDateToString(moment.unix(latestMessage.startedOn), this.locales);
            }else{
            incident.start = parseDateToString(moment.unix(serverIncident.startedOn || 0), this.locales);
            }
          }
        }

    }else{

      if (incident.status === Status.Closed && product.endedOn) {
            incident.ended = parseDateToString(moment.unix(product.endedOn), this.locales);
          }
          else if (incident.status === Status.Closed && incident.severity === Severity.Trivial) {
            incident.ended = parseDateToString(moment.unix(this.getLatestTrivialDate(product)), this.locales);
          }

          if (incident.severity === Severity.Trivial && (latestMessage.status === Status.Opened)) {
            incident.ended = parseDateToString(moment.unix(this.getLatestTrivialDate(product)), this.locales);
            latestMessage.status = Status.Closed;
          }

          if (incident.updated || incident.ended) {
            incident.start = parseDateToString(moment.unix(incident.startedOn), this.locales, false);
          } else {
            incident.start = parseDateToString(moment.unix(incident.startedOn), this.locales);
          }
    }
    
    return incident;
  }



  private offeringsForProduct(productId: any){
    let offerings: string[]= [];
    this.serviceRegistry_global.getOfferingsbyProduct(productId).forEach((offering:any)=>{
               offerings.push(offering.id);
      })
    return offerings;
  }
  private hasProductOrOffering(incident: Incident, filter?: any[]): boolean {


    if (filter?.length) {
      let productOfferings: string[] = [], offerings: any[] = [], cloudProducts: any =[];
      if (incident.offerings && !this.isCommunication(incident.eventType)) { //CSO & CMR
        offerings = this.getLatestHistory(incident)?.serviceImpact?.productOfferings;
        if (offerings && offerings?.length) {
          offerings.forEach((offering: any) => {
            if (incident.offerings && incident.offerings[offering]) {
              productOfferings.push(incident.offerings[offering].id)
            }
          })
        }
      }else if(this.isCommunication(incident.eventType)){
          if(incident.eventType === EventType.product.toString()){
            productOfferings = this.offeringsForProduct(incident.productId);
          }
          if(incident.eventType === EventType.cloud.toString()){
            this.serviceRegistry_global.getCloudProductIds(incident.cloudId||"").forEach((product:any)=>{
              cloudProducts.push(product);
              productOfferings = productOfferings.concat(this.offeringsForProduct(product));
            })

          }
      }

      else {
        if (incident.offerings) {
          productOfferings = Object.values(incident.offerings).map((offering: any) => offering.name);
        }
      }
      return filter.some((item: any) => {
        return (
          (item === incident.productId) || cloudProducts.includes(item) || 
          productOfferings.includes(item) || incident.eventType === EventType.global.toString()
        );
      });
    }
    return false;
  }

 private generateEvents(incidents: ServerIncident[], cloud?: any, eventStatusFilter?: any): Incident[] {
    let incidentsByType: Incident[] = [];
    incidents.forEach((incident: ServerIncident) => {
      let incidents: Incident[] = this.mapIncident(incident);
      if ((cloud && cloud !== 'all' && incidents.length && cloud in incident.clouds)) {
        incidents = incidents.filter((incident: Incident) => incident.cloudId === cloud)
      }

      Array.prototype.push.apply(incidentsByType, incidents);
    });
    return incidentsByType;
  }

  private generateMessage(serverHistory: ServerHistory, incident: Incident) {
    let that = this;
    let history: History = {
      productName: incident.name,
      productId: incident.productId,
      showEnvironment: serverHistory.showEnvironment,
      status: serverHistory.status,
      serviceImpact: serverHistory.serviceImpact,
      severity: serverHistory.severity,
      locationImpact: serverHistory.locationImpact,
      eventType: (this.isCommunication(incident.eventType) ? incident.eventType : ((serverHistory.severity || EventType.maintenance).toLowerCase())) as EventType,
      messageType: serverHistory.messageType,
      type: serverHistory.operationsImpact,
      impactScope: "",
      updatedDate: parseDateToString(moment.unix(serverHistory.messageTime), this.locales),
      messageTime: serverHistory.messageTime,
      text: ''
    }, msgObj;

    msgObj = this.isCommunication(incident.eventType) ? this.messages.globalComm : ((history.eventType === EventType.maintenance) ? this.messages.maintainences : this.messages.incidents);
    if (msgObj) {
      let messageLocale = (LOCALEMAP[window.locale] || 'en').toLowerCase();
      if (serverHistory.messageType === MessageType.Custom) {
        messageLocale = 'en';
      }

      let tempMessageObject = msgObj[messageLocale];
      tempMessageObject = tempMessageObject && tempMessageObject[serverHistory.messageToken];
      if (tempMessageObject) {
        if (this.isCommunication(incident.eventType)) {
          if ((tempMessageObject.htmlMessage.match(/<p>/g) || []).length > 1) {
            history.text = tempMessageObject.htmlMessage;
          } else {
            history.text = tempMessageObject.textMessage;
          }

        }
        else {
          history.text = tempMessageObject.htmlMessage || tempMessageObject.textMessage;
        }
      } else {
        history.text = that.locales['none'];
      }

    } else {
      history.text = that.locales['none'];
    }

    if (history.text) {
      history.text = history.text.replace("{startDateTime}", incident.start).replace("{endDateTime}", incident.ended || "");
    } else {
      history.text = that.locales['none'];
    }

    if (serverHistory.cfsId) {
      if (/href="\{CFS_URL\}"/.test(history.text)) {
        history.text = history.text.replace(/href="\{CFS_URL\}"/, 'class="cfs-link"');
      }
      history.cfsId = serverHistory.cfsId;
    }
    if (serverHistory.severity) {
      if (serverHistory.customerImpact === "Most") {
        history.impactScope = that.locales.impact_scope_Most;
      } else if (serverHistory.customerImpact === "Some") {
        history.impactScope = that.locales.impact_scope_Some;
      } else {
        history.impactScope = that.locales.impact_scope_tbd;
      }
    } else {
      history.impactScope = that.locales.impact_maintenance;
    }
    let impacts = this.getImpactedDetails(history, incident);
    history.productOfferingsandServices = impacts.productOfferingsAndServics || '';
    history.productOfferings = impacts.productOfferings || '';
    history.impactedServices = impacts.impactedServices || '';
    history.environmentImpacted = impacts.environmentImpacted || '';

    return history;
  }

  private hasRegion(incident: ServerIncident, filter: CasCadingFilterProp, currentProduct?: any) {

    let regions: any = [];
    if (!currentProduct && incident?.regions) {
      regions = Object.values(incident.regions).map((region: any) => region.id);
    }
    else if (currentProduct && incident?.regions) {
      let latestHistory: History = this.getLatestHistory(currentProduct);
      regions = latestHistory?.locationImpact?.serviceRegions;
    }

    if (regions?.length && filter.regions) {
      let filteredRegions = filter.regions.filter((region: any) => {
        return -1 !== regions.indexOf(region);
      });
      return !!filteredRegions.length;
    }

    return false;
  }

  private hasOfferings(incident: ServerIncident, filter: CasCadingFilterProp) {
    if (incident.offerings) {
      let offerings = Object.keys(incident.offerings);

      if (offerings.length && filter.offerings) {
        let filteredOffer = filter.offerings.filter(function (offeringId) {
          return -1 !== offerings.indexOf(offeringId);
        });

        return !!filteredOffer.length;
      }
    }

    return true;
  }

  private hasEnvironmnets(incident: ServerIncident, filter: CasCadingFilterProp, currentProduct?: any) {
    let environments: any = [];
    if (!currentProduct && incident?.environments) {
      environments = Object.keys(incident.environments);
    }
    else if (currentProduct && incident?.environments) {
      let latestHistory: History = this.getLatestHistory(currentProduct);
      environments = latestHistory?.locationImpact?.serviceEnvironments;
    }
    if (environments?.length && filter.environments) {
      let filteredEnvironments = filter.environments.filter(function (envId) {
        return -1 !== environments.indexOf(envId);
      });

      return !!filteredEnvironments.length;
    }

    return false;

  }

  private applyFilter(incident: ServerIncident, filter: CasCadingFilterProp, currentProduct?: any) {

    let incidentProducts = Object.keys(incident.products);
    if (filter.products.length && -1 === incidentProducts.indexOf(filter.products)) {
      return false;
    }

    if (filter.offerings && filter.offerings.length) {
      let hasOffer = this.hasOfferings(incident, filter);
      if (!hasOffer) {
        return false;
      }
      if (hasOffer && filter.environments?.length && !this.hasEnvironmnets(incident, filter, currentProduct)) {
        return false;
      }
    }

    if (!filter.offerings?.length && filter.environments?.length && !this.hasEnvironmnets(incident, filter, currentProduct)) {
      return false;
    }

    if (!this.isCommunication(incident.type || "")) {
      if (filter.regions && filter.regions.length && !this.hasRegion(incident, filter, currentProduct)) {
        return false;
      }
    }

    return true;
  }

  private groupIncidentsByCloud(clouds: any, cloudId: any, eventKey: any, Id: any) {
    if (!clouds.get(cloudId)) { // create cloud and event if not exist
      clouds.set(cloudId,new Map());
      clouds.get(cloudId).set(eventKey,[Id]);
    } else if (!clouds.get(cloudId).get(eventKey)) { // create event if not exist under cloud 
      clouds.get(cloudId).set(eventKey,[Id]);
    } else { // add event to the group
      clouds.get(cloudId).get(eventKey).push(Id);
    }
  }

  private clearCache() {
    this._momoizeOngoingEvents = {};
  }

  /**
   * 
   * @returns - Promise of event service
   */
  public fetch(_callback?: Function | null, ignoremoment?:boolean) {
    let URL = ignoremoment?this.api_url :this.api_url + '?_=' + moment().valueOf();
    if (this._promise) {
      return this._promise;
    }
    this._promise = axios.get(URL);
    this._promise.then((result) => {
      if (result.status === 200 && result.data) {
        if (result.headers['last-modified'] &&
          (this._lastModified !== result.headers['last-modified'])) {
          this.clearCache();
          this._lastModified = result.headers['last-modified'];
          if (_callback && typeof _callback === 'function') {
            _callback();
          }
          this.set(result.data);
        }
      }
     delete this._promise;
    })
    .catch(() => {
      this.clearCache();
      delete this._promise;
    });
    return this._promise;
  }

  public registerForUpdate(_callback: Function) {
    this.registerCallback = _callback;
    if (this.intervalTimer) {
      window.clearInterval(this.intervalTimer);
    }
    this.intervalTimer = window.setInterval(() => this.fetch(this.registerCallback), REFRESH_TIME);
  }


  /**
   * 
   * @param result - response of event service 
   * @returns event service Object
   */
  private set(result: any) {
    if (result?.incidentEvent) {
      this.incidents = result.incidentEvent.incidents ? Object.values(result.incidentEvent.incidents) : [];
      this.messages.incidents = result.incidentEvent.messages
    }
    if (result?.maintenanceEvent) {
      this.maintainences = result.maintenanceEvent.maintenance ? Object.values(result.maintenanceEvent.maintenance) : [];
      this.maintainences = this.maintainences.map((serverIncident: ServerIncident) => {
        let productIds: String[] = Object.keys(serverIncident.products);

        productIds.forEach((productId: any) => {
          let history = serverIncident.products[productId].history, historIds: String[] = Object.keys(history);
          historIds.forEach((historyId: any) => {
            if (history[historyId].cmrStatus) {
              if (history[historyId].cmrStatus === Status.Canceled) {
              }
              if(history[historyId].cmrStatus  === Status.Reminder){
                history[historyId].status = Status.Scheduled
              }else{
                history[historyId].status = history[historyId].cmrStatus || Status.None;
              }
            }
          });
        });
        if(serverIncident.cmrStatus === Status.Reminder){
          serverIncident.status = Status.Scheduled
        }else{
          serverIncident.status = serverIncident.cmrStatus || Status.None;
        }
        return serverIncident;
      });
      this.messages.maintainences = result.maintenanceEvent.messages
    }
    if (result?.globalCommEvent) {
      this.globalCommRaw = result.globalCommEvent.globalComm ? Object.values(result.globalCommEvent.globalComm) : [];
      this.messages.globalComm = result.globalCommEvent.messages
    }

    this.globalCommunications = [];

    this.globalCommRaw.forEach((commRaw: Communications) => {
      if (commRaw) {
        if (commRaw.clouds && commRaw.messageType === EventType.cloud) {

          let clouds: { [key: string]: Cloud } = {};

          for (const [cloud_id, cloud_details] of Object.entries(commRaw.clouds)) {

            if (cloud_details && cloud_details.history) {
              let cloud_histories = this.getHistories(cloud_details.history);

              let cloud: Cloud = {
                cloudProducts: [],
                history: cloud_histories,
                id: cloud_details.id,
                name: cloud_details.name,
              };
              clouds[cloud_id] = cloud;
            }

          }

          let cloud_serverIncident = this.convertIncident(commRaw, {}, clouds, {});
          this.globalCommunications.push(cloud_serverIncident);
        }
        else if (commRaw.products && commRaw.messageType === EventType.product) {
          let products: { [key: string]: Product } = {};

          for (const [product_id, product_details] of Object.entries(commRaw.products)) {
            if (product_details && product_details.history) {
              let product_histories = this.getHistories(product_details.history);

              let product: Product = {

                endedOn: product_details.endedOn,
                history: product_histories,
                id: product_id,
                name: product_details.name,
                startedOn: product_details.startedOn,
                cloudId: product_details.cloudId,
                cloudName: product_details.cloudName
              };
              products[product_id] = product;

            }
          }

          let product_serverIncident = this.convertIncident(commRaw, {}, {}, products);
          this.globalCommunications.push(product_serverIncident);

        }
        else if (commRaw.history && commRaw.messageType === EventType.global) {
          let global_histories: { [id: string]: ServerHistory; } = this.getHistories(commRaw.history);
          let global_serverIncident = this.convertIncident(commRaw, global_histories, {}, {});
          this.globalCommunications.push(global_serverIncident);
        }
      }
    })

    return this;
  }

  private convertIncident(commRaw: Communications, history: { [key: number]: ServerHistory }, cloud: { [key: string]: Cloud }, products: { [key: string]: Product }) {
    let serverIncident: ServerIncident = {
      id: commRaw.id,
      status: commRaw.messageStatus,
      completedOn: commRaw.closedOn,
      closedOn: commRaw.closedOn,
      openedOn: commRaw.openedOn,
      type: commRaw.messageType,
      startedOn: commRaw.openedOn,
      history: history ? history : {},
      clouds: cloud ? cloud : {},
      products: products ? products : {},
      offerings: {},
      services: {},
      environments: {},
      regions: {}
    }
    return serverIncident;
  }

  private getHistories(communication_history: { [key: number]: CommunicationsHistory }) {

    let histories: { [id: string]: ServerHistory; } = {};

    if (communication_history) {
      for (const [history_timestmap, history_record] of Object.entries(communication_history)) {

        let locationImpactTemp: LocationImpact = { serviceEnvironments: [], serviceRegions: [] }

        let serverHistory: ServerHistory = {
          showEnvironment: false,
          id: history_record.messageId,
          status: history_record.status as Status,
          messageType: MessageType.Custom,
          messageTime: history_record.messageTime,
          customerImpact: CustomerImpact.Unknown,
          operationsImpact: OperationsImpact.Unknown,
          messageToken: history_record.messageToken,
          titleToken: history_record.titleToken,
          serviceImpact: {},
          locationImpact: locationImpactTemp
        }

        histories[history_timestmap] = serverHistory;
      }
    }
    return histories;
  }

  public getProductandEventIdfromMessageId(messageId: any) {

    let productId: any = "";
    let incidentId: any = "";
    let cfsExists = false;

    if (messageId) {
      [...this.incidents, ...this.maintainences].forEach((incident: ServerIncident) => {
        Object.values(incident.products).forEach((product: Product) => {
          Object.values(product.history).forEach((historyObj: ServerHistory) => {
            if (historyObj.id === messageId) {
              productId = product.id;
              incidentId = incident.id;
              if (historyObj?.cfsId) {
                if (historyObj.cfsId !== "") {
                  cfsExists = true;
                }
              }
            }
          })
        })
      })
    }
    return { "productId": productId, "incidentId": incidentId, "cfsExists": cfsExists };
  }

  /**
   * 
   * @param cfsId - it is customer faceing statement reference id which will be there in incident history 
   * @param eventId - incident id(CSO/CMR)
   * @returns - Promise of CFS get request
   */
  public getCFS(cfsId: string, eventId: string, isSkmsCfs: boolean) {

    if (window.adobeIMS && window.adobeIMS?.isSignedInUser()) {
      const headers = {
        'Authorization': 'Bearer ' + window.adobeIMS.getAccessToken().token,
        'x-api-key': API_KEY
      };
      if (isSkmsCfs) {
        return axios.get(URLS.CFS_URL + cfsId + '?csoId=' + eventId + "&isSkmsCfs=true", { headers });
      } else {
        return axios.get(URLS.CFS_URL + cfsId + '?csoId=' + eventId, { headers });
      }
    }
    else {
      return Promise.resolve([]);
    }
  }
  /**
   * 
   * @param event - it can be either product(refer type Product) or incident(refer type Incident)
   * @param isOverviewPage - this flag is to ignore CFS, for overview page CFS message will be ignored
   * @returns - latest history of CSO/CMR
   */
  public getLatestHistory(event: any, isOverviewPage?: boolean) {
    let histories: any = event.history;
    let messageKeys: string[] = Object.keys(event.history).sort((a: string, b: string) => parseInt(b) - parseInt(a));
    let index = 0;
    if (isOverviewPage && messageKeys.length > 0) {
      while (histories[parseInt(messageKeys[index])].cfsId) {
        ++index;
      }
    }
    return histories[parseInt(messageKeys[index])];
  }

  public getLatestTrivialDate(event: any,) {
    let histories: any = event.history;
    let messageKeys: string[] = Object.keys(event.history).sort((a: string, b: string) => parseInt(b) - parseInt(a));
    let index = 0;

    while (histories[parseInt(messageKeys[index])]) {

      if (histories[parseInt(messageKeys[index])].severity !== Severity.Trivial) {
        break;
      } else {
        ++index;
      }

    }

    return histories[parseInt(messageKeys[index - 1])].messageTime;
  }

  private isProductUnderCloud(cloudid: string, incident: any) {
    if (cloudid && incident.products) {

      for (var id in incident.products) {
        if (incident.products[id].cloudId === cloudid) {
          return true;
        }
      }
    }
    return false;
  }

  private isSameCloud(cloudid: string, incident: any) {
    if (cloudid && incident.clouds) {

      for (var id in incident.clouds) {
        if (incident.clouds[id].id === cloudid) {
          return true;
        }
      }
    }
    return false;
  }

  private isParentCloud(productid: string, cloudid: any) {

    let parentCloud = this.serviceRegistry_global.getCloudId(productid);
    if (parentCloud === cloudid) {
      return true;
    }

    return false;
  }

  public getOnGoingEvents(cloud?: any, eventStatusFilter?: any, product?: any, today?: boolean): any[] {

   
    let memKey = hashCode(JSON.stringify(arguments) + this._lastModified);

    if (this._momoizeOngoingEvents[memKey] && moment().isBefore(this._expiryTime)) {
      return this._momoizeOngoingEvents[memKey];
    } else {
      if (!this._expiryTime || moment().isAfter(this._expiryTime)) {
        this._expiryTime = moment().add(MEMOIZE_TIME, 'hours');
      }
    }
    let results: any = [],
      incidents = JSON.parse(JSON.stringify([...this.incidents, ...this.maintainences, ...this.globalCommunications]));
    cloud = cloud || 'all';
    if (incidents?.length) {
      results = incidents.filter((incident: any) => {

        let resolvedStart = moment().subtract(4, 'hours');
        if ([EventType.global, EventType.product, EventType.cloud].includes(incident.type)) {

          // Overview Page -> Global, Cloud and Product Level
          // Cloud Page -> Global and Only Cloud
          if (cloud === 'all' || (cloud !== 'all' && incident.type === EventType.global) || (cloud === 'all' && incident.type === EventType.cloud) || this.isSameCloud(cloud, incident) || this.isProductUnderCloud(cloud, incident)) {
            if (incident.closedOn !== 0) {
              if (moment().isBetween(moment(moment.unix(incident.startedOn)), moment(moment.unix(incident.closedOn)))) {
                return true;
              }
              else if (moment(moment(moment.unix(incident.closedOn))).isBetween(resolvedStart, moment())) {
                return true;
              }
            } else {
              return true;
            }
          }
          return false;
        }
        if (!incident.clouds) return false;
        if (!incident.products) return false;
        let clouds = Object.keys(incident.clouds),
          eventEndDate: any;

        if (cloud !== 'all') {
          if (clouds.indexOf(cloud) === -1)
            return false;
        }
        if (product) {
          let products = Object.keys(incident.products);
          if (products.indexOf(product) === -1) {
            return false;
          }
        }
        let filteredProduct = Object.values(incident.products).filter((inproduct: any) => {
          let latestMessage = this.getLatestHistory(inproduct);
          // trivial will be displayed for 4 hours in overview page if it is downgraded from major/minor
          //otherwise trivial will be ignored
          let val = false;
          if (latestMessage.severity === Severity.Trivial) {

            if (inproduct.endedOn) {
              eventEndDate = moment(moment.unix(inproduct.endedOn));
              if (today && isToday(moment(eventEndDate))) {
                return true;
              } else {
                return eventEndDate && moment(eventEndDate).isBetween(resolvedStart, moment())
              }
            } else {
              latestMessage.status = Status.Closed;
              eventEndDate = moment(moment.unix(this.getLatestTrivialDate(inproduct)));
              if (today && isToday(moment(eventEndDate))) {
                return true;
              } else {
                return eventEndDate && moment(eventEndDate).isBetween(resolvedStart, moment())
              }
            }
          } else {

            if (!latestMessage.severity) { //cmr
              var latestStatus = this.getStatus(inproduct, StatusType.Latest);
              if ( latestStatus === Status.Started) {
                val = true;
              }
              if (latestStatus === Status.Canceled || latestStatus === Status.Completed) {
                if(latestMessage?.completedOn){
                  eventEndDate = moment(moment.unix(latestMessage.completedOn));
                }else{
                  eventEndDate = moment(moment.unix(incident.completedOn));
                }
              }

            } else { // cso
              if (latestMessage.status === Status.Opened || latestMessage.status === Status.Discovery) {
                val = true;
              }
              if (latestMessage.status === Status.Closed || latestMessage.status === Status.Dismissed) {
                eventEndDate = moment(moment.unix(inproduct.endedOn));
              }
            }
          }


          if (today && isToday(moment(eventEndDate))) {
            return true;
          } else {

            return (eventEndDate && isToday(eventEndDate) && moment(eventEndDate).isBetween(resolvedStart, moment())) || val;
          }
        });
        incident.products = {};

        if (filteredProduct.length) {
          filteredProduct.forEach((product: any) => {
            incident.products[product.id] = product;
          })
        }
        else {
          return false;
        }

        if (cloud === 'all' || (clouds.indexOf(cloud) !== -1)) {
          if (!eventStatusFilter || (eventStatusFilter && eventStatusFilter.name === 'all')) {
            return true;
          }

          if (eventStatusFilter && eventStatusFilter.name !== "all" && -1 !== eventStatusFilter.values.indexOf(incident.status)) {
            return true;
          }
        }

        return false;
      });



      results.sort((a: any, b: any) => {
        return b.startedOn - a.startedOn;
      });

      this._momoizeOngoingEvents[memKey] = this.generateEvents(results, cloud);
      return this._momoizeOngoingEvents[memKey];
    }

    return [];
  }

  public incidentsCloud() {

    let cloudId: string, that = this, clouds: any = new Map(), incidents: any;
    incidents = this.getOngoingIncidentsByCloud('all');
    incidents.forEach((incident: ServerIncident) => {

      if (this.isCommunication(incident.type || "")) {
        let resolvedStart = moment().subtract(4, 'hours');

        let eventKey = "";

        if (incident.closedOn !== 0) {
          if ((incident.startedOn && incident.closedOn && moment().isBetween(moment(moment.unix(incident.startedOn)), moment(moment.unix(incident.closedOn))))) {
            eventKey = "announcement";
          }
          else if (incident.closedOn && moment(moment(moment.unix(incident.closedOn))).isBetween(resolvedStart, moment())) {
            eventKey = "closed_announcement";
          }
        } else {
          eventKey = "announcement";
        }

        if (eventKey) {
          if (incident.type === EventType.global) {
            this.cloudMapping.forEach((cloud: any) => {
              this.groupIncidentsByCloud(clouds, cloud.id, eventKey, incident.id);
            })

          }
          else if (incident.type === EventType.cloud) {
            Object.values(incident.clouds).forEach((cloud: any) => {
              this.groupIncidentsByCloud(clouds, cloud.id, eventKey, incident.id);
            })
          }
          else {
            Object.values(incident.products).forEach((product: any) => {
              this.groupIncidentsByCloud(clouds, product.cloudId, eventKey, incident.id);
            })
          }
        }
      }
      Object.keys(incident.clouds).forEach((cloud: any) => {

        cloudId = cloud;
        let cloudProducts = Object.values(incident.clouds[cloud].cloudProducts);

        if (!cloudId) {
          cloudId = CLOUDIDS.ADOBE_SERVICES_ID;
        }

        let currentProduct;
        cloudProducts.forEach((productId: any) => {
          if (incident.products) {
            currentProduct = incident.products[productId];
            let latestMessage: History, eventKey: any,
              resolvedStart = moment().subtract(4, 'hours'), end = moment(),
              isRecentEvent = false, endedDate;
            if (!currentProduct) return;

            latestMessage = this.getLatestHistory(currentProduct);

            //CSO
            var severity = latestMessage?.severity || "";
            if (severity) {
              eventKey = (severity).toLowerCase();
              
              if (latestMessage.status === Status.Closed || latestMessage.status === Status.Dismissed || (severity === Severity.Trivial && latestMessage.status === Status.Opened)) {
                if (severity === Severity.Trivial) {

                  if (currentProduct.endedOn) {
                    endedDate = moment(moment.unix(currentProduct.endedOn));
                  } else {
                    latestMessage.status = Status.Closed;
                    endedDate = moment(moment.unix(this.getLatestTrivialDate(currentProduct)));
                  }
                } else {
                  endedDate = moment(moment.unix(currentProduct.endedOn));
                }

                isRecentEvent = endedDate && isToday(endedDate) && moment(endedDate).isBetween(resolvedStart, end);
                if (!isRecentEvent) return;
                eventKey = 'resolved_' + severity.toLowerCase();
              }
              this.groupIncidentsByCloud(clouds, cloudId, eventKey, productId);
            } else {
              //CMR

                let eventKey: any = EventType.maintenance, resolvedStart = moment().subtract(4, 'hours'), end = moment();

                if (latestMessage.status === Status.Completed || latestMessage.status === Status.Canceled ||(incident.status === Status.Completed && latestMessage.status === Status.Updated) || (incident.status === Status.Canceled && latestMessage.status === Status.Updated)) {
                  endedDate = moment(moment.unix(latestMessage.completedOn || incident.completedOn || 0));
                                                    
                  isRecentEvent = endedDate && isToday(endedDate) && moment(endedDate).isBetween(resolvedStart, end);

                  if (!isRecentEvent) return;
                  if (latestMessage.status === Status.Completed) {
                    eventKey = 'resolved_maintenance'
                  }
                  if (latestMessage.status === Status.Canceled) {
                    eventKey = 'canceled_maintenance'
                  }
                  if (latestMessage.status === Status.Canceled && !this.isCanceledMaintenaceAfterStart(currentProduct, latestMessage.status)) {
                    return;
                  }
                  

                }

                if(this.getStatus(currentProduct,StatusType.Latest) === Status.Scheduled){return;}
                this.groupIncidentsByCloud(clouds, cloudId, eventKey, productId);
            }
          }
        });
      });
    });


    let cloudGroup: any = [];
    this.cloudMapping.forEach((cloud: any) => {
      if (clouds.get(cloud.id)) {
        cloudId = cloud.id;
        cloudGroup.push({
          cloud: {
            cloudId: cloud.id,
            cloudName: cloud.name
          },
          icon: cloud.icon,
          status: clouds.get(cloudId),
          statusDetails: this.priortisedStatusCloud(clouds.get(cloudId))

        });
      } else {
        cloudGroup.push({
          cloud: {
            cloudId: cloud.id,
            cloudName: cloud.name
          },
          icon: cloud.icon,
          statusDetails: [{
            eventType: 'available',
            eventString: that.locales.available
          }]
        });
      }
    });
    return cloudGroup;
  }

  public getProductAllMessages(incident: Incident, productId?: string): History[] {
    let histories: { [key: number]: ServerHistory } = {},
      messageKeys: string[] = [];
    if (incident.eventType === EventType.maintenance && incident.products && productId) {
      let product: Product = incident.products[productId];
      incident.name = product.name;
      incident.productId = product.id;
      histories = product.history;
    } else if (incident.eventType === EventType.cloud) {
      if (incident.cloudId) {
        let cloud: Cloud = incident.clouds[incident.cloudId];
        histories = cloud.history || {};
      }
    } else if (incident.eventType === EventType.product && incident.products) {
      if (incident.productId) {
        let product: Product = incident.products[incident.productId];
        histories = product.history || {};
      }
    }
    else {
      if (incident && incident.history) {
        histories = incident.history;
      }
    }

    
    histories = Object.values(histories).map((history: any) => {
      if (history.cmrStatus !== "Reminder" || history.messageType === MessageType.Custom) {
        return history;
      }
      return null; 
    }).filter((history: any) => history !== null); 

    messageKeys = Object.keys(histories).sort((a: string, b: string) => parseInt(b) - parseInt(a));
    return messageKeys.map((messageKey: string) => this.generateMessage(histories[parseInt(messageKey)], incident))
      .sort((a: History, b: History) => b.messageTime - a.messageTime);
  }

  public isCommunication(eventType: string): Boolean {
    if ([EventType.cloud.toString(), EventType.global.toString(), EventType.product.toString()].includes(eventType)) {
      return true;
    }
    return false;
  }

  public getProductLastetMessage(incident: Incident): History[] {
    let list = [], latestMessage, item: History;

    let histories: { [key: number]: ServerHistory } = {};
  
    Object.values(incident.history||{}).map((history: any) => {
      if (history.cmrStatus !== "Reminder" || history.messageType === MessageType.Custom) {
        return history;
      }
      return null; 
    }).filter((history: any) => history !== null);
      if (incident && histories) { 
        latestMessage = this.getLatestHistory(incident, true);
        item = this.generateMessage(latestMessage, incident);
        list.push(item);
      }
    return list;
  }
  public getOnGoingEventsByType(cloud?: any, eventStatusFilter?: any, searchFilter?: any[], myEvents?: Incident[], product?: any, today?: boolean): any[] {
    let incidentsByType: IncidentGroup[] = [],
      incidentTypes: string[] = [], typeIndex, incidents: Incident[];
    if (myEvents) {
      incidents = myEvents;
    } else {
      incidents = this.getOnGoingEvents(cloud, eventStatusFilter, product, today);
    }

    incidents.forEach((incident: Incident) => {
      let isResolved = [Status.Closed, Status.Completed, Status.Canceled].includes(incident.status),
        color = isResolved ? this.isCommunication(incident.eventType) ? "closed_announcement" : (incident.eventType === EventType.maintenance ? (incident.status === Status.Completed ? 'resolved_maintenance' : 'canceled_maintenance') : 'resolved') : this.isCommunication(incident.eventType) ? "announcement" : incident.eventType;
      if (searchFilter?.length && !this.hasProductOrOffering(incident, searchFilter)) {
        return;
      }
      typeIndex = incidentTypes.indexOf(color);
      if (typeIndex === -1 && incident.eventType !== 'none') {
        incidentTypes.push(color);
        incidentsByType.push({
          type: color,
          eventType: incident.eventType.toLowerCase(),
          list: []
        });
        typeIndex = incidentTypes.length - 1;
      }
      if (incident.eventType !== 'none') {
        if (product) {
          if (incident.productId === product) {
            incidentsByType[typeIndex].list.push(incident);
          }else if(EventType.global.toString() === incident.eventType){
            incidentsByType[typeIndex].list.push(incident);
          }else if(EventType.cloud.toString() === incident.eventType && incident.cloudId === this.serviceRegistry_global.getCloudId(product)){
            incidentsByType[typeIndex].list.push(incident);
          }
        }
        else {
          incidentsByType[typeIndex].list.push(incident);
        }
      }
    });
    incidentsByType.sort((a: IncidentGroup, b: IncidentGroup) => {
      let aIndex = INCIDENTPRIORITIES.indexOf(a.type), bIndex = INCIDENTPRIORITIES.indexOf(b.type);
      if (aIndex === -1) aIndex = INCIDENTPRIORITIES.length;
      if (bIndex === -1) bIndex = INCIDENTPRIORITIES.length;
      return aIndex - bIndex;
    });

    incidentsByType.forEach((incidentGroup: IncidentGroup) => {
      incidentGroup.list.sort((a: Incident, b: Incident) => (b.startedOn - a.startedOn));
    })
    //2nd level sorting based on updated time
    incidentsByType.forEach((incidentGroup: IncidentGroup) => {
      incidentGroup.list.sort((a: Incident, b: Incident) => {
        if (a?.products && b?.products && a?.productId && b?.productId) {
          return this.getLatestHistory(b.products[b.productId]).messageTime - this.getLatestHistory(a.products[a.productId]).messageTime;
        } else {
          return b.startedOn - a.startedOn;
        }
      })
    })
    return incidentsByType;
  }
  public getOnGoingEventsByCloud(cloud?: any, eventStatusFilter?: any, searchFilter?: any[], myEvents?: Incident[]) {
    var incidentsByType: any[] = [], that = this,
      incidentTypes: any[] = [], typeIndex: any, cloudName: any, incidents: Incident[];
    if (myEvents) {
      incidents = myEvents;
    } else {
      incidents = this.getOnGoingEvents(cloud, eventStatusFilter);
    }

    

    incidents.forEach((incident: Incident) => {
      if (incident.eventType === INCIDENTPRIORITIES[7] && incident.previousStatus === Status.Scheduled && incident.status === Status.Canceled) {
        return;
      }
      let flag = true;
      if (searchFilter?.length) {
        flag = this.hasProductOrOffering(incident, searchFilter);
      }
      if (flag) {
        if (!incident.severity) {
          if (this.isCommunication(incident.eventType)) {
            if (incident.eventType === EventType.global) {

              this.cloudMapping.forEach((cloudObj: any) => {
                cloudName = cloudObj.name;
                typeIndex = incidentTypes.indexOf(cloudObj.name);
                if (typeIndex === -1 && cloudName) {
                  incidentTypes.push(cloudName);
                  incidentsByType.push({
                    cloudName: cloudName,
                    list: []
                  });
                  typeIndex = incidentTypes.length - 1;
                }
                if (incidentsByType[typeIndex]?.list) {
                  incidentsByType[typeIndex].list.push(incident);
                }
              });

            }
            else if (incident.eventType === EventType.cloud || incident.eventType === EventType.product) {
              cloudName = incident.cloudName;
              typeIndex = incidentTypes.indexOf(cloudName);
              if (typeIndex === -1 && cloudName) {
                incidentTypes.push(cloudName);
                incidentsByType.push({
                  cloudName: cloudName,
                  list: []
                });
                typeIndex = incidentTypes.length - 1;
              }
              if (incidentsByType[typeIndex]?.list) {
                incidentsByType[typeIndex].list.push(incident);
              }

            }

          }
          else {
            // Object.values(incident.clouds).forEach((cloud: any) => {
              cloudName = incident.cloudName;
              // cloudName = cloud.name;
              typeIndex = incidentTypes.indexOf(cloudName);
              if (typeIndex === -1 && cloudName) {
                incidentTypes.push(cloudName);
                incidentsByType.push({
                  cloudName: cloudName,
                  list: []
                });
                typeIndex = incidentTypes.length - 1;
              }
              if (incidentsByType[typeIndex]?.list) {
                incidentsByType[typeIndex].list.push(incident);
              }
            // });
          }
        } else {
          cloudName = incident.cloudName;
          typeIndex = incidentTypes.indexOf(cloudName);
          if (typeIndex === -1 && cloudName) {
            incidentTypes.push(cloudName);
            incidentsByType.push({
              cloudName: cloudName,
              list: []
            });
            typeIndex = incidentTypes.length - 1;
          }
          if (incidentsByType[typeIndex]?.list) {
            incidentsByType[typeIndex].list.push(incident);
          }
        }
      }
    });

    let incidentsCloud: any[] = [];
    this.cloudMapping.forEach((cloudObj: any) => {
      let cloudAdded = false;
      incidentsByType.forEach((cloudgroup: any) => {
        if (cloudgroup.cloudName === cloudObj.name) {
          cloudAdded = true;
          incidentsCloud.push({
            cloud: {
              cloudId: cloudObj.id,
              cloudName: cloudObj.name
            },
            icon: cloudObj.icon,
            list: cloudgroup.list || [],
            statusDetails: [],
            status: {}
          });
        }
      })
      if (!cloudAdded) {
        incidentsCloud.push({
          cloud: {
            cloudId: cloudObj.id,
            cloudName: cloudObj.name
          },
          icon: cloudObj.icon,
          list: [],
          statusDetails: [],
          status: {}
        });

      }
    });

    incidentsCloud.forEach((cloudObj: any) => {
      cloudObj.list.forEach((event: any) => {

        if (this.isCommunication(event.eventType)) {
          cloudObj.statusDetails.push({ type: "announcement" });
        }
        else {
          if (event.status !== Status.Completed && event.status !== Status.Closed && event.status !== Status.Canceled) {
            event._type = event.eventType;
            cloudObj.statusDetails.push({ type: event.eventType });
          } else if (event.status === Status.Closed) {
            cloudObj.statusDetails.push({ type: "resolved_" + event.eventType });
            event._type = "resolved_" + event.eventType;
          } else if (event.status === Status.Completed) {
            cloudObj.statusDetails.push({ type: "resolved_maintenance" });
            event._type = "resolved_maintenance";
          } else if (event.status === Status.Canceled) {
            cloudObj.statusDetails.push({ type: 'canceled_maintenance' });
            event._type = 'canceled_maintenance';
          }
        }
      })
    });


    var groupBy = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    incidentsCloud.forEach((cloudObj: any) => {
      if (cloudObj.statusDetails.length !== 0) {

        var details = groupBy(cloudObj.statusDetails, "type");
        var mapdetails = new Map(Object.entries(details));
        cloudObj.status = this.priortisedStatusCloud(mapdetails)
      } else {
        cloudObj.status = [{
          eventType: 'available',
          eventString: that.locales.available
        }]
      }
    })


    incidentsCloud.forEach((cloudObj: any) => {
      cloudObj.list.sort((a: any, b: any) => (INCIDENTPRIORITIES.indexOf(a._type) - INCIDENTPRIORITIES.indexOf(b._type)) || (b.startedOn - a.startedOn));
    });

    return incidentsCloud;
  }

  public filterProductsByDate(serviceRegistry: any, filter: any, Id: any, type?: any) {
    
    let filteredProducts: any, that = this, available="available", priortisedEvents = [...INCIDENTPRIORITIES, available];



    if (type && type === "offering") {

      filteredProducts = this.filteredOfferings(serviceRegistry, filter, Id, true);

    } else {
      filteredProducts = this.filterProducts(serviceRegistry, filter, Id, true);
    }

    let historyDates = [];
    let productHistoryDataTemp = Object.assign({}, filteredProducts),
      productHistoryData = [], fromDate = moment(filter.date[0]),
      fromDateString = fromDate.format('YYYY-MM-DD'),
      toDate = moment(filter.date[1]),
      numberOfDays = toDate.diff(fromDate, 'days') + 1;

    if (productHistoryDataTemp && productHistoryDataTemp[0]) {
      for (let index = 0; productHistoryDataTemp[index]; index++) {
        productHistoryData.push(productHistoryDataTemp[index]);
      }
    }

    for (let dateIndex = 0; dateIndex < numberOfDays; dateIndex++) {
      let currentDate = moment(fromDateString).add(dateIndex, 'days'),
        formatedCurrentDate;
      if (isToday(currentDate)) {
        formatedCurrentDate = that.locales['current'];
      } else if (isYesterday(currentDate)) {
        formatedCurrentDate = that.locales['yesterday'];
      } else {
        formatedCurrentDate = shortDate(currentDate);
      }
      historyDates.push(formatedCurrentDate);
      for (let index = 0; productHistoryData[index]; index++) {
        let product = productHistoryData[index];
        if (!product.historyData) {
          product.historyData = [{
            date: shortDate(currentDate),
            filterDate: currentDate.format('YYYY-MM-DD'),
            status: available
          }];
        } else {
          product.historyData.push({
            date: shortDate(currentDate),
            filterDate: currentDate.format('YYYY-MM-DD'),
            status: available
          })
        }
      
        if (product.incidents) {
          let priorityStatus = available;
          for (let eventIndex = 0; product.incidents[eventIndex]; eventIndex++) {
            let event = product.incidents[eventIndex];
            let eventStartDate = moment(moment.unix(event.startedOn).format('YYYY-MM-DD')),
              eventEndDate;

            if (event.endedOn) {
              eventEndDate = moment(moment.unix(event.endedOn).format('YYYY-MM-DD'));
            }
            let latestHistory = this.getLatestHistory(event), status;
            if (latestHistory.severity === Severity.Trivial) {
              if (event.endedOn) {
                eventEndDate = moment(moment.unix(event.endedOn).format('YYYY-MM-DD'));
              } else {
                eventEndDate = moment(moment.unix(this.getLatestTrivialDate(event)).format('YYYY-MM-DD'));
                status = Status.Closed;
              }
            }

            if (eventStartDate && eventStartDate.isSameOrBefore(currentDate, 'days')) {

               if(eventEndDate && !eventEndDate.isSameOrAfter(currentDate, 'days')){// if end date exists and is not today check history 
               continue; 
              }
              let eventType;

              if (event.type === "announcement") {
                eventType = event.type
                status = event.status
              }
              else {
                eventType = (latestHistory?.severity || EventType.maintenance).toLowerCase();
                status = latestHistory.status;
              }

              if(eventEndDate && isToday(currentDate)){
                if (event.type === "announcement") {
                  eventType = "closed_announcement";
                }else if(eventType === EventType.maintenance ){
                    eventType = (status === Status.Canceled)?"canceled_maintenance":(status === Status.Completed)?"resolved_maintenance": "maintenance";
                }else{
                  eventType = "resolved";
                }               
              }

              if ((!isToday(currentDate) && eventType === EventType.potential) || (!isToday(currentDate) && status === Status.Dismissed) || (!isToday(currentDate) && status ===Status.Canceled)) {
                continue;
              }

              if (priortisedEvents.indexOf(eventType) < priortisedEvents.indexOf(priorityStatus)) {
                priorityStatus = eventType;
              }
            }
          }
          if (priorityStatus === EventType.potential && !isToday(currentDate)) {
            priorityStatus = available
          }
          product.historyData[product.historyData.length - 1].status = priorityStatus;
        }
      }
    }

    productHistoryData = productHistoryData.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }

      return 0;
    });

    return {
      historyDates: historyDates,
      productHistoryData: productHistoryData
    }

  }

  public getScheduledMaintenance(filter: CasCadingFilterProp) {
    let maintainences: Incident[] = [];
    [...this.maintainences].forEach((maintainence: ServerIncident) => {
      Object.values(maintainence.products).forEach((product: any) => {
          if(filter.products === "all" || filter.products.includes(product.id)){
            if(this.getStatus(product, StatusType.Latest) === Status.Scheduled){
              if(this.applyFilter(maintainence, filter)){
                let incidents = this.mapIncident(maintainence, filter.products);
                Array.prototype.push.apply(maintainences, incidents);
              }
            }
          }
      })
    });
    maintainences.sort((a: Incident, b: Incident) => { return a.startedOn - b.startedOn})
    return maintainences;
  }

  public findLatestCFS(eventId: string, productId: string) {
    let incident: Incident | null = this.getIncident(eventId, productId);

    if (incident?.history) {
      let histories = Object.values(incident.history);

      histories = histories.filter((history: ServerHistory) => !!history.cfsId);
      if (histories.length) {
        let times: number[] = histories.map((history: ServerHistory) => history.messageTime),
          latestHistory: ServerHistory = incident.history[Math.max.apply(null, times)];
        return latestHistory.cfsId
      }
    }

    return null;
  }

  public isSkmsCfs(eventId: string, productId: string) {

    let incident: Incident | null = this.getIncident(eventId, productId);

    if (incident?.history) {
      let histories = Object.values(incident.history);

      histories = histories.filter((history: ServerHistory) => !!history.cfsId);
      if (histories.length) {
        let times: number[] = histories.map((history: ServerHistory) => history.messageTime),
          latestHistory: ServerHistory = incident.history[Math.max.apply(null, times)];
        if (latestHistory.messageToken.includes("message.incidentCFS")) {
          return true;
        } else {
          return false;
        }
      }
    }

    return false;

  }


  public getIncident(id: any, productId?: string, cloudId?: string): Incident | null {
    let allData = [...this.incidents, ...this.maintainences, ...this.globalCommunications], filtered: ServerIncident[];

    filtered = allData.filter((incident: ServerIncident) => (incident.clouds || this.isCommunication(incident.type || '')) && incident.id === id);
    if (filtered.length) {
      let incidents: Incident[] = this.mapIncident(filtered[0], productId);

      for (let incident of incidents) {
        if (EventType.cloud.toString() === incident.eventType && incident.cloudId === cloudId) {
          return incident;
        }
      }
      return (incidents.length) ? incidents[0] : null;
    }

    return null;
  }

  public filteredOfferings(serviceRegistry: any, filter: CasCadingFilterProp, productId: any, history?: boolean) {
    let filteredOffering: any = [], filteredOfferingsIndex: any = {}, that = this, allOfferings: any = [], productOfferings: any = [];
    let allData = [...this.incidents, ...this.maintainences];

    if (filter?.offerings?.length) {
      filter.offerings.forEach((offeringId: any) => {
        allOfferings.push(serviceRegistry.serviceData.offerings[offeringId]);
      })
    }
    else {
      allOfferings = serviceRegistry.getOfferingsbyProduct(productId);
    }

    productOfferings = allOfferings.map((offering: any) => offering.id);

    productOfferings.forEach((offeringId: any) => {
      if (!filteredOfferingsIndex[offeringId]) {
        let offering = Object.assign({}, serviceRegistry.serviceData.offerings[offeringId]);
        filteredOfferingsIndex[offeringId] = filteredOffering.length;
        offering.incidents = [];
        filteredOffering.push(offering);
      }

      let offeringIndex = filteredOfferingsIndex[offeringId];

      allData.forEach((incident: any) => {


        if (!incident.clouds) return;
        if (incident.products && incident.products[productId]) {

          let latestMessage = this.getLatestHistory(incident.products[productId]);
          // if(latestMessage.severity === Severity.Trivial && (latestMessage.status !== Status.Closed && latestMessage.status !== Status.Dismissed)) return;
          if ((latestMessage.severity || latestMessage.cmrStatus) && !this.hasRightHistoryStatus(latestMessage)) {
            return;
          }
          if (!latestMessage.severity && !latestMessage.cmrStatus && !this.hasRightStatus(incident)) {
            return;
          }


          if ((incident.offerings && Object.keys(incident.offerings).includes(offeringId)) || (!latestMessage.serviceImpact && latestMessage.severity === "Potential")) {


            if (filter?.regions?.length && !this.hasRegion(incident, filter, incident.products[productId])) {
              return;
            }

            if (filter?.environments?.length && !this.hasEnvironmnets(incident, filter, incident.products[productId])) {
              return;
            }

            let severity = (latestMessage.severity || EventType.maintenance).toLowerCase();
            let status;
             var latestStatus = this.getStatus(incident.products[productId], StatusType.Latest);
            filteredOffering[offeringIndex].events = filteredOffering[offeringIndex].events || {};
             var cmrStartedOn = latestMessage.startedOn || incident.startedOn,
                cmrEndedOn = latestMessage.completedOn || incident.completedOn;

            let pushCurrentIncident = false;

            if (history) {
              if (latestStatus === Status.Scheduled) {
                return;
              }
              pushCurrentIncident = true;
            }
            else if (!history && latestStatus !== Status.Scheduled) {
            
                status = latestStatus.toLowerCase();
              
              var endedDate;
              if (latestMessage.severity === Severity.Trivial) {
                if (incident.products[productId].endedOn) {
                  endedDate = incident.products[productId].endedOn;
                } else {
                  endedDate = this.getLatestTrivialDate(incident.products[productId]);
                  status = Status.Closed.toLowerCase();
                }

              } else {
                endedDate = incident.products[productId].endedOn || cmrEndedOn;
              }
              if (status !== "closed" && status !== "completed" && status !== "canceled" && status !== "dismissed") {
                pushCurrentIncident = true;
              }
              else if ((status === "closed" || status === "dismissed") && isToday(moment(moment.unix(endedDate)))) {
                pushCurrentIncident = true;
              } else if ((status === "completed" || status === "canceled") && isToday(moment(moment.unix(endedDate)))) {
                pushCurrentIncident = true;
              }
            }

            if (pushCurrentIncident) {
              if (latestMessage.severity === Severity.Trivial) {
                filteredOffering[offeringIndex].events["trivial_closed"] = (filteredOffering[offeringIndex].events["trivial_closed"] || 0) + 1;
              } else if(status === "dismissed"){
               filteredOffering[offeringIndex].events[severity + "_closed" ] =
                  (filteredOffering[offeringIndex].events[severity + "_closed" ] || 0) + 1;
              } else if(status === "canceled"){
                 if(this.getStatus(incident.products[productId],StatusType.Previous)==="Started")filteredOffering[offeringIndex].events['maintenance_canceled'] = (filteredOffering[offeringIndex].events['maintenance_canceled'] || 0) + 1;
                  else return;
              }else {
                filteredOffering[offeringIndex].events[(status === "completed" || status === "closed") ? severity + "_" + status : severity] = (filteredOffering[offeringIndex].events[(status === "completed" || status === "closed") ? severity + "_" + status : severity] || 0) + 1;
              }

              if (latestMessage.severity) {
                let Obj: any = {};
                Obj = Object.assign({}, incident.products[productId]);
                Obj.status = latestStatus;
                Obj.incidentId = incident.id;
                filteredOffering[offeringIndex].incidents.push(Obj);
              } else {
                let Obj: any = {};
                Obj = Object.assign({}, incident.products[productId]);
                Obj.startedOn = cmrStartedOn;
                Obj.endedOn = cmrEndedOn;
                Obj.status = latestStatus;
                Obj.incidentId = incident.id;
                filteredOffering[offeringIndex].incidents.push(Obj);
              }
            }

            if (latestStatus === Status.Scheduled) {
              let startDate = moment.unix(parseInt(cmrStartedOn, 10)).local().format('L');
              filteredOffering[offeringIndex].formattedMaintenanceDate = that.locales['maintenance_date'].replace('{maintenanceDate}', startDate);
            }
          }
        }
      });
    });

    filteredOffering = filteredOffering.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    return filteredOffering;
  }


  public filterProducts(serviceRegistry: any, filter: any, cloudId: any, history?: boolean) {
    let filteredProducts: any = [], filteredProductsIndex: any = {}, that = this;
    let allData = [...this.incidents, ...this.maintainences, ...this.globalCommunications];

    let allProducts = serviceRegistry.getCloudProductIds(cloudId) || [];
    let cloudProducts = (filter?.products?.length) ? filter.products : allProducts;

    cloudProducts.forEach((productId: any) => {
      if (!filteredProductsIndex[productId]) {
        let product: any, productData: any;
        productData = serviceRegistry.serviceData.products[productId];
        if (productData) {
          product = Object.assign({}, productData);
          filteredProductsIndex[productId] = filteredProducts.length;
          product.incidents = [];
          filteredProducts.push(product);
        }
      }

      let productIndex = filteredProductsIndex[productId];

      allData.forEach((incident: any) => {
        if (!incident.clouds) return;
        if (!incident.products) return;
        let cuurentProduct = incident.products[productId];
        if (!cuurentProduct) return;
        let latestMessage = this.getLatestHistory(cuurentProduct);
        if ((latestMessage.severity||latestMessage.cmrStatus) && !this.hasRightHistoryStatus(latestMessage)) {
          return;
        }
        if (!latestMessage.severity && !latestMessage.cmrStatus && !this.hasRightStatus(incident)) {
          return;
        }

        if (filteredProducts[productIndex]) {
          if (!this.isCommunication(incident.type) && filter?.regions?.length && !this.hasRegion(incident, filter, cuurentProduct)) {
            return;
          }
          let severity = (latestMessage.severity || EventType.maintenance).toLowerCase();
          let status;
          filteredProducts[productIndex].events = filteredProducts[productIndex].events || {};
          let pushIncident = false;

          var latestStatus = this.getStatus(cuurentProduct, StatusType.Latest);

          var cmrStartedOn = latestMessage.startedOn || incident.startedOn,
                cmrEndedOn = latestMessage.completedOn || incident.completedOn;

          if (history) {
            if (latestStatus === Status.Scheduled) {
              return;
            }
            pushIncident = true;
          }

          else if (!history && latestStatus !== Status.Scheduled) {
 
            status = latestStatus.toLowerCase();

            var endedDate;
           
            if (latestMessage.severity === Severity.Trivial) {
              if (cuurentProduct.endedOn) {
                endedDate = cuurentProduct.endedOn;
              } else {
                endedDate = this.getLatestTrivialDate(cuurentProduct);
                status = Status.Closed.toLowerCase();
              }

            } else {
              endedDate = cuurentProduct.endedOn || cmrEndedOn;
            }

            if ((status !== "closed" && status !== "completed" && status !== "canceled" && status !== "dismissed") || ((status === "closed" || status === "dismissed" || status === "completed" || status === "canceled") && isToday(moment(moment.unix(endedDate))))) {
              pushIncident = true;
            }
          }
          if (pushIncident) {


            if (this.isCommunication(incident.type)) {
              filteredProducts[productIndex].events[(status === "closed") ? "announcement" + "_" + status : "announcement"] =
                (filteredProducts[productIndex].events[(status === "closed") ? "announcement" + "_" + status : "announcement"] || 0) + 1;

            }
            else if (latestMessage.severity === Severity.Trivial) {
              filteredProducts[productIndex].events["trivial_closed"] = (filteredProducts[productIndex].events["trivial_closed"] || 0) + 1;
            } else {
              if (this.isCanceledMaintenaceAfterStart(cuurentProduct, latestMessage.status)) {
                filteredProducts[productIndex].events['canceled_maintenance'] = (filteredProducts[productIndex].events['canceled_maintenance'] || 0) + 1;
              }else if(status === "dismissed"){
               filteredProducts[productIndex].events[severity + "_closed" ] =
                  (filteredProducts[productIndex].events[severity + "_closed" ] || 0) + 1;
              } else if (status !== "canceled" && status !== "dismissed") {
                filteredProducts[productIndex].events[(status === "completed" || status === "closed") ? severity + "_" + status : severity] =
                  (filteredProducts[productIndex].events[(status === "completed" || status === "closed") ? severity + "_" + status : severity] || 0) + 1;
              }
            }

            if (latestMessage.severity) {
              let Obj: any = {}
              Obj = Object.assign({}, cuurentProduct);
              Obj.status = status;
              Obj.incidentId = incident.id;
              Obj.type = latestMessage.severity;
              filteredProducts[productIndex].incidents.push(Obj);
            } else {
              let Obj: any = {};
              Obj = Object.assign({}, cuurentProduct);
              Obj.startedOn = cmrStartedOn;
              Obj.endedOn = cmrEndedOn;
              Obj.status = latestStatus;
              Obj.incidentId = incident.id;
              Obj.type = this.isCommunication(incident.type) ? "announcement" : incident.type;
              filteredProducts[productIndex].incidents.push(Obj);
            }
          }

          if (latestStatus === Status.Scheduled) {
            let startDate = moment.unix(parseInt(cmrStartedOn, 10)).local().format('L');
            filteredProducts[productIndex].formattedMaintenanceDate = that.locales['maintenance_date'].replace('{maintenanceDate}', startDate);
          }
        }
      });
    });

    filteredProducts = filteredProducts.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });


    return filteredProducts;
  }


  public getIncidentsByDate(filter: CasCadingFilterProp, cloud: any, eventTypeFilter: any, statusFilter: any, subscriptionValue?: any, isProductPage?: any) {

    let incidentsByDate: any[] = [], that = this,
      date = moment().format('YYYY-MM-DD'),
      priorityOrder = ["major", "minor", "potential", "maintenance", "Closed", "Dismissed", "Completed", "Canceled"],
      fromDate = moment(filter.date && filter.date[0] ? filter.date[0] : date),
      toDate = moment(filter.date && filter.date[1] ? filter.date[1] : date),
      toDateString = toDate.format('YYYY-MM-DD'),
      numberOfDays = toDate.diff(fromDate, 'days') + 1;
    for (let dateIndex = 0; dateIndex < numberOfDays; dateIndex++) {
      let currentDate = moment(toDateString).subtract(dateIndex, 'days'),
        formatedCurrentDate;
      if (isToday(currentDate)) {
        formatedCurrentDate = that.locales['current'];
      } else if (isYesterday(currentDate)) {
        formatedCurrentDate = that.locales['yesterday'];
      } else {
        formatedCurrentDate = shortDate(currentDate);
      }
      incidentsByDate.push({
        formatedDate: formatedCurrentDate,
        incidents: []
      });

      [...this.incidents, ...this.maintainences, ...this.globalCommunications].forEach((incident: ServerIncident) => {

        if (!this.isCommunication(incident.type || "") && (!incident.clouds || incident.status === 'Scheduled')) return;
        let item: any = {}, clouds = incident.clouds ? Object.keys(incident.clouds) : [],
          eventEndDate, eventStartDate;

        if (this.isCommunication(incident.type || "")) {

          if (eventTypeFilter && eventTypeFilter !== 'all' && "announcement" !== eventTypeFilter) {
            return false;
          }

          if (statusFilter && statusFilter.value !== 'all') {
            if (incident.status !== statusFilter.value) {
              return
            }
          }

          if (incident.type === EventType.global || incident.type === EventType.cloud) {
            eventStartDate = incident.openedOn ? moment(moment.unix(incident.openedOn).format('YYYY-MM-DD')) : null;
            eventEndDate = incident.closedOn ? moment(moment.unix(incident.closedOn).format('YYYY-MM-DD')) : null;

            if ((eventStartDate && eventStartDate.isSameOrBefore(currentDate, 'days')) &&
              (!eventEndDate || eventEndDate.isSameOrAfter(currentDate, 'days'))) {

              let incidents = this.mapIncident(incident, filter.products);

              if (isProductPage) {
                incidentsByDate[incidentsByDate.length - 1].productName = item.productName;
              }
              if (incidentsByDate[incidentsByDate.length - 1].incidents.length) {
                Array.prototype.push.apply(incidentsByDate[incidentsByDate.length - 1].incidents, incidents)
              } else {
                incidentsByDate[incidentsByDate.length - 1].incidents = incidents;
              }
            }

          }


        }
        if (cloud === 'all' || (clouds.indexOf(cloud) !== -1)) {
          if (!filter.products.length || !this.applyFilter(incident, filter)) {
            return false;
          }
          let currentProduct = incident.products[filter.products],
            latestHistory: History = this.getLatestHistory(currentProduct);

          if (!this.applyFilter(incident, filter, currentProduct)) {
            return false;
          }

          if ((latestHistory.severity || latestHistory.cmrStatus ) && !this.hasRightHistoryStatus(latestHistory)) {
            return;
          }
          if ((!latestHistory.severity && !latestHistory.cmrStatus && !this.hasRightStatus((incident))) || this.getStatus(currentProduct, StatusType.Latest) === Status.Scheduled) {
            return;
          }

          if (this.isCommunication(incident.type || '')) {
            eventStartDate = incident.openedOn ? moment(moment.unix(incident.openedOn).format('YYYY-MM-DD')) : null;
            eventEndDate = incident.closedOn ? moment(moment.unix(incident.closedOn).format('YYYY-MM-DD')) : null;
          }
          else if (latestHistory.severity) { // CSO
            eventEndDate = currentProduct.endedOn ? moment(moment.unix(currentProduct.endedOn).format('YYYY-MM-DD')) : null;
            eventStartDate = moment(moment.unix(currentProduct.startedOn).format('YYYY-MM-DD'));
          } else { //CMR
            let startedOn,endedOn;
            startedOn = latestHistory.startedOn || incident.startedOn;
            if(this.getStatus(currentProduct, StatusType.Latest) === Status.Completed || this.getStatus(currentProduct, StatusType.Latest) === Status.Canceled){
               endedOn = latestHistory.completedOn || incident.completedOn;
            }
            eventStartDate = startedOn ? moment(moment.unix(startedOn).format('YYYY-MM-DD')) : null;
            eventEndDate = endedOn ? moment(moment.unix(endedOn).format('YYYY-MM-DD')) : null;
          }
          let eventType = this.isCommunication(incident.type || '') ? "announcement" : (latestHistory.severity || EventType.maintenance).toLowerCase();
          let latestStatus = this.getStatus(currentProduct, StatusType.Latest);

          if (latestHistory.severity === Severity.Trivial) {
            if (currentProduct.endedOn) {
              eventEndDate = moment(moment.unix(currentProduct.endedOn).format('YYYY-MM-DD'));
            } else {
              eventEndDate = moment(moment.unix(this.getLatestTrivialDate(currentProduct)));
              latestStatus = Status.Closed;
            }
          }

          if (latestHistory.severity === Severity.Trivial && latestStatus !== Status.Closed && latestStatus !== Status.Dismissed) return;

          if (!isToday(currentDate) && eventType === EventType.potential) {
            return;
          }
          if (!isToday(currentDate) && latestStatus === Status.Dismissed) {
            return;
          }

          if (!isToday(currentDate) && latestStatus === Status.Canceled) {
            return;
          }

          if (eventTypeFilter && eventTypeFilter !== 'all' && eventType !== eventTypeFilter) {
            return false;
          }

          if (statusFilter && statusFilter.value !== 'all') {
            if (eventType === EventType.maintenance) {
              if (incident.status !== statusFilter.value) {
                return
              }
            } else {
              let latestStatus = latestHistory.status === Status.Discovery ? Status.Opened : latestHistory.status === Status.Dismissed ? Status.Closed : latestHistory.status;
              if (latestStatus !== statusFilter.value) {
                return
              }
            }
          }
          //Start date should be same or before current date
          //End date should be same or after current date

          if ((eventStartDate && eventStartDate.isSameOrBefore(currentDate, 'days')) &&
            (!eventEndDate || eventEndDate.isSameOrAfter(currentDate, 'days'))) {

            let incidents = this.mapIncident(incident, filter.products);

            if (isProductPage) {
              incidentsByDate[incidentsByDate.length - 1].productName = item.productName;
            }
            if (incidentsByDate[incidentsByDate.length - 1].incidents.length) {
              Array.prototype.push.apply(incidentsByDate[incidentsByDate.length - 1].incidents, incidents)
            } else {
              incidentsByDate[incidentsByDate.length - 1].incidents = incidents;
            }
          }

        }

      });

      incidentsByDate[incidentsByDate.length - 1].incidents = incidentsByDate[incidentsByDate.length - 1].incidents.sort((a: any, b: any) => {
        var closedStates = ["Closed", "Dismissed", "Completed", "Canceled"],
          left = priorityOrder.indexOf(
            closedStates.indexOf(a.status) === -1 ? a.eventType.toLowerCase() : a.status
          ),
          right = priorityOrder.indexOf(
            closedStates.indexOf(b.status) === -1 ? b.eventType.toLowerCase() : b.status
          );

        if (left === right) {
          return (b.updatedMessageTime || b.startedOn) - (a.updatedMessageTime || a.startedOn);
        }

        return left - right;
      });
    }
    return incidentsByDate;
  }


  public getUrlParameter(sParam: any) {
    let sPageURL = decodeURIComponent(window.location.search.substring(1)), sURLVariables = sPageURL.split('&'),
      sParameterName, i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? '' : sParameterName[1];
      }
    }
    return '';
  }

  public getSubscribedOngoingIncidents(subscriptions: any) {
    let subscribedIncidents: any = [];

    subscriptions.forEach((subscription: any) => {

      let incident: any;
      if (!subscription.products.length) {

        if (subscription.clouds && subscription.clouds.length && !subscription.clouds.includes('All Clouds')) {
          subscription.clouds.forEach((cloudName: any) => {
            this.cloudMapping.forEach((cloud: any) => {
              if (cloud.name === cloudName) {
                incident = this.getIncident(subscription.eventId, '', cloud.id);
                if (incident && ![Status.Closed, Status.Completed, Status.Canceled].includes(incident.status)) {
                  subscribedIncidents.push(incident);
                }
              }
            });
          });
        }
        else {
          incident = this.getIncident(subscription.eventId);
          if (incident && ![Status.Closed, Status.Completed, Status.Canceled].includes(incident.status)) {
            subscribedIncidents.push(incident);
          }
        }

      } else {
        subscription.products.forEach((productId: any) => {
          incident = this.getIncident(subscription.eventId, productId);
          if (incident && ![Status.Closed, Status.Completed, Status.Canceled].includes(incident.status)) {
            subscribedIncidents.push(incident);
          }
        });
      }
    })
    subscribedIncidents.sort((a: any, b: any) => (INCIDENTPRIORITIES.indexOf(a.eventType) - INCIDENTPRIORITIES.indexOf(b.eventType)) || (b.startedOn - a.startedOn));

    return subscribedIncidents;
  }
  /**
   * 
   * @param eventSubscription 
   * @param selfSubscriptions 
   * @param entitlements 
   * @returns 
   */
  public getMyEvents(eventSubscription: EventSubscription[], selfSubscriptions: any[], entitlements: any[]) {
    let productIds: any = {}, subscriptionIds: any = {};
    productIds = selfSubscriptions.reduce((productMap: any, subscription: any) => {
      if (subscription.product?.type === SubscriptionType.Product) {
        productMap[subscription.product.id] = []
      } else {
        let offeringIds = subscription.productOfferings?.reduce((offeringList: any[], offering: any) => {
          offeringList.push(offering.id)
          return offeringList;
        }, [])
        productMap[subscription.product.id] = offeringIds
      }
      return productMap;
    }, {})

    entitlements.forEach((productId: string) => {
      if (!productIds[productId]) {
        productIds[productId] = []
      }
    });

    subscriptionIds = eventSubscription.reduce((eventMap: any, subsription: EventSubscription) => {
      eventMap[subsription.eventId] = subsription.products;
      return eventMap;
    }, {});

    let incidents: Incident[] = this.getOnGoingEvents();
    return incidents.filter((incident: Incident) => {
      let state: boolean = false;
      if (subscriptionIds[incident.incidentId]) {
        state = true;
        if (subscriptionIds[incident.incidentId]?.length) {
          state = subscriptionIds[incident.incidentId].includes(incident.productId)
        }
      }

      if (state)
        return true;

      if (incident.productId) {
        return this.isMyEvents(productIds, incident.productId, incident.offerings);
      } else {
        return (incident.products && Object.keys(incident.products).some((productId: any) => this.isMyEvents(productIds, productId, incident.offerings)))
      }
    });
  }

  private isMyEvents(productIds: any[], productId: any, offerings: any) {
    if (productIds[productId]?.length > 0) {
      let offeringsIds = productIds[productId];
      return offerings ? Object.keys(offerings).some((id: any) => offeringsIds.includes(id)) : false
    } else {
      return (productIds[productId]);
    }
  }
}