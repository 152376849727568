import { ConfigContext } from "../../App";
import React, { useContext, useEffect, useState } from 'react';
import { EventType, Incident, IncidentGroup, Status } from "../../proptype";
import { useHistory } from "react-router-dom";
import { ServiceRegistry } from "../../models/service-registry";
import { EventService } from "../../models/event-service";
import { SUBMENU, INCIDENTPRIORITIES, BANNERPRIORITIES } from "../../constants";
import parse from 'html-react-parser';
import ReactTooltip from "react-tooltip";
import { filterEmptyAnnouncements, filterEventsByFilter, filterOngoingCanceledMaitenaceEvents, filterScheduledMaitenaceEvents } from '../../utils';
import MenuList from "../menuList/menulist";

export default function Banner(props: any) {

  const handleResize = () => {
    if (window.matchMedia("only screen and (max-width: 767px)").matches) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  };

  const locales = useContext(ConfigContext).appConfig.languageData;
  const histroyAPI = useHistory();

  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [allEvents, setAllEvents] = useState<IncidentGroup[]>([]);
  const [bannerClass, setBannerClass] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(true);
  const [onlyScheduled, setOnlyScheduled] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
  const eventService: EventService = EventService.init();
  eventService.setLocales(locales);


  useEffect(() => {
    if (props.id || !props.isParentLoading) {
      loadData();
    }
  }, [props])

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setIsMobile(!!window.matchMedia("only screen and (max-width: 767px)").matches);
    const [servicePromise, productPromise]: any = [serviceRegistry.fetch(), eventService.fetch()];
    Promise.all([servicePromise, productPromise])
      .then(() => {
        loadData();
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
    return () => {
      setMounted(false);
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  function checkScheduled(allOngoingEvents: any) {
   var eventsToCheck = filterEmptyAnnouncements(allOngoingEvents);
    if (eventsToCheck.length && eventsToCheck[0].list) {
      var scheduled = true;
      eventsToCheck[0].list.forEach((incident: any) => {
        if (incident.status !== "Scheduled") {
          scheduled = false;
        }
      })
      return scheduled;
    }
    return false;
  }

  function loadData() {
    if (mounted) {
      let onGoingEvents: IncidentGroup[];
      let filteredEvents: any[] = [];
      

      if(props.allEvents){
        if(props.allEvents[0]?.incidents){
          props.allEvents[0].incidents.forEach((incident:any)=>{
            filteredEvents.push(incident.incidentId);
          })
        }   
      }
    
      if (props.id) {
        onGoingEvents = eventService.getOnGoingEventsByType(props.id && !props.isProduct ? props.id : undefined, undefined, undefined, undefined, props.isProduct ? props.id : undefined, true);
      } else {
        onGoingEvents = eventService.getOnGoingEventsByType(props.id && !props.isProduct ? props.id : undefined, undefined, undefined, undefined, props.isProduct ? props.id : undefined);
      } 

      let incidentsByType: IncidentGroup[] = [];
      if (onGoingEvents.length > 0){
      
      onGoingEvents.forEach((incidentGroup: IncidentGroup) => {
          incidentsByType.push({
            type: incidentGroup.type,
            eventType: incidentGroup.eventType,
            list: incidentGroup.list
          });
      })

      incidentsByType.sort((a: IncidentGroup, b: IncidentGroup) => {
        let aIndex = BANNERPRIORITIES.indexOf(a.type), bIndex = BANNERPRIORITIES.indexOf(b.type);
        if (aIndex === -1) aIndex = BANNERPRIORITIES.length;
        if (bIndex === -1) bIndex = BANNERPRIORITIES.length;
        return aIndex - bIndex;
      });  
      }

      let allOngoingEvents = incidentsByType.slice();

      if (props.showOnlyScheduledCanceledMaintenace) {
        allOngoingEvents = filterOngoingCanceledMaitenaceEvents(allOngoingEvents);
      }
      if(props.allEvents){
        allOngoingEvents = filterEventsByFilter(allOngoingEvents,filteredEvents);
      }

      let scheduled = checkScheduled(allOngoingEvents);
      //check if there's only scheduled maintenance and no other events
      if (allOngoingEvents.length === 1 && scheduled) {
        setOnlyScheduled(scheduled);
      } else {
        setOnlyScheduled(false);
      }

      allOngoingEvents = filterScheduledMaitenaceEvents(allOngoingEvents);
      allOngoingEvents =  filterEmptyAnnouncements(allOngoingEvents);

      setAllEvents(allOngoingEvents);
      if (allOngoingEvents.length && allOngoingEvents[0]?.list.length > 0 && allOngoingEvents[0]?.list[0].eventType && !scheduled) {
        setBannerClass(allOngoingEvents[0].type);  
      } else if (allOngoingEvents.length && allOngoingEvents[1]?.list.length > 0 && allOngoingEvents[1]?.list[0].eventType && scheduled){
        setBannerClass(allOngoingEvents[1].type);
      }else {
        let name: any;
        if(props?.offerings?.length >0){
          name="";
        }
        else if (props.isProduct) {
          if (props.productName) {
            name = props.productName;
          }
        }
        if (props.id && !props.isProduct) {
          name = SUBMENU.filter((menu: any) => menu.id === props.id)[0].name;
        }
        setBannerClass('available');

        if (props.id && name) {
          setBannerTitle(locales.home_service_available.replace("{cloudName}", `<b>${name}</b>`));
        } else {
          if(props?.offerings?.length >0){
            setBannerTitle(locales.home_service_available.replace("{cloudName}", `<b>${name}</b>`));
          }else{
            setBannerTitle(locales.adobe_service_available);
          }
        }
      }
    }

  }

  function buildBannerList(filteredList: any, item: any, index: number) {
    let map_string: string = '', isLastIndex = filteredList.length === (index + 1), eventString: string = '';
    if (item.type === "resolved" || item.type === "resolved_maintenance" || item.type === 'canceled_maintenance'|| item.type === "closed_announcement") {

      var events_priority = ["major", "minor", "potential", "trivial", "maintenance","announcement"];
      //get all event types
      var resArr: any[] = [];
      resArr = item.list.map((incident: any) =>
        (["Global","Cloud","Product"].includes(incident.eventType))?"announcement":incident.eventType
      )
      //count event types
      let groupEventTypes: any[] = [];
      resArr.forEach((eventType: any) => {
        let eventTypeLocal=eventType;
        if (["Global","Cloud","Product"].includes(eventType)){
          eventTypeLocal ="announcement"
        }
        if (groupEventTypes[eventTypeLocal]) {
          groupEventTypes[eventTypeLocal].count += 1;
        } else {
          groupEventTypes[eventTypeLocal] = {
            type: eventTypeLocal,
            count: 1
          }
        }
      })

      //unique event types
      var filteredEventTypes: any[] = resArr.filter((itemType: any, pos: any) => {
        return resArr.indexOf(itemType) === pos;
      }).sort(function (a, b) {
        return events_priority.indexOf(a) - events_priority.indexOf(b);
      });

      //assign map string
      filteredEventTypes.forEach((eventType: any, eventIndex: number, eventList: any) => {

        let eventObj = groupEventTypes[eventType];
        if (eventObj.type === "announcement"){
          map_string += (eventObj.count > 1 ? locales["closed_announcements"] : locales["closed_announcement"])
            .replace("{resolvedCount}", eventObj.count);
        }
        else if (eventObj.type === "maintenance" && item.type === "resolved_maintenance") {
          map_string += (eventObj.count > 1 ? locales["resolved_maintenances"] : locales["resolved_maintenance"])
            .replace("{resolvedCount}", eventObj.count);
        } else if (eventObj.type === "maintenance" && item.type === 'canceled_maintenance') {
          map_string += (eventObj.count > 1 ? locales["canceled_maintenances"] : locales['canceled_maintenance'])
            .replace("{canceledCount}", eventObj.count);
        } else {
          map_string += (eventObj.count > 1 ? locales["closedCSOs"] : locales["closedCSO"])
            .replace("{count}", eventObj.count)
            .replace("{eventType}", locales[eventObj.type]);
        }

        if (eventIndex !== eventList.length - 1) {
          map_string += "__";
        }

      })
    } else {
      for (var listIndex = filteredList.length - 1; listIndex >= 0; listIndex--) {
        if (filteredList[listIndex].type.includes("resolved") || filteredList[listIndex].type.includes("closed")) {
          isLastIndex = listIndex === (index + 1)
        }
      }
      item.list = item.list.filter((listItem: any) => listItem.status !== Status.Scheduled);
      eventString = item.list.length > 1 ? "_events" : "_event";
      map_string = locales[(item.type + eventString) as keyof typeof locales];
      if (map_string) {
        map_string = map_string.replace(`{${item.type}Count}`, item.list.length.toString());

        if (isLastIndex && item.type !== "maintenance") {
          map_string += ` ${locales.in_progress}`;
        }
      }
    }
    return map_string;
  }




  function isEventType(type: string) {
    let eventTypes: any = Object.values(EventType);
    return eventTypes.includes(type);
  }


  function buildBannerText(eventsGroup: IncidentGroup[]) {
    let count: number = 0, issues_string:any, count_string:any, issue_array:any[]=[], indexVar:any=0;
    if (isMobile) {
      eventsGroup = eventsGroup.filter((item: IncidentGroup) => item.list.length > 0)
      eventsGroup.forEach((item: IncidentGroup, index: number) => {
        issue_array.push(buildBannerList(eventsGroup, item, index));
        if(index === 0 && item.list.length ===0){indexVar++}
        if (index === 0) return;
        else if(index!==indexVar) count += item.list.length;
      });

      if (count > 0) {
        count_string = `${locales.other_incidents_banner}`.replace("{otherIncidentsCount}", count.toString());
      }
      return <li key={0}>{`${issue_array[indexVar]} ${count_string ? count_string : ""}`}</li>
    } else {
      let filteredEvents = eventsGroup.filter((item: IncidentGroup) => item.list.length > 0)
      return filteredEvents.map((item: IncidentGroup, index: number) => {

        if (!isEventType(item.type) && item.type !== "resolved" && item.type !== "resolved_maintenance" && item.type !== 'canceled_maintenance' && item.type !== "closed_announcement") return <></>;
        issues_string = buildBannerList(filteredEvents, item, index);
        if (issues_string.includes("__")) {
          var issue_strings = issues_string.split("__");
          return issue_strings.map((string: any, stringIndex: number) => {
            return <li key={stringIndex}> {string} </li>
          })
        } else {
          return <li key={index}> {parse(issues_string)} </li>
        }
      });
    }
  }


  function buildTooltipText(eventsGroup: IncidentGroup[]) {
    let issues_string, final_string = "";
    if (!isMobile) {
      let filteredEvents = eventsGroup.filter((item: IncidentGroup) => item.list.length > 0)
      filteredEvents.forEach((item: IncidentGroup, index: number) => {

        if (!isEventType(item.type) && item.type !== "resolved" && item.type !== "resolved_maintenance" && item.type !== 'canceled_maintenance' && item.type !== "closed_announcement") return;
        issues_string = buildBannerList(filteredEvents, item, index);
        if (issues_string.includes("__")) {
          var issue_strings = issues_string.split("__");
          issue_strings.forEach((string: any, stringIndex: number) => {
            final_string += `<li>${string}</li>`;
          })
        } else {
          final_string += `<li>${parse(issues_string)}</li>`;
        }
      });
      return final_string;
    }
    return "";
  }

  return (
    <div className={`banner-container ${props.id ? 'service-banner' : 'overview-banner'}`}>
      <div className={`status-stripe ${bannerClass}`}>
        <div className="container">
          <div className="row">
            {loading ? <div className="col-md-8">{locales.loading.toUpperCase()}...</div> : <div className="col-md-8">
              {allEvents.length && !onlyScheduled ?
                <span className="banner-list">
                  <span className="icon status-icon"></span>
                  <ul data-tip={buildTooltipText(allEvents)} data-html={true} data-arrow-color={"#E6E3E3"} className="separator-list">
                    {buildBannerText(allEvents)}
                    <ReactTooltip html={true} />
                  </ul>
                </span> :
                <span>
                  <span className="icon status-icon-available"></span>
                  <span>{parse(bannerTitle)}</span>
                </span>
              }
              {isMobile ? <>
            {/* <MenuList isMobile={true} ></MenuList>  */}
             <MenuList isMobile={true} navstate={props.navstate}/>
            <button className="pns-link" onClick={() => { histroyAPI.push("/proactive-notifications/manage") }}> <i className="subscribe-icon"></i></button> </>: ""}
            </div>
            }
            {!isMobile ?
              <div className="col-md-4 desktop-pns">
                <MenuList navstate={props.navstate}/>
                 {/* <MenuList /> */}
                <button className="pns-link" onClick={() => { histroyAPI.push("/proactive-notifications/manage") }}> <span className="subscribe-banner"> <span className="text">{locales.manage_subscriptions}</span><span className="subscribe-icon"></span></span></button>
                
              </div> : ""
            }
          </div>
        </div>
      </div>
    </div>
  );
}
