import axios, { AxiosPromise, AxiosResponse } from "axios";
import { EVENTTYPES, REGIONS, SUBMENU, URLS } from "../constants";
import { SubscriptionType } from "../proptype";
import {
  RegistryCloud,
  RegistryData,
  RegistryEnvironment,
  RegistryProduct,
  RegistryService,
} from "../service-registry.d";
import { cloneArrayOfObject, isBizible, removeDuplicate } from "../utils";

export class ServiceRegistry {
  api_url: string;
  serviceData: RegistryData;

  private static instance: ServiceRegistry;
  private _lastModified: string = "";
  private _response: any;
  private _promise: AxiosPromise | null | undefined;
  private _headPromise: Promise<string> | null | undefined;

  private constructor() {
    this.api_url = URLS.SERVICEMAP_URL;
    this.serviceData = {
      clouds: {},
      products: {},
      offerings: {},
      services: {},
      environments: {},
      regions: {},
    };
  }

  private getDataCenters(environments: RegistryService[]) {
    let dataCenters = environments.reduce(
      (dataCenters: any, environment: RegistryService) => {
        let split = environment.name?.split(/-(.+)/),
          dataCenter = split?split[0]:"None";

        if (dataCenters[dataCenter]) {
          dataCenters[dataCenter]["serviceEnvironments"].push(environment);
        } else {
          dataCenters[dataCenter] = {
            name: dataCenter,
            id: dataCenter.replace(/\s/g, "-"),
            isOpen: false,
            serviceEnvironments: [environment],
          };
        }

        return dataCenters;
      },
      {}
    );

    return Object.values(dataCenters);
  }

  private getOfferings(service: any) {
    let offeringList: any[] = [];
    if (!service) return [];

    if (service?.productOfferings?.length) {
      offeringList = service.productOfferings.reduce(
        (result: any, offeringId: any) => {
          if (this.serviceData.offerings[offeringId]) {
            let productOfferings: any =
              [this.serviceData.offerings[offeringId]]

            Array.prototype.push.apply(result, productOfferings);
           
          }
          return result;
        },
        []
      );
    }

    offeringList = this.sort(offeringList, "name");
    return offeringList;
  }
  private getEnviroments(service: RegistryService): RegistryEnvironment[] {
    let environments: RegistryEnvironment[] = [];
    if (service?.productOfferings?.length) {
      service.productOfferings.forEach((offeringId: string) => {
        Array.prototype.push.apply(
          environments,
          this.getEnviroments(this.serviceData.offerings[offeringId])
        );
      });
    }
    if (service?.productServices?.length) {
      Array.prototype.push.apply(
        environments,
        this.getServiceEnvironments(service.productServices)
      );
    }

    return environments;
  }

  private isEntitled(
    service: RegistryProduct | RegistryService,
    entitlements: string[]
  ): boolean {
    if (service?.serviceCode?.length) {
      return service.serviceCode.some((code: string) =>
        entitlements.includes(code)
      );
    }

    return false;
  }

  private getServiceEnvironments(
    productServices: RegistryService[]
  ): RegistryEnvironment[] {
    let serviceEnvironments: RegistryEnvironment[] = [];
    if (productServices?.length) {
      productServices = productServices
        .filter((serviceId: any) => !!this.serviceData.services[serviceId])
        .map((serviceId: any) => this.serviceData.services[serviceId]);

      serviceEnvironments = productServices.reduce(
        (environments: any, productService: any) => {
          if (productService?.serviceEnvironments?.length) {
            let envList = productService.serviceEnvironments
              .filter(
                (environmentId: any) =>
                  !!this.serviceData.environments[environmentId]
              )
              .map((environmentId: any) => {
                return this.serviceData.environments[environmentId];
              });
            Array.prototype.push.apply(environments, envList);
          }

          return environments;
        },
        []
      );
    }

    return serviceEnvironments;
  }
  private getServicesAndEnvironments(service: RegistryProduct) {
    let result: any = {
      services: [],
      environments: [],
    };
    if (
      service.productOfferings?.length
    ) {
      result.services = this.getOfferings(service);

      result.services = result.services.map((offering: RegistryService) => {
        offering.serviceEnvironments = this.getEnviroments(offering);
        Array.prototype.push.apply(
          result.environments,
          offering.serviceEnvironments
        );
        return offering;
      });
    } else {
      result.environments = this.getEnviroments(service);
    }

    result.environments = removeDuplicate(result.environments, "id");
    result.environments = this.sort(result.environments, "name");
    result.services = this.sort(result.services, "name");

    return result;
  }

  private sort(values: any, by: any) {
    return values.sort((a: any, b: any) => {
      if (a[by] > b[by]) {
        return 1;
      }
      if (a[by] < b[by]) {
        return -1;
      }
      return 0;
    });
  }

  private getLastModified() {
    if (!this._headPromise) {
      this._headPromise = new Promise((resolve, reject) => {
        axios
          .head(this.api_url)
          .then((response: AxiosResponse) => {
            delete this._headPromise;
            resolve(response.headers["last-modified"]);
          })
          .catch(() => {
            delete this._headPromise;
            reject("");
          });
      });
    }

    return this._headPromise;
  }

  private _fetch(clearCache?: boolean) {
    this._promise = axios.get(this.api_url);

    this._promise
      .then((result: AxiosResponse) => {
        if (result.status === 200 && result.data) {
          if (
            result.headers["last-modified"] &&
            this._lastModified !== result.headers["last-modified"]
          ) {
            this._response = null;
            this._lastModified = result.headers["last-modified"];
          }
          this.set(result.data, clearCache);
        }
        delete this._promise;
      })
      .catch(() => {
        this._response = null;
        delete this._promise;
      });

    

    return this._promise;
  }

  public static init() {
    if (!ServiceRegistry.instance) {
      ServiceRegistry.instance = new ServiceRegistry();
    }
    return ServiceRegistry.instance;
  }


    public async fetch(clearCache?: boolean) {
        if (clearCache) {
            this._response = null;
        }
        if (this._promise) {
            return this._promise;
        }

        if (this._response) {
            let lastModifiedDate = '';
            try {
                if (this._headPromise) {
                    lastModifiedDate = await this._headPromise;
                } else {
                    await this.getLastModified();
                }
                if (lastModifiedDate && lastModifiedDate !== this._lastModified) {
                    return this._fetch();
                } else {
                    this.serviceData = JSON.parse(JSON.stringify(this._response.data));
                    return Promise.resolve(this._response);
                }
            } catch {
                this.serviceData = JSON.parse(JSON.stringify(this._response.data));
                return Promise.resolve(this._response);
            }
        }
        return this._fetch(clearCache);

    }

  public fetchCached() {
    if (this._response) {
      return Promise.resolve(this._response);
    } else {
      return this._promise ? this._promise : this._fetch();
    }
  }

  private set(result: any, clearCache?: boolean) {
    if (result) {
      this._response = !clearCache
        ? { data: JSON.parse(JSON.stringify(result)) }
        : null;
      this.serviceData = result;
    }
    return this.serviceData;
  }

  public getCloudProductIds(cloud: string) {
    if (cloud && this.serviceData.clouds && this.serviceData.clouds[cloud]) {
      return this.serviceData.clouds[cloud]?.cloudProducts || [];
    }

    return [];
  }
  public getProductName(productId: string) {
    let product = this.serviceData?.products[productId];
    return product?.name || "";
  }
  public getCloudId(productId: string) {
   
    for (let cloudId in this.serviceData.clouds) {
      if (
        this.serviceData.clouds.hasOwnProperty(cloudId) &&
        this.serviceData.clouds[cloudId]?.cloudProducts?.indexOf(productId) !== -1
      ) {
        return cloudId;
      }
    }

    return null;
  }

  public getCloudName(productId: string) {
    for (let cloudId in this.serviceData.clouds) {
      if (this.serviceData.clouds.hasOwnProperty(cloudId)) {
        let hasProduct = (
          this.serviceData.clouds[cloudId]?.cloudProducts || []
        ).some((product: any) => {
          if (typeof product === "string" && product === productId) {
            return true;
          }
          //Commenting as cloudproducts is always an array of strings
          // if (typeof product === "object" && product.id === productId) {
          //   return true;
          // }

          return false;
        });

        if (hasProduct) {
          return this.serviceData.clouds[cloudId].name;
        }
      }
    }

    return "";
  }
  public getProductList(cloud: string) {

    return this.sort(
      this.getCloudProductIds(cloud)
        .filter((productId: any) => !!this.serviceData.products[productId])
        .map((productId: any) => {
          return this.serviceData.products[productId];
        }),
      "name"
    );

  }

  public getOfferingsbyProduct(productId: any) {
    return this.getOfferings(this.serviceData.products[productId]);
  }
  public getProductServicesAndEnvironments(productId: any) {
    let result: any = {
      services: [],
      environments: [],
    };
    if (productId && this.serviceData.products[productId]) {
      let product = this.serviceData.products[productId];
      if (!product.showEnvironment) {

        result.services = this.getOfferings(product);
      } else {
        result = this.getServicesAndEnvironments(product);
      }
    }
    return result;
  }

  public getProductsAndOfferings() {
    let products: any[] = [],
      offerings: any = [];

    Object.values(this.serviceData.clouds).forEach((cloud: any) => {
      if (cloud.cloudProducts) {
        cloud.cloudProducts.forEach((productId: any) => {
          let product = this.serviceData.products[productId],
            productOfferings;
          if (product) {
            productOfferings = this.getOfferings(product);
            Array.prototype.push.apply(offerings, productOfferings);
            products.push(product);
          }
        });
      }
    });
    return {
      products: this.sort(products, "name"),
      offerings: this.sort(offerings, "name"),
    };
  }
  public getServiceCodeMap() {
    const entitlementMap = new Map();
    Object.values(this.serviceData.products).forEach((product: any) => {
      if (product.serviceCode && product.serviceCode.length) {
        product.serviceCode.forEach((code: any) => {
          entitlementMap.set(code, product.id);
        });
      } else {
        if (product.productOfferings) {
          product.productOfferings.forEach((offeringId: any) => {
            const productOfferings = this.serviceData.offerings[offeringId];
            if (
              productOfferings?.serviceCode &&
              productOfferings?.serviceCode.length
            ) {
              productOfferings.serviceCode.forEach((code: any) => {
                entitlementMap.set(code, product.id);
              });
            }
          });
        }
      }
    });
    return entitlementMap;
  }

  public getProductIdFromOfferingId(offeringId: any) {
    if (this.serviceData.offerings[offeringId]) {
      let products: any = this.serviceData.products;

      for (let productId in products) {
        if (
          products.hasOwnProperty(productId) &&
          products[productId].productOfferings &&
          products[productId].productOfferings.indexOf(offeringId) !== -1
        ) {
          return productId;
        }
      }
    }
    return null;
  }

  public getTransformedSubscription(
    subscription: any,
    offeringId: any,
    productId: any
  ) {
    return {
      product: { id: productId, type: "2" },
      environments: subscription.environments,
      eventType: subscription.eventType,
      productOfferings: [
        {
          id: offeringId,
          regions: subscription.productOfferings[0].regions,
          environments: subscription.productOfferings[0].environments,
        },
      ],
      regions: subscription.regions,
    };
  }

  public getProductsByCloud(entitlements?: string[]) {
    let clouds: any[] = Object.values(this.serviceData.clouds);

    clouds = clouds
      .filter((cloud: RegistryCloud) => !!cloud.cloudProducts)
      .map((cloud: RegistryCloud) => {
        cloud.cloudProducts = cloud.cloudProducts
          .map((productId: string) => {
            let product: RegistryProduct = this.serviceData.products[productId];
            if (!product) return null;

            product.isEntitled =
              !!entitlements && this.isEntitled(product, entitlements);
            product.active = !!entitlements && product.isEntitled;
            if (product.showEnvironment) {
              product.serviceEnvironments = removeDuplicate(
                this.getEnviroments(product),
                "id"
              );
              product.serviceEnvironments = this.sort(
                product.serviceEnvironments,
                "name"
              );
            } else {
              product.regions = cloneArrayOfObject(REGIONS);
            }
            if (product?.productOfferings?.length) {
              let dataCenterMap: any = {};
              product.productOfferings = product.productOfferings
                .filter(
                  (offeringId: string) =>
                    !!this.serviceData.offerings[offeringId]
                )
                .map((offeringId: string) => {
                  let offering = this.serviceData.offerings[offeringId];

                  offering.isEntitled =
                    !!entitlements && this.isEntitled(offering, entitlements);
                  offering.active = !!entitlements && offering.isEntitled;
                  if (product.showEnvironment) {
                    offering.serviceEnvironments = removeDuplicate(
                      this.getEnviroments(offering),
                      "id"
                    );
                    if (offering.serviceEnvironments?.length) {
                      offering.serviceDataCenters = this.getDataCenters(
                        offering.serviceEnvironments
                      );
                      offering.serviceDataCenters.forEach((dataCenter: any) => {
                        if (dataCenterMap[dataCenter.name]) {
                          dataCenter.serviceEnvironments.forEach(
                            (environment: any) => {
                              if (
                                !dataCenterMap[dataCenter.name].some(
                                  (env: any) => env.id === environment.id
                                )
                              ) {
                                dataCenterMap[dataCenter.name].push(
                                  environment
                                );
                              }
                            }
                          );
                        } else {
                          dataCenterMap[dataCenter.name] =
                            dataCenter.serviceEnvironments;
                        }
                      });
                    }
                  }
                  return offering;
                });

              if (product.showEnvironment) {
                product.productOfferings = product.productOfferings.filter(
                  (offering: RegistryService) =>
                    offering.serviceEnvironments?.length
                );
              }
              let activeOfferings: number = product.productOfferings.filter(
                (offering: RegistryService) => offering.active
              ).length;
              product.partial =
                !!entitlements &&
                !!activeOfferings &&
                product.productOfferings.length !== activeOfferings;
              if (product.active) {
                product.productOfferings = product.productOfferings.map(
                  (offering: RegistryService) => {
                    offering.active = true;
                    return offering;
                  }
                );
              }
              product.active = product.active || product.partial;
            }

            product.isOpen = product.active;
            product.eventTypes = cloneArrayOfObject(EVENTTYPES);

            return product;
          })
          .filter((product: RegistryProduct | null) => {
            if (product) {
              if (product.showEnvironment) {
                if (product.productOfferings?.length) {
                  return product.productOfferings.some(
                    (offering: RegistryService) =>
                      offering.serviceEnvironments?.length
                  );
                } else {
                  return product.serviceEnvironments?.length;
                }
              }
              return true;
            }
            return false;
          });

        cloud.isOpen = cloud.cloudProducts.some(
          (product: RegistryProduct) => product.active
        );

        cloud.cloudProducts.sort((a: RegistryProduct, b: RegistryProduct) =>
          a.name.localeCompare(b.name)
        );

        return cloud;
      });
    //just to order;
    let menus = SUBMENU.filter((cloud: any) => cloud.id).reduce(
      (menus: any, menu: any) => {
        menus[menu.id] = menu;
        return menus;
      },
      {}
    );

    return clouds
      .filter((cloud: RegistryCloud) => !!menus[cloud.id])
      .sort(
        (cloudA: RegistryCloud, cloudB: RegistryCloud) =>
          menus[cloudA.id].orderid - menus[cloudB.id].orderid
      );
  }

  public getUpdatedCloudList(subscriptions: any[], callback: Function) {
    let cloudList: RegistryCloud[] = this.getProductsByCloud();
    let subscriptionMap = subscriptions.reduce(
        (map: any, subscription: any) => {
          if (subscription?.productOfferings?.length) {
            subscription.offeringIds = subscription.productOfferings.map(
              (offering: any) => offering.id
            );
          }
          map[subscription.product.id] = subscription;
          return map;
        },
        {}
      ),
      productIDs = Object.keys(subscriptionMap),
      isBizibleEnvironmentSelected = false;

    cloudList = cloudList.map((cloud: RegistryCloud) => {
      cloud.cloudProducts = cloud.cloudProducts.map(
        (product: RegistryProduct) => {
          let subscribeEnvIds: any[] = [];
          product.active = productIDs.includes(product.id);
          if (product.active) {
            let subsProduct = subscriptionMap[product.id];

            cloud.isOpen = true;

            if (product.showEnvironment) {
              if (subsProduct?.productOfferings?.length) {
                subscribeEnvIds = subsProduct.productOfferings.reduce(
                  (envIds: any[], offering: any) => {
                    Array.prototype.push.apply(
                      envIds,
                      offering.environments.map(
                        (environment: any) => environment.id
                      )
                    );
                    return envIds;
                  },
                  []
                );
              } else {
                subscribeEnvIds = subsProduct.environments.map(
                  (env: any) => env.id
                );
                product.serviceEnvironments = product.serviceEnvironments?.map(
                  (environment: RegistryService) => {
                    environment.active = subscribeEnvIds.includes(
                      environment.id
                    );
                    return environment;
                  }
                );
              }
            } else {
              let subsRegions: any[];
              if (
                product.productOfferings?.length &&
                subsProduct.productOfferings?.length
              ) {
                subsRegions = subsProduct.productOfferings[0].regions.map(
                  (region: any) => region.id
                );
              } else {
                subsRegions = subsProduct.regions.map((region: any) =>
                  region.id
                );
              }

              product.regions = product.regions?.map((region: any) => {
                region.active = subsRegions.includes(region.id);
                return region;
              });
            }
            let subsEventTypes=subsProduct.eventType;
            product.eventTypes = product.eventTypes?.map((eventType: any) => {
              eventType.active = subsEventTypes.includes(eventType.id);
              return eventType;
            });

            if (
              product.productOfferings?.length &&
              subscriptionMap[product.id]?.offeringIds?.length
            ) {
              product.productOfferings = product.productOfferings.map(
                (offering: RegistryService) => {
                  offering.active = subscriptionMap[
                    product.id
                  ].offeringIds.includes(offering.id);
                  if (offering.active) {
                    product.isOpen = true;
                  }
                  if (product.showEnvironment) {
                    offering.serviceEnvironments =
                      offering.serviceEnvironments?.map(
                        (environment: RegistryService) => {
                          environment.active = subscribeEnvIds.includes(
                            environment.id
                          );
                          return environment;
                        }
                      );
                  }
                  if (isBizible(offering)) {
                    isBizibleEnvironmentSelected =
                      !!offering.serviceEnvironments?.some(
                        (environment: RegistryService) => environment.active
                      );
                  }
                  return offering;
                }
              );

              product.partial =
                product.productOfferings?.length !==
                subscriptionMap[product.id]?.offeringIds?.length;
            }
          }

          return product;
        }
      );

      return cloud;
    });
    if (callback && typeof callback === "function") {
      callback(isBizibleEnvironmentSelected);
    }

    return cloudList;
  }

  public mergeOfferingsIntoProduct(subscriptionObj: any) {
   
    let products: any = [],
      offerings: any = [],
      groupOfferings: any = [],
      selfSubscriptions: any = subscriptionObj.subscriptions;

    selfSubscriptions.forEach((subscription: any) => {
      if (
        subscription.product.type === SubscriptionType.Product &&
        this.serviceData.products[subscription.product.id]
      ) {
        products.push(subscription);
      } else if (subscription.product.type === SubscriptionType.Offering) {
        let offeringId: any, productId: any;
        offeringId = subscription.productOfferings[0].id;
        productId = this.getProductIdFromOfferingId(offeringId);
        if (productId && this.serviceData.offerings[offeringId]) {
          offerings.push(
            this.getTransformedSubscription(subscription, offeringId, productId)
          );
        }
      }
    });

    offerings.forEach((offering: any) => {
      if (groupOfferings.length === 0) {
        groupOfferings.push(offering);
      } else {
        let push: boolean = false;
        groupOfferings.forEach((group: any) => {
          if (group.product.id === offering.product.id) {
            group.productOfferings.push(offering.productOfferings[0]);
            push = true;
          }
        });
        if (!push) {
          groupOfferings.push(offering);
        }
      }
    });
    return this.allSubscribedProducts(products.concat(groupOfferings));
  }

  public allSubscribedProducts(subscriptionObj: any) {
    
    let subscriptions = subscriptionObj;
    let subscriptionNameObjs = subscriptions;

    subscriptions.forEach((productObj: any, index: number) => {
      subscriptionNameObjs[index].product.name =
        this.getProductNameForSubscription(productObj);
        
      subscriptionNameObjs[index].cloudName = this.getCloud(productObj);
      

      if (
        subscriptions[index].product.type === SubscriptionType.Product &&
        this.serviceData.products[productObj.product.id]
      ) {
        productObj.regions.forEach((region: any, regionIndex: number) => {
          subscriptionNameObjs[index].regions[regionIndex].name =
          subscriptionNameObjs[index].regions[regionIndex].id || "";
        });
        productObj.environments.forEach(
          (environment: any, envIndex: number) => {
            subscriptionNameObjs[index].environments[envIndex].name =
              this.serviceData.environments[environment.id]?.name || "";
          }
        );

        let offerings =
            this.serviceData.products[productObj.product.id].productOfferings,
          currentOfferings: any = [];

        if (offerings && offerings.length) {
          offerings.forEach((offeringId: any) => {
            if (this.serviceData.offerings[offeringId]) {
              currentOfferings.push({
                id: offeringId,
                name: this.serviceData.offerings[offeringId].name,
                regions: subscriptionNameObjs[index].regions,
                environments: subscriptionNameObjs[index].environments,
              });
            }
          });
          subscriptionNameObjs[index].productOfferings = currentOfferings;
        }
      } else {
        productObj.productOfferings.forEach(
          (offering: any, offIndex: number) => {
            subscriptionNameObjs[index].productOfferings[offIndex].name =
              this.serviceData.offerings[offering.id]?.name || "";
              
            productObj.productOfferings[offIndex].regions.forEach(
              (region: any, regionindex: any) => {
                subscriptionNameObjs[index].productOfferings[offIndex].regions[
                  regionindex
                ].name = subscriptionNameObjs[index].productOfferings[offIndex].regions[
                  regionindex
                ].id || "";
              }
            );
            productObj.productOfferings[offIndex].environments.forEach(
              (environment: any, envIndex: any) => {
                subscriptionNameObjs[index].productOfferings[
                  offIndex
                ].environments[envIndex].name =
                  this.serviceData.environments[environment.id]?.name || "";
              }
            );
          }
        );
      }
    });


    return subscriptionNameObjs;
  }

  public getProductNameofOffer(offeringId: any) {
    if (this.serviceData.offerings[offeringId]) {
      let products: any = this.serviceData.products;
      for (let productId in products) {
        if (
          products.hasOwnProperty(productId) &&
          products[productId].productOfferings &&
          products[productId].productOfferings.indexOf(offeringId) !== -1
        ) {
          return products[productId].name;
        }
      }
    }
    return null;
  }

public getOfferingNameofOffer(offeringId: any) {
    if (this.serviceData.offerings[offeringId]) {
      
          return this.serviceData.offerings[offeringId].name;
        
      
    }
    return null;
  }

  public getProductNameForSubscription(subscription: any) {
    let productId: any = subscription.product.id;

    if (productId && this.serviceData.products[productId]) {
      return this.serviceData.products[productId].name;
    } else if (
      subscription.productOfferings &&
      subscription.productOfferings.length
    ) {
      let offerId: any = subscription.productOfferings[0].id,
        productName = this.getProductNameofOffer(offerId);
      if (productName) {
        return productName;
      }
    }
    return "";
  }

  public getCloud(subscription: any) {
    let products: any = this.serviceData.products,
      serviceId = subscription.product.id;
    if (serviceId && this.serviceData.products[serviceId]) {
      return this.getCloudName(serviceId);
    } else if (subscription?.productOfferings?.length) {
      let offerId = subscription.productOfferings[0].id;
      for (let productId in products) {
        if (
          products.hasOwnProperty(productId) &&
          products[productId].productOfferings &&
          -1 !== products[productId].productOfferings.indexOf(offerId)
        ) {
          return this.getCloudName(productId);
        }
      }
    }
    return "";
  }
}
