
import { LanguageMap, PreferredLanguage, ServiceRegions } from "./proptype";

export const RESOLVEDSTATES = ["Closed", "Completed", "Dismissed"];
export const INCIDENTPRIORITIES = [
    "announcement",
    "Global",
    "Cloud",
    "Product",
    "major",
    "minor",
    "potential",
    "maintenance",
    "closed_announcement",
    "resolved",
    "resolved_major",
    "resolved_minor",
    "resolved_potential",
    "resolved_trivial",
    "trivial",
    "resolved_maintenance",
    "canceled_maintenance",
];

export const BANNERPRIORITIES = [
    "announcement",
    "major",
    "minor",
    "potential",
    "maintenance",
    "closed_announcement",
    "resolved",
    "resolved_major",
    "resolved_minor",
    "resolved_potential",
    "resolved_trivial",
    "trivial",
    "resolved_maintenance"
];

export const MEMOIZE_TIME = 3.5;

export const MARKETO_ID = '503491';
export const BIZIBLE_ID = '503549';

export const COOKIE_EXPIRY = 365;
export const REFRESH_TIME = 60000;
export const SLACK_RELOAD_TIME = 600000;

export const IMS_EVENT = 'IMS_READY';

export const EVENT_SOURCE = 'SNOW';

export const SUBMENU = [{
        name: "Overview",
        id: 0,
        path: "/",
        isLocale: true,
    },{
        name: "Creative Cloud",
        id: "503459",
        path: "/cloud/creative_cloud",
        orderid: 1,
    },{
        name: "Experience Cloud",
        id: "503461",
        path: "/cloud/experience_cloud",
        orderid: 2,
    },{
        name: "Document Cloud",
        id: "503457",
        path: "/cloud/document_cloud",
        orderid: 3,
    },{
        name: "Adobe Services",
        id: "503458",
        path: "/cloud/cloud_services",
        orderid: 4,
    },{
        name: "Adobe Experience Platform",
        id: "503460",
        path: "/cloud/experience_platform",
        orderid: 5,
    }];
    export const CLOUDIDS = {
        CREATIVE_CLOUD_ID: "503459",
        EXPERIENCE_CLOUD_ID: "503461",
        DOCUMENT_CLOUD_ID: "503457",
        ADOBE_SERVICES_ID: "503458",
        ADOBE_CLOUD_PLATFORM_ID: "503460",
    };

    export const REGIONS: any[] = [{
        id: ServiceRegions.Americas,
        name: ServiceRegions.Americas,
        active: false,
    }, {
        id: ServiceRegions.Apac,
        name: ServiceRegions.Apac,
        active: false,
    },{
        id: ServiceRegions.Emea,
        name: ServiceRegions.Emea,
        active: false,
}];


export const API_KEY = 'StatusSubscriptionSnowApiKey';
export const SLACK_API_KEY = 'StatusSubscriptionSnowApiKey';
export const OMEGA_ENV = 'prod';
export const IMS_ENV = 'prod';
export const APS_APP_ID = `AdobeStatus1`;

export const ImsWebConstants = {
    fedGlobalNav: 'https://www.adobe.com/etc.clientlibs/globalnav/clientlibs/base/feds.js',
    fedGlobalNavPolyfills: 'https://www.adobe.com/etc.clientlibs/globalnav/clientlibs/base/polyfills.js',
    imslibLocation: 'https://auth.services.adobe.com/imslib/imslib.min.js',
    omegaLocation: '//assets.adobedtm.com/d4d114c60e50/f1c1db5355c3/launch-d5e1cea3e343.min.js'
};

export const URLS = {
    SERVICEMAP_URL: 'https://data.status.adobe.com/adobestatus/SnowServiceRegistry',
    EVENT_URL: 'https://data.status.adobe.com/adobestatus/StatusEvents',
    SUBSCRIBE_URL: 'https://statussubscription-ws.adobe.io/subscription',
    PNS_URL: 'https://statussubscription-ws.adobe.io',
    CFS_URL: 'https://statussubscription-ws.adobe.io/cfs/',
    ORG_API_URL: 'https://graph.identity.adobe.com/',
    ADD_TO_SLACK_URL: `https://statussubscription-ws.adobe.io/bot/login?api_key=StatusSubscriptionSnowApiKey&slackAuthRedirect=true`,
    SLACK_URL: 'https://statussubscription-ws.adobe.io/slackSubscription',
    REVOKE_SLACK: `https://statussubscription-ws.adobe.io/slack/events?api_key=${API_KEY}&slackAuthRedirect=true`,
    APS_URL: `https://aps-web.adobe.io/webapps/access_profile/v3`
}

export const SHOW_HELP = 'true';

export const EVENTTYPES = [
    {
        id: "1",
        name: "event_type_major", // not actual value - locale key, ref., en.json
        active: false
    },
    {
        id: "2",
        name: "event_type_minor", // not actual value - locale key, ref., en.json
        active: false
    },
    {
        id: "4",
        name: "event_type_maintence", // not actual value - locale key, ref., en.json
        active: false
    },
    {
        id: "5",
        name: "event_type_messages", // not actual value - locale key, ref., en.json
        active: false,
    }
];

export const LOCALEMAP: LanguageMap ={
    "cs-cz":"cs",
    "da-dk":"da",
    "de-de":"de",
    "en":"en",
    "es-es":"es",
    "fi-fi":"fi",
    "fr-fr":"fr",
    "it-it":"it",
    "ja-jp":"ja",
    "ko-kr":"ko",
    "nb-no":"nb",
    "nl-nl":"nl",
    "pl-pl":"pl",
    "pt-br":"pt",
    "ru-ru":"ru",
    "sv-se":"sv",
    "tr-tr":"tr",
    "zh-cn":"zh-hans",
    "zh-tw":"zh-hant"
}

export const SUPPORTEDLANGUAGES = [
    "cs-cz",
    "da-dk",
    "de-de",
    "en-us",
    "es-es",
    "fi-fi",
    "fr-fr",
    "it-it",
    "ja-jp",
    "ko-kr",
    "nb-no",
    "nl-nl",
    "pl-pl",
    "pt-br",
    "ru-ru",
    "sv-se",
    "tr-tr",
    "zh-cn",
    "zh-tw"
];

export const PREFERREDLANGMAP: PreferredLanguage = {
    "ar-ae": "Arabic (United Arab Emirates)",
    "bg-bg": "Bulgarian (Bulgaria)",
    "zh-cn": "Chinese (Simplified)",
    "zh-tw": "Chinese (Traditional)",
    "hr-hr": "Croatian (Croatia)",
    "cs-cz": "Czech (Czech Republic)",
    "da-dk": "Danish (Denmark)",
    "nl-nl": "Dutch (Netherlands)",
    "en-il": "English (Israel)",
    "en-gb": "English (United Kingdom)",
    "en-us": "English (United States)",
    "et-ee": "Estonian (Estonia)",
    "fi-fi": "Finnish (Finland)",
    "fr-ca": "French (Canada)",
    "fr-fr": "French (France)",
    "de-de": "German (Germany)",
    "he-il": "Hebrew (Israel)",
    "hu-hu": "Hungarian (Hungary)",
    "it-it": "Italian (Italy)",
    "ja-jp": "Japanese (Japan)",
    "ko-kr": "Korean (South Korea)",
    "lv-lv": "Latvian (Latvia)",
    "lt-lt": "Lithuanian (Lithuania)",
    "nb-no": "Norwegian Bokmål (Norway)",
    "pl-pl": "Polish (Poland)",
    "pt-br": "Portuguese (Brazil)",
    "ro-ro": "Romanian (Romania)",
    "ru-ru": "Russian (Russia)",
    "sr-rs": "Serbian (Serbia)",
    "sk-sk": "Slovak (Slovakia)",
    "sl-si": "Slovenian (Slovenia)",
    "es-la": "Spanish (Latin America)",
    "es-mx": "Spanish (Mexico)",
    "es-es": "Spanish (Spain)",
    "sv-se": "Swedish (Sweden)",
    "tr-tr": "Turkish (Turkey)",
    "uk-ua": "Ukrainian (Ukraine)"
};