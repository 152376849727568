
import { API_KEY, APS_APP_ID, EVENT_SOURCE, PREFERREDLANGMAP, SUPPORTEDLANGUAGES, URLS } from '../constants';
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { APSResponse, AccessibleItem, EventType, Incident } from '../proptype';

declare global {
    interface Window {
        adobeIMS: any;
    }
}

export class SubscriptionService {
    private static instance: SubscriptionService;
    private locales: any;
    private subscription_url: any;
    private pns_url: any;
    private slack_url: any;
    private headers = {
        'Authorization': '', // value needs to be updated before attaching to axios
        'x-api-key': API_KEY,
        'source': EVENT_SOURCE,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    };


    public static init() {
        if (!SubscriptionService.instance) {
            SubscriptionService.instance = new SubscriptionService();
        }
        return SubscriptionService.instance;
    }
    private constructor() {
        this.subscription_url = URLS.SUBSCRIBE_URL;
        this.pns_url = URLS.PNS_URL;
        this.slack_url = URLS.SLACK_URL;
    }

    public getIMSEntitlementCode(profile?: any): string[] {
        if (!profile) {
            const imsProfile = sessionStorage.getItem('adobeid_ims_profile');
            if (!imsProfile) {
                window.adobeid.redirect_uri = window.location.href;
                window.adobeIMS.signIn();
            } else {
                profile = JSON.parse(imsProfile);
            }

        }

        let entitlements: string[] = [];
        if (profile?.projectedProductContext?.length) {
            profile.projectedProductContext.forEach((context: any) => {
                if (!entitlements.includes(context.prodCtx.serviceCode)) {
                    entitlements.push(context.prodCtx.serviceCode);
                }
            });
        }

        return entitlements;
    }

    public getEntitlementCodeFromAPS(): Promise<string[]> {
        if (!window.adobeIMS?.isSignedInUser()) {
            window.adobeid.redirect_uri = window.location.href;
            window.adobeIMS.signIn();
        }

        return new Promise((resolve, reject) => {
            const entitlements = this.getIMSEntitlementCode();
            if (entitlements.length) {
                resolve(entitlements);
                return;
            }
            axios.post(URLS.APS_URL, {
                "appDetails":
                {
                    "nglAppVersion": "1.0",
                    "nglLibRuntimeMode": "NAMED_USER_ONLINE",
                    "nglLibVersion": "12.3",
                    "locale": "en_US",
                    "nglAppLaunchState": "WORKFLOW_STATE",
                    "appNameForLocale": `${APS_APP_ID}`,
                    "nglAppId": `${APS_APP_ID}`,
                    "appVersionForLocale": "1.0"
                }
            }, {
                headers: {
                    'Authorization': `Bearer ${window.adobeIMS?.getAccessToken().token}`,
                    'X-Api-Key': 'adobedotcom_status'
                }
            }).then((res: AxiosResponse) => {
                const response = res.data?.asnp?.payload;
                if (response) {
                    const result: APSResponse = JSON.parse(atob(response));
                    if (result.appProfile?.accessibleItems) {
                        const fiCodes = Array.prototype.concat.apply([], result.appProfile?.accessibleItems.map((item: AccessibleItem) => Object.keys(item.fulfillable_items || {})));
                        resolve(fiCodes);
                        return;
                    }
                }
                resolve([]);
                return;

            }, (error: AxiosError) => {
                resolve([]);
            });
        })
    }

    public getSubscriptions(currentTab?: any, safe?: boolean) {
        let URL: string = (currentTab === "event") ? this.subscription_url : this.pns_url + "/selfSubscriptions";
        this.headers = {
            ...this.headers,
            'Authorization': 'Bearer ' + window.adobeIMS?.getAccessToken().token
        };
        if (safe) {
            URL += '?safe=true';
        }
        return axios.get(URL, { headers: this.headers });
    }

    public getSlackSubscription() {
        this.headers = {
            ...this.headers,
            'Authorization': 'Bearer ' + window.adobeIMS?.getAccessToken().token
        };

        return axios.get(this.slack_url, { headers: this.headers });
    }
    public removeSlackSubscription() {
        this.headers = {
            ...this.headers,
            'Authorization': 'Bearer ' + window.adobeIMS?.getAccessToken().token
        };

        return axios.delete(this.slack_url, { headers: this.headers });
    }
    public subscibeProducts(payload: any) {
        return axios({
            method: "post",
            headers: {
                ...this.headers,
                'Authorization': 'Bearer ' + window.adobeIMS.getAccessToken().token,
            },
            url: this.pns_url + "/selfSubscriptions",
            data: payload
        })
    }

    public unSubscribeProduct(productId: any, subscriptions: any, timeZone: any, slackNotificationEnabled: boolean) {

        let payload: any = {
            "timeZone": timeZone,
            "language": this.getUserDetail().userLanguage,
            "source": EVENT_SOURCE,
            "subscriptions": [],
            "slackNotificationEnabled": slackNotificationEnabled
        };
        subscriptions.forEach((subscription: any) => {
            if (subscription.product.id !== productId) {
                let Obj: any = {
                    "product": {
                        "id": subscription.product.id,
                        "name": subscription.product.name,
                        "type": subscription.product.type
                    },
                    "cloudName": subscription.cloudName,
                    "eventType": subscription.eventType,
                    "regions": subscription.regions,
                    "environments": subscription.environments
                }
                if (subscription.productOfferings && subscription.productOfferings.length) {
                    Obj.productOfferings = subscription.productOfferings;
                }
                payload.subscriptions.push(Obj);
            }
        })


        this.headers = {
            ...this.headers,
            'Authorization': 'Bearer ' + window.adobeIMS.getAccessToken().token,
        };

        if (payload.subscriptions.length) {
            return axios({
                method: "post",
                headers: this.headers,
                url: this.pns_url + "/selfSubscriptions",
                data: payload
            })
        } else {
            return this.deleteSelfSubscription()
        }
    }

    public setLocales(locales: any) {
        this.locales = locales;
    }
    public deleteSelfSubscription() {

        this.headers = {
            ...this.headers,
            'Authorization': 'Bearer ' + window.adobeIMS.getAccessToken().token,
        };

        return axios({
            method: "DELETE",
            headers: this.headers,
            url: this.pns_url + "/selfSubscriptions"
        })

    }
    public getUserDetail() {
        let that = this;
        const imsProfile = sessionStorage.getItem('adobeid_ims_profile');
        if (!imsProfile) {
            window.adobeid.redirect_uri = window.location.href;
            window.adobeIMS.signIn();
        }

        let profile = imsProfile && JSON.parse(imsProfile),
            userLanguage = "English (United States)", langIndex;
        if (profile?.preferred_languages?.length) {
            langIndex = SUPPORTEDLANGUAGES.indexOf(profile.preferred_languages[0]);
        }

        if (langIndex !== undefined && langIndex !== -1) {
            let preferredLanguage: string = profile.preferred_languages.length ? PREFERREDLANGMAP[profile.preferred_languages[0]] : 'en-us';
            userLanguage = that.locales.languages[langIndex] || PREFERREDLANGMAP[preferredLanguage];
        }

        return {
            userLanguage: userLanguage,
            email: profile.email
        }

    }

    public validateTimeZone(timeZone: string) {
        return axios({
            method: 'GET',
            headers: {
                ...this.headers,
                'Authorization': 'Bearer ' + window.adobeIMS?.getAccessToken().token,
            },
            url: this.pns_url + "/selfSubscriptions/validate?timeZone=" + timeZone
        });
    }

    public isCommunication(eventType: string): Boolean {
        if ([EventType.cloud.toString(), EventType.global.toString(), EventType.product.toString()].includes(eventType)) {
            return true;
        }
        return false;
    }

    public updateSubscription(type: Method, timeZone: string, incident?: Incident, slackChannel?: any) {

        if (incident) {
            let currentRegions: any = incident.regions || [],
                payload: any = {
                    eventLanguage: this.getUserDetail().userLanguage,
                    eventSubscription: {
                        eventId: incident.incidentId,
                        severity: this.isCommunication(incident.eventType || "") ? incident.eventType : incident.severity || 'Maintenance',
                        eventStartTime: incident.startedOn,
                        eventUpdateTime: incident.updatedTime || 0,
                        eventEndTime: incident.endedOn || 0,
                        status: incident.status || "",
                        cloudNames: [incident.cloudName],
                        regions: Object.values(currentRegions).map((region: any) => {
                            return region.name;
                        }),
                        product: incident.productsAssociated.filter((service: any) => incident.productId === service.id)
                    },
                    eventSource: EVENT_SOURCE,
                    timeZone: timeZone
                };

            // send slack channel name in paylaod
            if (slackChannel) {
                payload.slack = { "slackChannel": slackChannel }
            }


            return axios({
                method: type,
                headers: {
                    ...this.headers,
                    'Authorization': 'Bearer ' + window.adobeIMS?.getAccessToken().token,
                },
                url: this.subscription_url,
                data: JSON.stringify(payload)
            });
        } else {
            return Promise.reject();
        }
    }

    public getImsEntitlementProducts(entitlements: any, profile?: any) {
        let imsServiceCode = this.getIMSEntitlementCode(profile);
        const imsEntitlementProducts: any = [];
        imsServiceCode.forEach((serviceCode: any) => {
            if (entitlements.get(serviceCode)) {
                imsEntitlementProducts.push(entitlements.get(serviceCode));
            }
        });
        return imsEntitlementProducts;
    }


}
