import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { ServiceRegistry } from '../../models/service-registry';
import { FilterStorage } from '../../models/filterstorage';
import { EventService } from '../../models/event-service';
import { CLOUDIDS, OMEGA_ENV, SUBMENU } from '../../constants';
import moment from "moment-timezone";
import EventFilter from '../eventfilter/eventfilter';
import Filter from '../filter/filter';
import { createBrowserHistory } from 'history';
import { clearPageState, isHistory, isToday, toggle_visibility, HideFooter } from '../../utils';
import Spinner from '../spinner/spinner';
import { ConfigContext } from "../../App";
import ProductTable from '../producttable/producttable';
import BreadCrumbs from '../breadcrumbs/breadcrumbs';
import { CasCadingFilterProp, EventType, FilterKey, Incident, IncidentGroup } from '../../proptype';
import Banner from '../banner/banner';
import OMEGA from '../../omega';
import GenericError from '../genericerror/genericerror';
import EventTile from '../eventtile/eventtile';
import { SubscriptionService } from '../../models/subscription-service';
import { AxiosPromise } from 'axios';

export default function Cloud() {
    const eventDefaultValue = {
        element: "Events",
        action: "click",
        type: "List items"
    }
    let defaultHistory: any = { historyDates: [], productHistoryData: [] };
    const locales = useContext(ConfigContext).appConfig.languageData;
    const updateMenu = useContext(ConfigContext).updateMenu;

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [productList, setProductList] = useState([]);
    const [historyProductList, setHistoryProductList] = useState(defaultHistory);
    const [isHistoryData, setIsHistoryData] = useState(false);
    const [eventFilter, setEventFilter] = useState('all');
    const [filterClicked, setFilterClicked] = useState(false);
    const [mounted, setMounted] = useState(true);
    const [filterCount, setFilterCount] = useState(0);
    const [hasUpdate, setHasUpdate] = useState(false);
    const [events, setEvents] = useState<IncidentGroup[]>([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [loadingSubscription, setLoadingSubscription] = useState(false);
    const [openEvent, setOpenEvent] = useState(false);
    const histroyAPI = useHistory();

    const CLOUDS: any = {
        'creative_cloud': CLOUDIDS.CREATIVE_CLOUD_ID,
        'experience_cloud': CLOUDIDS.EXPERIENCE_CLOUD_ID,
        'document_cloud': CLOUDIDS.DOCUMENT_CLOUD_ID,
        'cloud_services': CLOUDIDS.ADOBE_SERVICES_ID,
        'experience_platform': CLOUDIDS.ADOBE_CLOUD_PLATFORM_ID,
    };

    const {eventId, cloudName }: any = useParams();

    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
    const eventService: EventService = EventService.init();
    eventService.setLocales(locales);
    localStorage.removeItem('filterObj');
    const filterObj = FilterStorage.init(CLOUDS[cloudName]);
    const subscriptionService: SubscriptionService = SubscriptionService.init();

    useEffect(() => {
        if (mounted) {
            filterObj.setContext({
                type: 'cloud',
                cloud: CLOUDS[cloudName]
            });
            updateCurrentMenu();
            OMEGA.init().updateAndTrack('page', {
                hierarchy: "home:cloud",
                name: getCloudName(),
                url: encodeURIComponent(window.location.href),
                type: "home",
                env: OMEGA_ENV
            });
            loadData();
        }

    }, [cloudName]);

    useEffect(() => {
        if (hasUpdate) {
            onFilterChange();
            setHasUpdate(false);
        }
    }, [hasUpdate]);

    useEffect(() => {
       
        if (openEvent && !loading && typeof eventId === 'string') {

            const _element = document.querySelector(`.id-${eventId}`);
            if (_element) {
                _element.classList.add('active');
                setTimeout(() => {
                    
                    _element.scrollIntoView({
                        behavior: 'smooth'
                    });
                }, 200)
                setOpenEvent(false);
               
            }

        }
    }, [openEvent, loading]);

    useEffect(() => {
        updateCurrentMenu();
        OMEGA.init().updateAndTrack('page', {
            hierarchy: "home:cloud",
            name: getCloudName(),
            url: encodeURIComponent(window.location.href),
            type: "home",
            env: OMEGA_ENV
        });
        eventService.registerForUpdate(() => {
            setHasUpdate(true);
        })
        

        const [servicePromise, productPromise]: any = [serviceRegistry.fetch(), eventService.fetch()];
        filterObj.setContext({
            type: 'cloud',
            cloud: CLOUDS[cloudName]
        });
        window.scrollTo(0, 0);
        Promise.all([servicePromise, productPromise])
            .then(() => {
                if (mounted) {
                    updateCurrentMenu();
                    loadData();
                    setLoading(false);
                }
            })
            .catch((error: any) => {
                setLoading(false);
                setError(true);
            });

         

        return () => {
            setMounted(false);
        }

    }, []);

    function loadData() {

        if (window.adobeIMS?.isSignedInUser()) {
                         let state: any = histroyAPI.location.state;
                        let safe: boolean = !!state?.page;
                        clearPageState(createBrowserHistory());
                        setLoadingSubscription(true);
                        const subscriptionPromise: AxiosPromise = subscriptionService.getSubscriptions("event", safe);
                        subscriptionPromise
                            .then((result: any) => result.data)
                            .then((result: any) => {
                                let currentSubs: any = result;
                                setSubscriptions(currentSubs);
                                setLoadingSubscription(false);
                            }).catch((error: any) => {
                                setLoadingSubscription(false);
                                // setSubscriptionError(true);
                            })
                    }
        if (CLOUDS[cloudName]) {
            let filter = filterObj.getValue(),
                date = moment().format('YYYY-MM-DD'),
                history: boolean = false;

            if (filter?.date && filter.date.length === 2) {
                history = isHistory([moment(filter?.date[0]), moment(filter?.date[1])]);
            } else {
                filterObj.setValue('date', [date, date])
            }
            setIsHistoryData(history);
            if (history) {
                setHistoryProductList(eventService.filterProductsByDate(serviceRegistry, filter, CLOUDS[cloudName]))
            } else {
                setProductList(eventService.filterProducts(serviceRegistry, filterObj.getValue(), CLOUDS[cloudName], undefined));
            }
            setFilterCount(getFilterCount());
             if (eventId) {
                            
            let incident = eventService.getIncident(eventId);
            if (incident) {  
                setOpenEvent(true);
            }
            
        }
        }

        const onGoingEvents: IncidentGroup[] = eventService.getOnGoingEventsByType(CLOUDS[cloudName],undefined, undefined, undefined, undefined,  undefined);
        setEvents(onGoingEvents);

       
        
    }
    function updateCurrentMenu() {
        let currentMenu = getCloudName();
        if (!currentMenu?.length) {
            histroyAPI.push('/');
            return;
        }
        updateMenu(currentMenu);
    }
    function getFilterCount(_eventFilter?: string) {

        filterObj.setContext({
            type: 'cloud',
            cloud: CLOUDS[cloudName]
        });

        let filter: CasCadingFilterProp | undefined = filterObj.getValue(), count = 0;
        if (filter) {
            Object.keys(filter).forEach((key: string) => {
                if (filter && filter[key as keyof CasCadingFilterProp]?.length) {
                    count++;
                }

                if (key === FilterKey.Date && filter?.date?.length) {
                    if (isToday(moment(filter.date[0])) && isToday(moment(filter.date[1]))) {
                        count--;
                    }
                }
            });
        }
        if (_eventFilter) {
            count += _eventFilter !== 'all' ? 1 : 0;
        } else {
            count += eventFilter !== 'all' ? 1 : 0;
        }
        return count;
    }

    function onFilterChange(_eventType?: any) {
        let filter = filterObj.getValue(),
            _mStart = filter?.date && filter?.date[0] ? moment(filter?.date[0]) : moment(),
            _mEnd = filter?.date && filter?.date[1] ? moment(filter?.date[1]) : moment();
        if (isHistory([_mStart, _mEnd])) {
            setHistoryProductList(eventService.filterProductsByDate(serviceRegistry, filterObj.getValue(), CLOUDS[cloudName]));
            setIsHistoryData(true);
        } else {
            setIsHistoryData(false);
            setProductList(eventService.filterProducts(serviceRegistry, filterObj.getValue(), CLOUDS[cloudName], undefined));
        }
        setFilterCount(getFilterCount(_eventType));
    }
    function getCloudName() {
        let menuFiltered = SUBMENU.filter((menu: any) => menu.id === CLOUDS[cloudName]);
        if (menuFiltered.length) {
            return menuFiltered[0].name
        }
        return '';
    }

    function isSubscribed(incident: Incident) {
         if (subscriptions && subscriptions.length > 0) {
           if (isCommunication(incident.eventType)){
            return (subscriptions.length > 0 && subscriptions.filter((subscription: any) => subscription.eventId === incident.incidentId && ( subscription.products.length > 0? subscription.products.includes(incident.productId) : (subscription.clouds.length > 0 && subscription.clouds.includes(incident.cloudName) ))).length > 0);
           }
           else if (incident.eventType !== 'maintenance') {
             return (subscriptions.length > 0 && subscriptions.filter((subscription: any) => subscription.eventId === incident.incidentId && subscription.products.includes(incident.productId)).length > 0);
           } else {
             return (subscriptions.length > 0 && subscriptions.filter((subscription: any) => subscription.eventId === incident.incidentId).length > 0);
           }
         }
       }

    function renderCommunications(_eventType?: any, productList?: any){
        if (_eventType === "announcement" || _eventType === "all"){
        return events.map((event_type: any, index1: any) => {
            return (
              <div key={index1}>
                {event_type.list.map((item: any, index: any) => {
                    {
                        if (isCommunication(item.eventType) && filterProduct(item, productList) ){
                        return (
                            <div key={index}>
                              {
                                <EventTile event={item} key={index} isHistory={true} isSubscribed={isSubscribed(item)} subscriptionLoading={loadingSubscription}
                                />
                              }
                            </div>
                          );
                    }}
                  
                })}
              </div>
            );
          });
        }
    }

    function isCommunication(eventType: string): Boolean {
        if ([EventType.cloud.toString(), EventType.global.toString(), EventType.product.toString()].includes(eventType)){
            return true;
        }        
        return false;
      }

    function filterProduct(item:any, productList?: any): Boolean {

        if (item.eventType == EventType.product.toString() ){
            for (const product_details of productList) {
                if (product_details.id == item.productId){
                    return true;
                }
            }     
            return false;
        }
       return true;
      }
      
    function renderProductTable() {
        if (error) {
            return (
                <GenericError />
            );
        } else {
            return (
                loading ? <>{HideFooter(true)}<Spinner /></> :
                    <>{HideFooter(false)}
                    <div className="row product-main">
                    
                        <div className="col-md-3 side-panel">
                            <div id="side-panel">
                                <Filter product={false} type='cloud' cloud={CLOUDS[cloudName]} cloudName={cloudName} onFilter={onFilterChange} clearEventFilter={setEventFilter} />
                            </div>
                        </div>
                        <div className="col-md-9 cloud-table">
                        <div className="product-tab-content product-list-container">{renderCommunications(eventFilter, productList)}</div>
                            <ProductTable
                                eventFilter={eventFilter}
                                isHistory={isHistoryData}
                                productList={isHistoryData ? historyProductList : productList}
                                page={"products"}
                            />
                        </div>
                    </div></>
            )
        }
    }

    return (
        <div className="cloud-container">
            <Banner isParentLoading={loading} id={CLOUDS[cloudName]} showOnlyScheduledCanceledMaintenace={true} />
            <div className="container cloud-container">
                <BreadCrumbs cloudName={getCloudName()} isLoading={loading} />
                <h1 className="cloud-name title">{getCloudName()}</h1>
                <div className="product-title">
                    <span >{locales.products}</span>
                </div>
                <div id="filter-button">
                    <button className="btn" onClick={() => { setFilterClicked(!filterClicked); toggle_visibility("side-panel", "event-filter") }}>
                        <i className={filterClicked || filterCount ? "event-icon filter active" : "event-icon filter"}></i> {filterCount ? <span>({filterCount})</span> : ''}
                    </button>
                </div>
                <div className="product-tab-content">
                    <div id="event-filter">
                        <EventFilter active={eventFilter}
                            onEventFilter={(eventValue: string) => {

                                OMEGA.init().updateAndTrack("event", [{
                                    ...eventDefaultValue,
                                    feature: cloudName + "-" + "cloud page" + " filters",
                                    widget: {
                                        name: "event_type_filter",
                                        type: "panel"
                                    },
                                    search: {
                                        term: eventValue
                                    }
                                }]);

                                setFilterCount(getFilterCount(eventValue));
                                setEventFilter(eventValue);
                            }}
                        />
                    </div>
                    {renderProductTable()}
                </div>

            </div>
        </div>

    )
}
