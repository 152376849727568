import axios, { AxiosResponse } from "axios";
import { URLS } from "./constants";

declare global {
    interface Window {
        _satellite: any;
        digitalData: any;
    }
}

export default class OMEGA {
    private api_url: string;
    static instance: OMEGA;
    private constructor() {
        this.api_url = URLS.ORG_API_URL;
    }
    static init() {
        if (!OMEGA.instance) {
            OMEGA.instance = new OMEGA();
        }
        return OMEGA.instance;
    }
    updatePageTitle(title: string) {
        document.title = title;
    }
    isReady() {
        return window._satellite;
    }
    updateAndTrack(key: string, value: any) {
        
        let newDigitalData: any = window.digitalData || {},
            profile = sessionStorage.getItem('adobeid_ims_profile'),
            userProfile = profile && JSON.parse(profile);

        if (window.adobeIMS && window.adobeIMS?.isSignedInUser()) {
            this.getCorpName(userProfile, (corpName: string) => {
                newDigitalData = {
                    ...newDigitalData,
                    ...this.updatUserProperties(corpName),
                    [key]: value
                }
                window.digitalData = newDigitalData;
                this.track(key);
            });
        } else {
            newDigitalData = {
                ...newDigitalData,
                [key]: value
            }
            window.digitalData = newDigitalData;
            this.track(key);
        }
    }
    updatUserProperties(corpName: string) {
        let lang, profile = sessionStorage.getItem('adobeid_ims_profile'),
            userProfile = profile && JSON.parse(profile), newDigitalData = {};
        corpName = corpName === "anonymous" ? '' : corpName;
        if (userProfile) {
            lang =
                userProfile?.preferred_languages?.length
                    ? userProfile.preferred_languages[0].replace("-", ":")
                    : "en:us";

            newDigitalData = {
                ...window.digitalData,
                user: {
                    id: userProfile.userId,
                    corpId: this.getCorpID(userProfile) || "anonymous",
                    corpName: corpName || userProfile.company || "anonymous",
                    authSystem: "ims",
                    accountType: "user",
                    language: lang,
                    auth: "authenticated",
                }
            };
        }

        return newDigitalData;
    }

    getCorpName(userProfile: any, callback: Function) {
        let corpID = this.getCorpID(userProfile) || "anonymous",
            orgName = sessionStorage.getItem(corpID);

        if (corpID === 'anonymous') {
            callback('anonymous');
            return 'anonymous';
        }
        
        if (orgName) {
            callback(orgName);
            return orgName;
        }
        return axios({
            url: this.api_url + corpID,
            headers: {
                'Authorization': 'Bearer ' + window.adobeIMS.getAccessToken().token
            },
            method: 'GET'
        })
            .then((res: AxiosResponse) => res.data)
            .then(function (OrgInfo: any) {
                if (OrgInfo && OrgInfo.orgName) {
                    sessionStorage.setItem(corpID, OrgInfo.orgName);
                    callback(OrgInfo.orgName);
                } else {
                    callback('anonymous');
                }
            }).catch(function () {
                callback('anonymous');
            });
    }

    getCorpID(userProfile: any) {
        if (userProfile && userProfile.ownerOrg) {
            return userProfile.ownerOrg
        }

        if (userProfile && userProfile.projectedProductContext && userProfile.projectedProductContext.length) {
            return userProfile.projectedProductContext[0].prodCtx.owningEntity
        }
        return null;
    }
    track(key: string) {
        if (window._satellite) {
            window._satellite.track(key);
        }
    }
}
