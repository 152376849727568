import React, { useEffect, useState, useContext } from 'react';
import { ConfigContext } from "../../App";
import { useHistory, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { SubscriptionService } from '../../models/subscription-service';
import { ServiceRegistry } from '../../models/service-registry';
import Spinner from '../spinner/spinner';
import ProductTile from '../producttile/producttile'
import PNSHeader from '../pnsheader/pnsheader';
import { IMS_EVENT, OMEGA_ENV } from '../../constants';
import OMEGA from '../../omega';
import GenericError from '../genericerror/genericerror';
import { HideFooter } from '../../utils';

export default function UnSubscribeProduct(props: any) {

    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [selfSubscriptions, setSelfSubscriptions] = useState([]);
    const [slackSubscriptions, setSlackSubscriptions] = useState<any>({});
    const [slackNotificationEnabled, setSlackNotificationEnabled] = useState(false);
    const [userLanguage, setUserLanguage] = useState('');
    const [email, setEmail] = useState('');
    const [langInfo, setLangInfo] = useState(false);
    const [timeZoneInfo, setTimeZoneInfo] = useState(false);
    const [timeZone, setTimeZone] = useState();
    const [timezoneString, setTimeZoneString] = useState();
    const [mounted, setMounted] = useState(true);
    const locales = useContext(ConfigContext).appConfig.languageData;

    const historyAPI = useHistory();
    const { productId }: any = useParams();

    const subscriptionService: SubscriptionService = SubscriptionService.init();
    subscriptionService.setLocales(locales);
    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
    subscriptionService.setLocales(locales);

    useEffect(() => {

        setPageLoading(true);
        if (window.adobeIMS) {
            getData();
        } else {
            document.addEventListener(IMS_EVENT, getData);
        }

        return () => {
            setMounted(false);
            document.removeEventListener(IMS_EVENT, getData);
        }
    }, []);

    function getData() {
        if (!window.adobeIMS?.isSignedInUser()) {
            window.adobeid.redirect_uri = window.location.href;
            window.adobeIMS.signIn();
            return;
        }

        setUserLanguage(subscriptionService.getUserDetail().userLanguage);
        setEmail(subscriptionService.getUserDetail().email);
        const [
            servicePromise,
            subscriptionPromise,
            slackSubscriptionPromise
        ]: any = [
                serviceRegistry.fetch(),
                subscriptionService.getSubscriptions("product"),
                subscriptionService.getSlackSubscription()
            ];
        window.scrollTo(0, 0);

        Promise.all([subscriptionPromise, slackSubscriptionPromise, servicePromise])
            .then((result: any) => [result[0].data, result[1].data, result[2].data])
            .then((result: any) => {
                if (mounted) {
                    let [subscriptionData, slackSubscriptionData]: any = result;
                    if (slackSubscriptionData?.channel && slackSubscriptionData?.active) {
                        setSlackSubscriptions(slackSubscriptionData);
                    }
                    window.scrollTo(0, 0);

                    setTimeZone(subscriptionData.timeZone);
                    setSlackNotificationEnabled(subscriptionData.slackNotificationEnabled);
                    let timeZoneString: any = `${subscriptionData.timeZone} (${subscriptionData.timezoneOffset})`;
                    setTimeZoneString(timeZoneString);
                    let selfSubs = serviceRegistry.mergeOfferingsIntoProduct(subscriptionData);
                    setSelfSubscriptions(selfSubs || []);
                    setPageLoading(false);
                }
            })
            .catch((error: any) => {
                setError(true);
                setPageLoading(false);
            });
    }

    function unSubscribeProduct() {
        setLoading(true);
        subscriptionService.unSubscribeProduct(productId, selfSubscriptions, timeZone, slackNotificationEnabled)
            .then(() => {
                OMEGA.init().updateAndTrack('page', {
                    hierarchy: "home:manage-subscription:unsubscribe-product",
                    name: "PNS - Unsubscribe Product- Adobe status",
                    url: encodeURIComponent(window.location.href),
                    type: "pns",
                    env: OMEGA_ENV
                });
                setLoading(false);
                redirect();
            }).catch(() => {
                setLoading(false);
                setApiError(true);
            });
    }

    function redirect() {
        historyAPI.push({
            pathname: `/proactive-notifications/manage`,
            state: {
                tab: "product",
                page: 'pns'
            }
        })
    }

    return (
        <div className="unsubscribe-product-container">
            <PNSHeader></PNSHeader>
            {
                pageLoading ?
                    <>{HideFooter(true)}<Spinner /></> :<>
                    {HideFooter(false)}
                    <div className="container">
                        {
                            error ? <GenericError /> :
                                <div className="unsubscribe-product">
                                    <div className="title">{locales.unsubscribe_updates}</div>
                                    <div>{locales.un_future_products} {parse(locales.learnMoreLink)}</div>
                                    <div className="preference">
                                        <div>{locales.sb_email}: <b>{email}</b></div>
                                        {
                                            slackSubscriptions.channel ? <div>{locales.slack}: <b>{slackSubscriptions.channel}</b></div> : <></>
                                        }
                                        <div>{locales.sb_lang}: <b>{userLanguage}</b><i className="info-icon" onClick={() => { setLangInfo(!langInfo); }} /></div>
                                        {langInfo ? <div className="lang-info">{parse(locales.lang_info)}</div> : ""}
                                        <div>{locales.timezone}: <b>{timezoneString}</b><i className="info-icon" onClick={() => { setTimeZoneInfo(!timeZoneInfo); }} /></div>
                                        {timeZoneInfo ? <div className="lang-info">{parse(locales.timezone_info)}</div> : ""}
                                    </div>
                                    <div>
                                        <ProductTile subscription={selfSubscriptions.filter((sub: any) => sub.product.id === productId)[0]} />
                                    </div>
                                    <div className="clearfix">
                                        <div className="unsubscribe-btn">
                                            <button onClick={() => { redirect() }} className="btn">{locales.cancel_txt}</button>
                                            <button onClick={() => { unSubscribeProduct() }} disabled={loading} className={`btn primary ${loading ? 'loading' : ''}`}>{locales.sb_done}{loading ? <Spinner isButton={true} /> : ''}</button>
                                        </div>
                                    </div>
                                    {apiError ? <div className="error-info" onClick={() => { historyAPI.push('/') }}>{parse(locales.api_error)}</div> : ""}
                                </div>
                        }
                    </div></>
            }
        </div>
    );
}