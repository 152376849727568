import { ConfigContext } from "../../App";
import React,{ useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubscriptionService } from "../../models/subscription-service";
import parse from 'html-react-parser';
import PNSHeader from "../pnsheader/pnsheader";
import Spinner from "../spinner/spinner";
import { OMEGA_ENV } from "../../constants";
import OMEGA from "../../omega";

export default function PnsUnsubscribeAll() {

    const locales = useContext(ConfigContext).appConfig.languageData;
    const historyAPI = useHistory();
    const subscriptionService: SubscriptionService = SubscriptionService.init();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    function unSubscribeAll() {
        setLoading(true);
        subscriptionService.deleteSelfSubscription()
            .then(() => {
                OMEGA.init().updateAndTrack('page', {
                    hierarchy: "home:manage-subscription:unsubscribe-all",
                    name: "PNS - Unsubscribe All- Adobe status",
                    url: encodeURIComponent(window.location.href),
                    type: "pns",
                    env: OMEGA_ENV
                });
                setLoading(false);
                redirect();
            }).catch(() => {
                setLoading(false);
                setError(true);
            });
    }

    function redirect() {
        historyAPI.push({
            pathname: `/proactive-notifications/manage`,
            state: {
                tab: "product",
                page: "pns"
            }
        })
    }


    return (
        <div className="unsubscription-container">
            <PNSHeader></PNSHeader>
            <div className="container">
                <div className="unsubscribe-all">
                    <div className="title">{locales.unsubscribe_all}</div>
                    <p>{locales.unsubscribe_all_message}</p>
                    <div className="buttons">
                        <button onClick={unSubscribeAll} className={`btn primary ${loading ? 'loading' : ''}`} disabled={loading}>{locales.sb_done}{loading ? <Spinner isButton={true} /> : ''}</button>
                        <button onClick={() => { redirect() }} className="btn">{locales.cancel_txt}</button>
                    </div>
                    {error ? <div className="error-info" onClick={() => { historyAPI.push('/') }}>{parse(locales.api_error)}</div> : ""}
                </div>
            </div>
        </div>
    );
}
