import React, { useContext } from 'react';
import { SUBMENU } from '../../constants';
import { ConfigContext } from "../../App";
import { useHistory } from "react-router-dom";


function Header() {


	const contextMenu = useContext(ConfigContext).appConfig.menu;
	const locales = useContext(ConfigContext).appConfig.languageData;
	const histroyAPI = useHistory();

	const webTitle = 'Adobe Status';
	return (
		<div id="AdobeSecondaryNav" className="Subnav-wrapper">
			<nav className="Subnav">
				<button className="Subnav-trigger" aria-expanded="false" aria-haspopup="true" aria-label="Adobe Status"></button>
				<a
					className="Subnav-logo tab-link noaction"
					href="#/"
					onClick={() => {
						histroyAPI.push('/')
					}}
				>
					<span className="Subnav-logo-text mobile-title">{contextMenu === 'Overview' ? locales.overview : contextMenu}</span>
					<span className="Subnav-logo-text desktop-title">{webTitle}</span>
				</a>
				<div className="Subnav-menu-wrapper">
					<ul className="Subnav-menu tabs" data-gnav-scrollable="mobile-layout" >
						{SUBMENU.map((menu: any) => {
							return (
								<li key={menu.id} className="Subnav-menu-item">
									<a
										href="#/"
										onClick={() => {
											let body = document.querySelector('body');
											histroyAPI.push(menu.path);
											if (body?.click) {
												body.click();
											}

										}}
										className={menu.name === contextMenu ? `active Subnav-menu-label tab-link ${menu.name.toLowerCase()}` : `Subnav-menu-label tab-link ${menu.name.toLowerCase()}`}
									>
										{menu.isLocale ? locales[menu.name.toLowerCase()] : menu.name}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</nav>
		</div>
	);
}

export default Header;
