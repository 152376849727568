import { ConfigContext } from "../../App";
import React, { useContext } from 'react';
import { SUBMENU } from '../../constants';
import { useHistory } from "react-router-dom";
import ContentLoader from 'react-content-loader';

export default function BreadCrumbs(props: any) {
    const histroyAPI = useHistory();
    const locales = useContext(ConfigContext).appConfig.languageData;

    const MyLoader = (loaderProps: any) => (
        <div className="breadcrumbs-loader">
        <ContentLoader
            speed={2}
            width={100}
            height={10}
            backgroundColor="#f3f3f3"
            foregroundColor="#dcdcdc"
            {...loaderProps}
        >
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="10" />
        </ContentLoader>
        </div>
      );


    function redirectCloud(cloudName: any){
        var currentMenu = SUBMENU.filter((menu: any) => menu.name === cloudName);
        histroyAPI.push(currentMenu[0].path);
    }


    function clearAll() {
        props.currentTab("services");
        props.clear(true);
    }

    return (
        <ul className="breadcrumbs"> 
            <li className="breadcrumbs-item">
                <a href= "#/" onClick={()=>{histroyAPI.push("/")}}>{locales.overview}</a>
            </li>
            {
                (props.page === 'product') ?
                    (props.offerings !=="")?<>
                    <>
                    <>
                        <li className="breadcrumbs-item">
                            {
                                props.isLoading ?
                                    <MyLoader /> :
                                    <a href="#/"
                                        onClick={() => { redirectCloud(props.cloudName) }}
                                    >{props.cloudName}</a>
                            }
                        </li>
                        <li className="breadcrumbs-item">
                            {
                                props.isLoading || !props.productName ?
                                    <MyLoader /> :
                                    <a href="#/"
                                        onClick={() => { clearAll() }}>
                                    {props.productName}</a>
                            }
                        </li>
                        <li className="breadcrumbs-item">
                            {
                                props.isLoading || !props.offerings ?
                                    <MyLoader /> :
                                    <span className="active">{props.offerings}</span>
                            }
                        </li>
                    </>

                    </></>:
                    <>
                        <li className="breadcrumbs-item">
                            {
                                props.isLoading ?
                                    <MyLoader /> :
                                    <a href="#/"
                                        onClick={() => { redirectCloud(props.cloudName) }}
                                    >{props.cloudName}</a>
                            }
                        </li>
                        <li className="breadcrumbs-item">
                            {
                                props.isLoading || !props.productName ?
                                    <MyLoader /> :
                                    <span className="active">{props.productName}</span>
                            }
                        </li>
                    </>
                    
                    :<li className="breadcrumbs-item">
                        {
                            props.isLoading ?
                                <MyLoader /> :
                                <span className="active">{props.cloudName}</span>
                        }
                    </li>
            }
            
                
        </ul>
        
    );
}
