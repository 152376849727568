import { IMS_ENV } from "../constants";
import { IMSData } from "./ims";

export class ImsData {
  imslibData: IMSData = {
    token: null,
    tokenValid: false,
    isSignedInUser: false,
    reauthToken: null,
    tokenHasExpired: false,
    ready: false,
    error: null,
    profile: null,
    appState: null,
  };

  onStateChanged: Function | null = null;

  triggerOnStateChanged(newState: IMSData) {
    this.imslibData = { ...newState };
    if (this.onStateChanged) {
      this.onStateChanged(newState);
    }
  }

  constructor(onStateChanged: Function | null, adobeid: any = null) {
    this.onStateChanged = onStateChanged;
    if (adobeid) {
      this.adobeIdData = {
        ...this.adobeIdData,
        ...adobeid,
      };
    }
  }

  adobeIdData = {
    client_id: "adobedotcom_status",
    scope: "creative_cloud,AdobeID,openid,gnav,read_organizations,additional_info.projectedProductContext,additional_info.roles,additional_info.company,additional_info.ownerOrg,org.read",
    locale: "en_US",
    environment: IMS_ENV,
    onAccessToken: (token: string) => {
      const imslibData = {
        ...this.imslibData,
        token,
        isSignedInUser: true,
      };
      this.triggerOnStateChanged(imslibData);
    },
    onAccessTokenHasExpired: () => {
      const imslibData = {
        ...this.imslibData,
        tokenHasExpired: true,
      };
      this.triggerOnStateChanged(imslibData);
    },
    onReauthAccessToken: (reauthToken: string) => {
      const imslibData = {
        ...this.imslibData,
        reauthToken,
        isSignedInUser: true,
      };
      this.triggerOnStateChanged(imslibData);
    },
    onError: (errortype: any, error: any) => {
      const imslibData = {
        ...this.imslibData,
        error: {
          errortype,
          error,
        },
        isSignedInUser: true,
      };
      this.triggerOnStateChanged(imslibData);
    },
    onReady: (context: any) => {
      const imslibData = {
        ...this.imslibData,
        ready: true,
        appState: context,
      };
      this.triggerOnStateChanged(imslibData);
    },
    onProfile: (profile: any) => {
      const imslibData = {
        ...this.imslibData,
        profile,
      };
      this.triggerOnStateChanged(imslibData);
    },
  };

  onTokenValid = (valid: boolean) => {
    const imslibData = {
      ...this.imslibData,
      tokenValid: valid,
      isSignedInUser: true,
    };
    this.triggerOnStateChanged(imslibData);
  };
}
