import React, { useContext } from 'react';
import { ConfigContext } from "../../App";
import parse from 'html-react-parser';


function GenericError() {
    const locales = useContext(ConfigContext).appConfig.languageData;

    return (
        <div className="generic-error-container">
            <i className="event-icon major"></i>
            <div className="error-title">{locales.generic_error_title}</div>
            <div className="error-content">
                {parse(locales.generic_error_content)}
            </div>
        </div>
    );
}

export default GenericError;