import moment, { Moment } from "moment-timezone";
import { BIZIBLE_ID, LOCALEMAP,INCIDENTPRIORITIES } from "./constants";
import { EventType, Incident, IncidentGroup, Status } from "./proptype";


//const currentLocale = window.locale === "zh-Hant" ? "zh-tw" : window.locale === "zh-Hans" ? "zh-cn" : window.locale;
//const currentLocale = window.locale === "zh-Hant" ? "zh-tw" : window.locale === "zh-Hans" ? "zh-cn" : window.locale;
const currentLocale = LOCALEMAP[window.locale];

if (currentLocale && currentLocale !== 'en') {
    import(`moment/locale/${currentLocale}`);
}

export function parseDateToString(date: Moment, locales: any, timeZone: boolean = true) {
    if (currentLocale && currentLocale !== 'en') {
        moment.locale(currentLocale);
    }
    let dateString = '',
        now = moment();

    if (date.isUTC()) {
        date = date.local();
    }

    date = moment(date).tz(moment.tz.guess());

    if (!isNaN(parseInt(date.format('z')))) {
        date = date.utc();
    }

    function areSameDate(d1: Moment, d2: Moment) {
        return d1.year() === d2.year() &&
            d1.month() === d2.month() &&
            d1.date() === d2.date();
    }
    if (window.locale === "pl-pl") {
        dateString = ' ' + date.format('l') + ' ' + date.format(timeZone ? 'LT (z)' : 'LT');
    } else if (window.locale === "nl-nl") {
        dateString = ' ' + date.format(timeZone ? 'LLL (z)' : 'LLL');
    } else {
        dateString = ' ' + date.format(timeZone ? 'lll (z)' : 'lll');
    }
    if (areSameDate(date, now)) {
        dateString = ((locales !== undefined) ? locales['today'] : 'Today');
        dateString += ' ' + date.format(timeZone ? 'LT (z)' : 'LT');
    }
    if (areSameDate(date, now.subtract(1, 'days'))) {
        dateString = ((locales !== undefined) ? locales['yesterday'] : 'Yesterday');
        dateString += ' ' + date.format(timeZone ? 'LT (z)' : 'LT');
    }

    return dateString;
}


export function isToday(date: Moment | Date) {
    if (date instanceof Date) {
        date = moment(date);
    }
    let today = moment(new Date().setHours(0, 0, 0, 0));
    return today.isSame(date, 'days');
}

export function isYesterday(date: Moment) {
    let yesterday = moment(new Date().setDate(new Date().getDate() - 1));
    return yesterday.isSame(date, 'days');
}

export function isHistory(dates: Moment[]) {
    if (dates && dates.length === 2) {
        let dayDiff = dates[1].diff(moment(dates[0]), 'days');
        if (dayDiff > 0) {
            return true;
        } else if (dayDiff === 0 && !isToday(dates[0])) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function removeDuplicate(arr: any, key: any) {
    return arr.reduce((acc: any, current: any) => {
        let x = acc.find((item: any) => item[key] === current[key]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}

export function setCookie(cname: any, cvalue: any, exdays: any) {
    var d = new Date(), expires;
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';

}

export function cloneArrayOfObject(arr: any[]) {
    return arr.map((obj: any) => { return { ...obj } });
}

export function isBizible(offering: any) {
    return offering.id === BIZIBLE_ID;
}

export function shortDate(date: Moment) {
    let localeDateFormat: any;
    if (window.locale === "nl-nl") {
        localeDateFormat = "DD MMMM";
    }
    else if (window.locale === "nb-no" || window.locale === "de-de" || window.locale === "fi-fi") {
        localeDateFormat = "Do MMM";
    }
    else if (window.locale === "pt-br") {
        localeDateFormat = "D MMM";
    }
    else if (window.locale === "pl-pl") {
        localeDateFormat = "DD.MM";
    }
    else if (window.locale === "ja-jp") {
        localeDateFormat = "MMM Do";
    }
    else {
        localeDateFormat = "MMM DD";
    }
    return date.format(localeDateFormat);
}

export function clearPageState(history: any) {
    const currentState: any = history?.location?.state
    if (currentState?.page) {
        let state = { ...currentState };
        delete state.page;
        history.replace({ ...history.location, state });
    }
}

export function hashCode(str: string) {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash.toString();
}

export function toggle_visibility(sidePanelId: any, eventFilterId: any) {
    var sidepanel: any = document.getElementById(sidePanelId);
    var eventFilter: any = document.getElementById(eventFilterId);

    if (sidepanel.style.display === "block") {
        sidepanel.style.display = 'none';
    } else {
        sidepanel.style.display = 'block';
    }
    if (eventFilter.style.display === "block") {
        eventFilter.style.display = 'none';
    } else {
        eventFilter.style.display = 'block';
    }
}
export function filterOngoingCanceledMaitenaceEvents(onGoingEvents: IncidentGroup[]){
    return onGoingEvents.reduce((reducedEvents: IncidentGroup[],currentEvent: IncidentGroup)=>{
        if(currentEvent.type==='canceled_maintenance'){
            let list = currentEvent.list.filter((incident:any)=>incident.previousStatus===Status.Started);
            if(list.length>0){
                currentEvent.list=list;
                reducedEvents.push(currentEvent);
            }
        }else{
            reducedEvents.push(currentEvent);
        }
        return reducedEvents;
    },[]);
}

export function filterEventsByFilter(onGoingEvents: IncidentGroup[], filteredEvents: any[]){
    return onGoingEvents.reduce((reducedEvents: IncidentGroup[],currentEvent: IncidentGroup)=>{
        let list = currentEvent.list.filter((incident:any)=>filteredEvents.includes(incident.incidentId));
            if(list.length>0){
                currentEvent.list=list;
                reducedEvents.push(currentEvent);
            }
        return reducedEvents;
    },[]);
}

export function filterScheduledMaitenaceEvents(onGoingEvents: IncidentGroup[]){
    return onGoingEvents.reduce((reducedEvents: IncidentGroup[],currentEvent: IncidentGroup)=>{
        if(currentEvent.type==='maintenance'){
            let list = currentEvent.list.filter((incident:any)=>incident.status!==Status.Scheduled);
            if(list.length>0){
                currentEvent.list=list;
                reducedEvents.push(currentEvent);
            }
        }else{
            reducedEvents.push(currentEvent);
        }
        return reducedEvents;
    },[]);
}

export function filterEmptyAnnouncements(onGoingEvents: IncidentGroup[]){
    return onGoingEvents.reduce((reducedEvents: IncidentGroup[],currentEvent: IncidentGroup)=>{
        if(currentEvent.type==='announcement' || currentEvent.type==='closed_announcement' ){
            
            if(currentEvent.list.length>0){
                reducedEvents.push(currentEvent);
            }
        }else{
            reducedEvents.push(currentEvent);
        }
        return reducedEvents;
    },[]);
}
export function isCommunication(eventType: string): boolean {
    if ([EventType.cloud.toString(), EventType.global.toString(), EventType.product.toString()].includes(eventType)) {
      return true;
    }
    return false;
  }

export function Subscribed(incident: Incident, subscriptions:any[]) {
    if (subscriptions && subscriptions.length > 0) {

      if (isCommunication(incident.eventType)){
        return (subscriptions.length > 0 && subscriptions.filter((subscription: any) => subscription.eventId === incident.incidentId && ( (subscription.products && subscription.products.length > 0)? subscription.products.includes(incident.productId) : (( subscription.clouds && subscription.clouds.length > 0 && subscription.clouds.includes(incident.cloudName)) ))).length > 0);
      }
      else{
        return (subscriptions.length > 0 && subscriptions.filter((subscription: any) => subscription.eventId === incident.incidentId && subscription.products.includes(incident.productId)).length > 0);
      } 
    }
  }

export function HideFooter(hide:boolean){

var footer:any = document.getElementById("feds-footer");
if(footer){
    footer.style.display=hide?"none":"block";
}

}

