import React from "react";
import { useHistory } from "react-router-dom";
import { SubscriptionType } from "../../proptype";
export default function EmptyMyEventsMessage(props: any) {
  const histroyAPI = useHistory();
  const handleSignIn = () => {
    if (!window.adobeIMS?.isSignedInUser()) {
      window.adobeid.redirect_uri = window.location.href;
      window.adobeIMS.signIn();
      return;
    }
  };
  return (
    <div className="empt_my_events">
      <div className="title">{props.title}</div>
      <p>{props.message}</p>
      {props.type === SubscriptionType.Product ?
        <div className="button">
          <button
            className="button-info"
            onClick={() => {
              histroyAPI.push("/proactive-notifications/manage");
            }}
          >
            <span>{props.buttonText}</span>
          </button>
        </div>
        :
        <div className="button">
          <button className="button-info" onClick={handleSignIn}>
            <span>{props.buttonText}</span>
          </button>
        </div>
      }
    </div>
  );
}
