
import moment from 'moment-timezone';
import { CloudFilterProp, FilterProp, CasCadingFilterProp } from '../proptype';

export class FilterStorage {
    filter: CloudFilterProp;
    cloud: string;
    type: string;
    private static instance: FilterStorage;
    private constructor() {
        this.filter = {};
        this.cloud = 'all';
        this.type = 'cloud';
    }

    public static init(cloud: any) {
        
        let date = moment().format('YYYY-MM-DD'),
            filter: any = localStorage.getItem('filterObj'),
            defaultValue = {
                cloud: {
                    products: [],
                    regions: [],
                    date: [date, date]
                },
                product: {
                    products: "",
                    offerings: [],
                    regions: [],
                    environments: [],
                    date: [date, date]
                }
            };
        filter = JSON.parse(filter || '{}');

        if (filter && !filter[cloud]) {
            filter[cloud] = defaultValue;
        }


        if (!FilterStorage.instance) {
            FilterStorage.instance = new FilterStorage();
            FilterStorage.instance.cloud = cloud;
            FilterStorage.instance.filter = filter;
            localStorage.setItem('filterObj', JSON.stringify(filter));
        }

        return FilterStorage.instance;
    }

    setContext(context: any) {
        
        this.type = context.type || this.type;
        this.cloud = context.cloud || this.cloud;
        if (!this.filter[this.cloud as keyof CloudFilterProp]) {
            this.filter[this.cloud as keyof CloudFilterProp] = {
                cloud: {
                    products: [],
                    regions: [],
                    date: []
                },
                product: {
                    products: "",
                    offerings: [],
                    regions: [],
                    environments: [],
                    date: []
                }
            }
            localStorage.setItem('filterObj', JSON.stringify(this.filter));
        }
    }
    getContext() {
        return {
            type: this.type,
            cloud: this.cloud
        }
    }

    resetAll(cloud: any) {
        let date = moment().format('YYYY-MM-DD'),
            currentProducts = this.filter[cloud]['product']?.products || [];
        cloud = cloud || this.cloud;
        if (this.type === 'product' && !currentProducts.length) {
            currentProducts = "";
        }
        if (cloud) {
            this.filter[cloud] = {};
            this.filter[cloud]['cloud'] = {
                products: [],
                regions: [],
                date: [date, date]
            }
            this.filter[cloud]['product'] = {
                products: currentProducts,
                offerings: [],
                regions: [],
                environments: [],
                date: [date, date]
            }
            localStorage.setItem('filterObj', JSON.stringify(this.filter));
        }
    }

    reset() {
        let date = moment().format('YYYY-MM-DD');
        if (this.type === 'cloud') {
            this.filter[this.cloud][this.type] = {
                products: [],
                regions: [],
                date: [date, date]
            }
        } else {
            let products: any = this.filter[this.cloud as keyof CloudFilterProp][this.type as keyof FilterProp]?.products;
            this.filter[this.cloud as keyof CloudFilterProp][this.type as keyof FilterProp] = {
                products: products,
                offerings: [],
                regions: [],
                environments: [],
                date: [date, date]
            }
        }
        localStorage.setItem('filterObj', JSON.stringify(this.filter));
    }

    isDefault() {
        if (this.filter[this.cloud as keyof CloudFilterProp][this.type as keyof FilterProp]) {
            let currentFilter = this.filter[this.cloud as keyof CloudFilterProp][this.type as keyof FilterProp];
            for (var prop in currentFilter) {
                if (currentFilter.hasOwnProperty(prop)) {
                    if (currentFilter[prop as keyof CasCadingFilterProp] && currentFilter[prop as keyof CasCadingFilterProp].length) {
                        if (prop !== 'products' && prop !== 'date') {
                            return false;
                        }
                    }
                }
            }
            return true;
        }
        return false;
    }

    rebuild(key: any, values: any) {
        let cloudFilter = this.filter[this.cloud as keyof CloudFilterProp],
            filter: CasCadingFilterProp = (this.type === 'product' ? cloudFilter.product! : cloudFilter.cloud!);

        if (filter[key as keyof CasCadingFilterProp]) {
            let val = filter[key as keyof CasCadingFilterProp];

            if (typeof val === 'string' && -1 === values.indexOf(val)) {
                filter[key as keyof CasCadingFilterProp] = [];
            } else {
                val = val.filter((item: any) => {
                    return values.indexOf(item) > -1;
                });
                filter[key as keyof CasCadingFilterProp] = val;
            }
        }
        localStorage.setItem('filterObj', JSON.stringify(this.filter));
        return FilterStorage.instance;
    }

    add(key: any, value: any, type?: any, cloud?: any) {
        return this.push.call(this, key, value, type, cloud);
    }
    remove(key: any, value: any, type?: any, cloud?: any) {
        this.cloud = cloud || this.cloud;
        type = type || this.type;
        let cloudFilter = this.filter[this.cloud as keyof CloudFilterProp],
            filter: CasCadingFilterProp = (type === 'product' ? cloudFilter.product! : cloudFilter.cloud!);

        if (filter[key as keyof CasCadingFilterProp] &&
            filter[key as keyof CasCadingFilterProp].length) {

            let index = filter[key as keyof CasCadingFilterProp][key].indexOf(value);
            if (-1 !== index) {
                filter[key as keyof CasCadingFilterProp][key].splice(index, 1);
            }
        }
        localStorage.setItem('filterObj', JSON.stringify(this.filter));
        return FilterStorage.instance;

    }
    push(key: any, value: any, type?: any, cloud?: any) {
        this.cloud = cloud || this.cloud;
        type = type || this.type;

        let cloudFilter = this.filter[this.cloud as keyof CloudFilterProp],
            filter: CasCadingFilterProp = (type === 'product' ? cloudFilter.product! : cloudFilter.cloud!);

        if (filter[key as keyof CasCadingFilterProp] &&
            filter[key as keyof CasCadingFilterProp].length &&
            -1 === filter[key as keyof CasCadingFilterProp].indexOf(value)) {
            filter[key as keyof CasCadingFilterProp].push(value);
        } else {
            filter[key as keyof CasCadingFilterProp] = [];
            filter[key as keyof CasCadingFilterProp].push(value);
        }
        localStorage.setItem('filterObj', JSON.stringify(this.filter));

        return FilterStorage.instance;
    }

    setValue(key: any, value: any, type?: any, cloud?: any) {
        
        this.cloud = cloud || this.cloud;
        type = type || this.type;

        let cloudFilter = this.filter[this.cloud as keyof CloudFilterProp],
            filter: CasCadingFilterProp = (type === 'product' ? cloudFilter.product! : cloudFilter.cloud!);

        filter[key as keyof CasCadingFilterProp] = value;
        localStorage.setItem('filterObj', JSON.stringify(this.filter));
        return FilterStorage.instance;

    }
    clear(key: string) {
        let cloudFilter = this.filter[this.cloud as keyof CloudFilterProp],
            filter: CasCadingFilterProp = (this.type === 'product' ? cloudFilter.product! : cloudFilter.cloud!);

        if (key) {
            filter[key as keyof CasCadingFilterProp] = [];
        } else {
            filter = {
                products: [],
                regions: [],
                date: []
            }
        }
        localStorage.setItem('filterObj', JSON.stringify(this.filter));
        return FilterStorage.instance;
    }
    getValue() {
        return this.filter[this.cloud as keyof CasCadingFilterProp][this.type as keyof FilterProp];
    }
    destroy() {
        FilterStorage.instance = null!;
        return FilterStorage.instance;
    }
}