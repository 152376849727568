import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import { SubscriptionService } from '../../models/subscription-service';
import Spinner from '../spinner/spinner';
import { ConfigContext } from "../../App";
import { ServiceRegistry } from '../../models/service-registry';
import { EventService } from '../../models/event-service';
import { CreateSubscriptionErrorType, EventType, Incident } from '../../proptype';
import PNSHeader from '../pnsheader/pnsheader';
import { IMS_EVENT, OMEGA_ENV } from '../../constants';
import OMEGA from '../../omega';
import GenericError from '../genericerror/genericerror';
import { AxiosResponse, AxiosError } from 'axios';
import moment from 'moment';
import { HideFooter, isCommunication } from '../../utils';

export default function EventSubscription(props: any) {

    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const { eventId, productId, cloudId }: any = useParams();

    const defaultValue = {
        currentProduct: [],
        cloudName: '',
        productName: '',
        isMaintenance: false,
        isMessage:false,
        incidentId: '',
        displayEmail: '',
        typeOfError: CreateSubscriptionErrorType.ClientError,
        showError: false
    };
    const { search } = useLocation();

    const subscriptionService: SubscriptionService = SubscriptionService.init();
    const [slackSubscriptions, setSlackSubscriptions] = useState<any>({});
    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
    const eventService: EventService = EventService.init();
    const [data, setData] = useState(defaultValue);
    const [disableButton, setDisableButton] = useState(false);
    const [error, setError] = useState(false);
    const [incident, setIncident] = useState<Incident>();
    const [mounted, setMounted] = useState(true);
    const [userTimezone, setUserTimezone] = useState('UTC');
    const [timeZoneInfo, setTimeZoneInfo] = useState(false);
    const histroyAPI = useHistory();
    const locales = useContext(ConfigContext).appConfig.languageData;
    subscriptionService.setLocales(locales);
    useEffect(() => {
        if (window.adobeIMS) {
            getAllData();
        } else {
            document.addEventListener(IMS_EVENT, getAllData);
        }
        return () => {
            setMounted(false);
            document.removeEventListener(IMS_EVENT, getAllData);
        }
    }, []);

    function getAllData() {
        window.scrollTo(0, 0);
        if(window.adobeIMS){
        if (!window.adobeIMS?.isSignedInUser()) {
            window.adobeid.redirect_uri = window.location.href;
            window.adobeIMS.signIn();
            return;
        }
        }

        OMEGA.init().updateAndTrack('page', {
            hierarchy: props.type === 'subscription' ? "home:event_subscription" : "home:event_unsubscription",
            name: props.type === 'subscription' ? "Event subscription - Adobe status" : "Event unsubscription - Adobe status",
            url: encodeURIComponent(window.location.href),
            type: "pns",
            env: OMEGA_ENV
        });

        const [servicePromise, eventPromise, slackSubscriptionPromise]: any = [
            serviceRegistry.fetch(),
            eventService.fetch(),
            subscriptionService.getSlackSubscription()
        ];
        Promise.all([servicePromise, eventPromise, slackSubscriptionPromise, window.adobeIMS.getProfile()])
            .then((result) => {
                if (mounted) {
                    setTimeout(() => window.scrollTo(0, 0), 200)

                    let currentIncident: any = eventService.getIncident(eventId, productId, cloudId),
                    profile = result[3],
                    slackSubscriptionData = result[2].data,
                        isMaintenance = currentIncident?.eventType === 'maintenance',
                        currentProducts: any = Object.values(currentIncident?.products).filter((product: any) => product.id === productId);

                    setData({
                        ...defaultValue,
                        isMaintenance: isMaintenance,
                        isMessage: isCommunication(currentIncident.eventType),
                        incidentId: currentIncident.incidentId,
                        currentProduct: currentProducts,
                        cloudName:currentIncident.cloudName,
                        productName:currentIncident.name,
                        displayEmail: profile.email
                    });
                    setIncident(currentIncident);
                    if (slackSubscriptionData?.channel && slackSubscriptionData?.active) {
                        setSlackSubscriptions(slackSubscriptionData);
                    }

                    setLoading(false);
                }
            }).catch((error) => {
                setError(true);
                setLoading(false);
            });

        let currentTimezone = moment.tz.guess();

        subscriptionService.validateTimeZone(currentTimezone)
            .then((response: AxiosResponse) => response.data)
            .then((response: any) => {
                setUserTimezone(response.timeZone);
            }).catch(() => {
                setUserTimezone(currentTimezone);
            });

    }

    function getLearnMoreLink() {
        const eventServiceInstance: EventService = EventService.init();
        let gnavKey = 'en';
        if (document.querySelector('html')) {
            gnavKey = document.querySelector('html')?.getAttribute('data-gnav-lang-key') || 'en';
        }
        if (locales && locales.sb_future_incidents && gnavKey) {

            let eventType: any = eventServiceInstance.getUrlParameter('eventType');
            let currentLearnMoreLink = locales.sb_future_incidents;

            if (eventType && eventType.toLowerCase() === 'maintenance') {
                currentLearnMoreLink = locales.sb_maintenance_future_incidents;
            }
            if (data.isMessage) {
                currentLearnMoreLink = locales.sb_announcement_future;
            }
            currentLearnMoreLink = currentLearnMoreLink.replace('{lang_url}', gnavKey);
            return currentLearnMoreLink;
        }
        return '';
    }

    function redirctBack(from?: string) {
        let matches = /from=(\d+|pns)/.exec(search);
        if (matches && matches.length) {
            if (matches[1] === "pns") {
                histroyAPI.push({
                    pathname: '/proactive-notifications/manage',
                    state: {
                        page: 'pns',
                        tab: 'event',
                    }
                });
            } else {
                histroyAPI.push({
                    pathname: `/products/${matches[1]}`,
                    state: {
                        tab: "events",
                        eventType: "all",
                        page: "product"
                    }
                })

            }
        } else {
            if (from && from === 'cancel') {
                histroyAPI.push('/');
            } else {
                histroyAPI.push({
                    pathname: '/',
                    state: {
                        page: 'overview'
                    }
                });

            }

        }
    }

    function onSubscribe() { // subscribe or unsubscribe 
        setDisableButton(true);
        setBtnLoading(true);
        subscriptionService.updateSubscription(
            (props.type === "subscription") ? "post" : "delete",
            userTimezone,
            incident,
            (slackSubscriptions && slackSubscriptions.channel)?slackSubscriptions.channel:""
        ).then(() => {
            setDisableButton(false);
            setBtnLoading(false);
            redirctBack();
        }).catch((apiError: AxiosError) => {
            let errorDetail: any = {
                ...data,
                showError: true,
                typeOfError: CreateSubscriptionErrorType.ClientError

            };

            if (props.type === "subscription" &&
                apiError.response?.status &&
                apiError.response?.status >= 500) {

                errorDetail = {
                    ...errorDetail,
                    typeOfError: CreateSubscriptionErrorType.ServerError
                }

            }
            setData(errorDetail);
            setBtnLoading(false);
        });
    }
      
    function showErrorMessage() {
        if (data.showError && data.typeOfError === CreateSubscriptionErrorType.ClientError) {
            return <div className="error-info" onClick={() => { histroyAPI.push('/') }}>{parse(locales.api_error_event_subscription)}</div>
        } else if (data.showError && data.typeOfError === CreateSubscriptionErrorType.ServerError) {
            return <div className="error-info" onClick={() => { histroyAPI.push('/') }}>{parse(locales.subscription_progress_error)}</div>
        } else {
            return <></>
        }

    }

    function renderEventDetail() {
        return loading ?
            <>{HideFooter(true)}<Spinner /> </>:
           <>{HideFooter(false)} <div className="updates-info animate__animated animate__fadeIn">
                {props.type === "unsubscription"
                    ? <>
                        <p className="title">{locales.unsubscribe_updates}</p>
                        {data.isMessage ? <p className="label">{locales.un_future_announcement}</p> : data.isMaintenance ? <p className="label">{locales.un_future_maintenance_incidents}</p> : <p className="label">{locales.un_future_incidents}</p>}
                    </>
                    : <>
                        {data.isMessage ? <p className="title"><>{locales.sb_announcement_future_updates}</></p> : data.isMaintenance ? <p className="title"><>{locales.sb_maintenance_future_updates}</></p> : <p className="title"><>{locales.sb_future_updates}</></p>}
                        <p className="label">{parse(getLearnMoreLink())}</p>
                    </>
                }

                <div className="content">
                    <div className="event-id">
                        <b>{data.isMessage ? <>{locales.announcement}</> : data.isMaintenance ? <>{locales.maintenance_banner}</> : <>{locales.issue}</>} #{data.incidentId}</b>
                    </div>

                    {data.isMessage ? <div className="sub-title">
                        <b>{locales.clouds}: </b>{data.cloudName}
                    </div> :<></>}
                    
                    <div className="sub-title">
                        <b>{locales.products}: </b>
                        {data.isMessage ? data.productName :
                            data.currentProduct.map((product: any, pindex: number) => {
                                return (
                                    data.currentProduct.length === pindex + 1 ? product.name : product.name + ", "
                                )
                            })
                        }
                    </div>

                    <div className="options">
                        <div className="option">
                            <div className="sub-title"><b>{locales.sb_email}</b>: {data.displayEmail} </div>

                        </div>

                        {
                            slackSubscriptions.channel ?
                                <div className='option'>
                                    <div className="sub-title"> <b>{locales.slack} </b>: {slackSubscriptions.channel}</div>
                                </div> : <></>
                        }

                        <div className="option">
                            <div className="sub-title"><b>{locales.timezone}</b>: {userTimezone}<i onClick={() => { setTimeZoneInfo(!timeZoneInfo); }} className="info-icon" /></div>
                            {timeZoneInfo ? <div className="timezone-info">{parse(locales.timezone_info)}</div> : ""}
                        </div>
                    </div>


                </div>
                <div className="actions">
                    <button className="btn cancel-btn" onClick={() => redirctBack('cancel')}>
                        {locales.sb_cancel}
                    </button>
                    <button disabled={disableButton || btnLoading} className={`btn action-btn ${btnLoading ? 'loading' : ''}`} onClick={onSubscribe}>
                        {locales.sb_done}{btnLoading ? <Spinner isButton={true} /> : ''}
                    </button>
                </div>
                {showErrorMessage()}
            </div></>
    }

    return (
        <div className="subscribe-updates">
            <PNSHeader></PNSHeader>
            <div className="container">
                {error ? <GenericError /> : renderEventDetail()}
            </div>
        </div>);
}