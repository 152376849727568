export interface RegistryData {
    clouds: { [key: string]: RegistryCloud };
    products: { [key: string]: RegistryProduct };
    offerings: { [key: string]: RegistryService };
    services: { [key: string]: RegistryService };
    environments: { [key: string]: RegistryEnvironment };
    regions: { [key: string]: RegistryRegion };
}

export interface RegistryCloud {
    id: string;
    name: string;
    cloudProducts: any[];
    active?: boolean;
    isOpen?: boolean;
}

export interface RegistryEnvironment {
    id: string;
    name: string;
    regionId?: string;
    active?: boolean;
}

export interface RegistryService {
    id: string;
    name: string;
    parsedName?: string;
    serviceCode?: string[];
    showEnvironment?: boolean;
    productServices?: any[];
    serviceEnvironments?: any[];
    regions?: any[];
    isEntitled?: boolean;
    serviceDataCenters?: any;
    productOfferings?: any[];
    active?: boolean;
}

export interface RegistryProduct {
    id: string;
    name: string;
    productOfferings?: any[];
    showEnvironment: boolean;
    serviceCode?: any[];
    productServices?: any[];
    serviceEnvironments?: any[];// this is generated value
    regions?: any[];
    eventTypes?: any[];
    partial?: boolean;
    isEntitled?: boolean;
    active?: boolean;
    isOpen?: boolean;
}

export interface RegistryRegion {
    id: string;
    name: string;
}

export enum PNSWIZARD {
    Product = 'product',
    Region = 'region',
    Notification = 'notification',
    Confirm = 'confirm'
}