import React, { useContext, useState } from 'react';
import { RESOLVEDSTATES, SUBMENU } from '../../constants';
import { useHistory } from "react-router-dom";
import { EventService } from '../../models/event-service';
import parse from 'html-react-parser';
import { EventType, History, MessageType, Status, StatusLocaleIndex } from '../../proptype';
import { ConfigContext } from "../../App";

declare global {
    interface Window {
        location: Location,
        adobeid: any
    }
};
export default function EventTile(props: any) {


    const locales = useContext(ConfigContext).appConfig.languageData;
    const histroyAPI = useHistory();
    const eventService: EventService = EventService.init();
    eventService.setLocales(locales);

    const [showHistory, setShowHistory] = useState(false);

    function isCommunication(eventType: EventType): Boolean {
        if ([EventType.global, EventType.cloud, EventType.product].includes(eventType)) {
            return true;
        }
        return false;
    }

    function isCSO(eventType: EventType): boolean {
        return eventType && eventType.toLowerCase() !== 'maintenance' && !isCommunication(eventType);
    }


    function isCMR(eventType: EventType): boolean {
        return eventType && eventType.toLowerCase() === 'maintenance';
    }
    

    function isCustomMessage(messageType: MessageType) {
        return messageType === MessageType.Custom;
    }

    function isResolved(status: Status): boolean {
        return RESOLVEDSTATES.indexOf(status) !== -1;
    }
    function redirectSubscribe(currentItem: any, to: string) {

        let incidentId = currentItem.incidentId,
            subscribeURL = `/${to}/${incidentId}`;

        if (!isCommunication(currentItem.eventType)) {
            subscribeURL += `/${currentItem.productId}`;
        }

        if (EventType.product === currentItem.eventType) {
            subscribeURL += `/${currentItem.productId}`;
        }

        if (EventType.cloud === currentItem.eventType) {
            subscribeURL += `/cloud/${currentItem.cloudId}`;
        }

        let url: any = subscribeURL;
        histroyAPI.push({
            pathname: url,
            search: props.isPns ? `?from=pns` : props.isHistory ? `?from=${currentItem.productId}` : ''
        });

    }

    function showCFS(cfsId: string) {
        if (props.openCFS && typeof props.openCFS === 'function') {
            props.openCFS(cfsId, props.event.incidentId);
        }
    }

    function getMessageCount(): any{
        let items: History[]
        items = eventService.getProductAllMessages(props.event, props.productId);
        return items.length;
    }

    function getLatestMessageList(): History[] {
        if (props.isHistory || showHistory) {
            return eventService.getProductAllMessages(props.event, props.productId);
        } else if (!props.isHistory && !showHistory && isCommunication(props.event.eventType)) {
            let items: History[]
            items = eventService.getProductAllMessages(props.event, props.productId);
            if (items.length > 1)
                return [items[0]];
            return items;
        }
        else {
            return eventService.getProductLastetMessage(props.event);

        }
    }
    function renderDates() {
        if (isCommunication(props.event.eventType)) {
            if (isResolved(props.event.status)) {
                return <div><b>{locales.posted_closed}</b>  {props.event.start} {window.locale === "pl" ? "–" : "-"} {props.event.ended}</div>
            }
            else{
                return props.event.updated ? <div><b>{locales.posted_updated}</b>  {props.event.start} {window.locale === "pl" ? "–" : "-"} {props.event.updated}</div> : <div><b>{locales.posted}</b> {props.event.start}</div>
            }
        }
        if (isResolved(props.event.status)) {
            return <div><b>{locales.started_ended}:</b> {props.event.start} {window.locale === "pl" ? "–" : "-"} {props.event.ended}</div>
        } else if (props.event.status === Status.Scheduled || props.event.status === Status.Started) {
            return <div><b>{locales.expected_duration}</b>  {props.event.start} {window.locale === "pl" ? "–" : "-"} {props.event.ended}</div>
        } else if (props.event.status === Status.Canceled && props.event.previousStatus === Status.Started) {
            return <div><b>{locales.started_canceled}: </b> {props.event.start} {window.locale === "pl" ? "–" : "-"} {props.event.ended}</div>
        } else if (props.event.status === Status.Canceled && props.event.previousStatus === Status.Scheduled) {
            return <div><b>{locales.scheduled_canceled}: </b> {props.event.scheduled} {window.locale === "pl" ? "–" : "-"} {props.event.ended}</div>
        } else {
            return props.event.updated ? <div><b>{locales.started_updated}:</b>  {props.event.start} {window.locale === "pl" ? "–" : "-"} {props.event.updated}</div> : <div><b>{locales.started}:</b> {props.event.start}</div>
        }
    }

    function renderImpactMessage(isLastIndex: boolean): JSX.Element {

        if (props.isHistory) {
            return (isLastIndex) ? <b>{locales.impact_description}</b> : <b>{locales.impact_update}</b>;
        }

        return props.event.updated ? <b>{locales.impact_update}</b> : <b>{locales.impact_description}</b>

    }

    
    function renderCommMessage(isLastIndex: boolean): JSX.Element {

        if (props.isHistory || showHistory) {
            return (isLastIndex) ? <b>{locales.announcement_description}</b> : <b>{locales.announcement_latest_update}</b>;
        }

        return props.event.updated ? <b>{locales.announcement_latest_update}</b> : <b>{locales.announcement_description}</b>

    }

    function renderSubscribe(): JSX.Element {
        if (!isResolved(props.event.status) && !props.error) {
            return (props.subscriptionLoading) ?
                <button disabled={props.subscriptionLoading} className={`pull-right link-subscribe disabled ${isCommunication(props.event.eventType)?"comms":""}` }>
                    <span>{locales.loading}...</span>
                </button> :
                (props.isSubscribed)
                    ? <button className={`pull-right link-unsubscribe subscribed ${isCommunication(props.event.eventType)?"comms":""}`} onClick={() => { redirectSubscribe(props.event, 'unsubscribe') }}>
                        <span>{locales.unsubscribe}</span>
                    </button>
                    : <button className={`pull-right link-subscribe ${isCommunication(props.event.eventType)?"comms":""}`} onClick={() => { redirectSubscribe(props.event, 'subscribe') }}>
                        <span>{locales.subscribe}</span>
                    </button>
        }

        return <></>

    }

    function renderServiceImpacted(history: History) {
        if (history.productOfferings || history.impactedServices) {
            return (
                <div className="impacted-services">
                    <b>{locales.services_impacted}</b> <span>{history.productOfferingsandServices || history.productOfferings || history.impactedServices}</span>
                </div>
            );
        }

        return ''

    }
    function showglobalCommHistory(){

        setShowHistory(true);

    }

    function getCloudName(cloudname: any){
    return SUBMENU.filter((menu: any) => menu.name === cloudname)[0].path;

    }

    function redirectEvent(url: any) {
        histroyAPI.push({
            pathname: url,
            state: {
                tab: "events",
                eventType: "all"
            }
        });
    }
    return (
        <div >
            <div className={`row animate__animated animate__fadeIn collapsible id-${props.event.incidentId} ${props.event.eventType} ${props.event.status} ${props.expandall ? "active" : ""}`} onClick={(e) => {
                var el: any = e.target;
                e.stopPropagation();
                e.preventDefault();
                if (el.className.indexOf('collapsible') !== -1) {
                    if (el.className.indexOf('active') !== -1) {
                        el.className = el.className.replace('active', '');
                    } else {
                        el.className = el.className + ' active';
                    }
                }
            }}>
                <div className="col-md-4 incident-id-container">
                    <div className={`icon-con ${isCommunication(props.event.eventType)?"comms":""}`}>
                        <i className={`event-icon ${ isCommunication(props.event.eventType)? (props.event.status=='Closed'?'closed_announcement':props.event.eventType):props.event.eventType }`}></i>
                    </div>
                    <div className="incident-text-wrap">
                        {isCSO(props.event.eventType) ?
                            <>
                                <div className="incident-product">
                                    <b>{props.event.name}</b>
                                </div>
                                {
                                    isCommunication(props.event.eventType) ?
                                        <div className="incident-id"><b>{locales.announcement} </b>{locales.hash_symbol}{props.event.incidentId}</div> :
                                        <div className="incident-id"><b>{locales.issue} </b>{locales.hash_symbol}{props.event.incidentId}</div>
                                }
                            </> :
                             <>
                                <div className="incident-product">
                                    <b>{props.event.name}</b>
                                </div>
                                {
                                        <div className="incident-id"><b>{locales.maintenance} </b>{locales.hash_symbol}{props.event.incidentId}</div>
                                }
                            </>
                        }
                        {!isCommunication(props.event.eventType) ? <div><b>{locales.incident_status}</b> {locales.status[StatusLocaleIndex[props.event.status]]}</div> : ''}
                    </div>
                </div>
                <div className="col-md-5 vertical-center">
                    <div className="inner-wrapper">
                        {isCSO(props.event.eventType) ? <div><b>{locales.cloud}:</b> {props.event.cloudName}</div> : 
                        <div><b>{locales.cloud}:</b> {props.event.cloudName}</div>
                            }
                        {props.event.regionsInvolved ? <div><b>{locales.incident_region}</b> {props.event.regionsInvolved}</div> : ""}
                        {renderDates()}
                    </div>
                </div>
                <div className="col-md-3">
                    {
                        renderSubscribe()
                    }
                </div>
            </div>
            <div className="message-container">
                {(getLatestMessageList()).map((history: History, hindex: number, messageList: History[]) => {
                    return (
                        
                        <div className="status-message" key={hindex}>
                            <div className="row">
                                <div className={props.isHistory ? "col-md-12" : "col-md-9"}>
                                    {isCommunication(history.eventType) ?
                                        <div className="solution-content">
                                            <div>{history.updatedDate}</div>
                                            <div>{renderCommMessage(messageList.length - 1 === hindex)}{parse(history.text)}</div>
                                        </div>
                                        :
                                        <div className="solution-content">
                                            <div>{history.updatedDate}</div>
                                            {!isCSO(history.eventType) ? <div><b>{locales.impact_type}</b> {locales[props.event.type.toLowerCase() + '_type']}</div> : ''}
                                            {history.severity ? <div><b>{locales.incident_severity}</b> {locales[history.severity.toLowerCase()]}</div> : ''}
                                            <div>
                                                <b>{locales.incident_impact}</b> {history.impactScope}
                                            </div>

                                            <div className="banner-services">
                                                {renderServiceImpacted(history)}
                                                {(history.showEnvironment && history.environmentImpacted) ?
                                                    <div className="environment-impacted">
                                                        <b>{locales.environments_impacted}</b> {history.environmentImpacted}
                                                    </div> :
                                                    ''
                                                }

                                                <div onClick={(e: any) => {
                                                    if (history.cfsId && e.target?.classList?.contains('cfs-link')) {
                                                        showCFS(history.cfsId);
                                                    }
                                                }}>
                                                    {renderImpactMessage(messageList.length - 1 === hindex)}
                                                    {isCustomMessage(history.messageType) ? <>{locales.custom_message}{parse(history.text)}</> : parse(history.text)}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                
                                {
                                
                                    (!props.isHistory && !showHistory && !isCommunication(props.event.eventType)) ?
                                        <div className="col-md-3 link-event">
                                            <button onClick={() => { isCSO(history.eventType) ? redirectEvent(`/products/${props.event.productId}/${props.event.incidentId}`) : redirectEvent(`/products/${history.productId}/${props.event.incidentId}`) }} >
                                                {locales.event_history}
                                            </button>
                                        </div> : (!props.isHistory &&!showHistory&& history.eventType===EventType.cloud)? 
                                        
                                            <div className="col-md-3 link-event">
                                                <button onClick={() => {redirectEvent(`${getCloudName(props.event.cloudName)}/${props.event.incidentId}`)}}>
                                                     {locales.event_history}
                                                </button>
                                            </div>
                                            
                                        :(!props.isHistory && !showHistory&&history.eventType===EventType.product)? 
                                        
                                            <div className="col-md-3 link-event">
                                                <button onClick={() => {redirectEvent(`/products/${props.event.productId}/${props.event.incidentId}`)}}>
                                                     {locales.event_history}
                                                </button>
                                            </div>:(!props.isHistory && !showHistory && history.eventType===EventType.global && getMessageCount()>1)? 
                                        
                                            <div className="col-md-3 link-event">
                                                <button onClick={() => {showglobalCommHistory()}}>
                                                     {locales.event_history}
                                                </button>
                                            </div>:""
                                }

                            </div>
                        </div>
                    );
                })}
            </div>

        </div>
    );
}