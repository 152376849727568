import React, { useEffect, useContext } from 'react';
import { setCookie } from '../../utils';
import { COOKIE_EXPIRY, IMS_EVENT, LOCALEMAP } from '../../constants';
import { ConfigContext } from "../../App";

declare global {
    interface Window {
        languageCodeMap: any,
        constants: any,
        supportedWebLanguages: any
    }
}
function Footer() {

    const locales = useContext(ConfigContext).appConfig.languageData;
    const LANG = window.languageCodeMap;


    useEffect(() => {
        if (window.adobeIMS) {
            setLocale();
        } else {
            document.addEventListener(IMS_EVENT, setLocale);
        }
    }, []);

    function getKeyByValue(object: any, value: any) {
        return Object.keys(object).find(key => object[key] === value);
    }
    function setLocale() {
        let currentLocaleData: any = locales;
        document.title = currentLocaleData.page_header_2;

    }

    function closemodal() {
        var modal = document.getElementById('changeLanguageModal');
        if (modal) {
            modal.style.display = "none";
        }
    }
    function changeLanguage(e: any) {
        var locale = e.target.attributes["data-lang"]["value"],
            localeMatch = window.location.pathname.match(/\/([\w-]+)/);
        var pathname: any = ""
        setCookie('locale', locale, COOKIE_EXPIRY);
        if (localeMatch && localeMatch.length === 2) {
            if (window.supportedWebLanguages.indexOf(localeMatch[1]) !== -1) {
                pathname = window.location.pathname.replace(localeMatch[1], locale);
                window.location.href = window.location.protocol + "//" + window.location.host + pathname + window.location.search;
            } else if (window.supportedWebLanguages.indexOf(getKeyByValue(LOCALEMAP, localeMatch[1])) !== -1) {
                pathname = window.location.pathname.replace(localeMatch[1], locale);
                window.location.href = window.location.protocol + "//" + window.location.host + pathname + window.location.search;
            } else {
                window.location.reload();
            }
        }
        else {
            window.location.reload();
        }
    }

    return (
        <div>
            <div className="modal modal-region modal-frame" id="changeLanguageModal">
                <div className="modal-content">
                    <span className="close" onClick={closemodal}>&times;</span>
                    <div className="modal-body">
                        <div className="grid-cols-12 regions-header">
                            <h2>{locales.change_language}</h2>
                            <p>{locales.change_language_info}</p>
                        </div>
                        <div className="grid-cols-12 regions-content">
                            {LANG?.map((lang: any, index: any) => {
                                return (
                                    <ul key={index}>
                                        {lang.value.map((value: any) => {
                                            return (
                                                <li key={value.key}>
                                                    <span data-lang={value.key} onClick={changeLanguage}>{value.value}</span>
                                                </li>
                                            );

                                        })}
                                    </ul>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
