import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigContext } from "../../App";

export default function PNSHeader(props: any) {
    const historyAPI = useHistory();
    const locales = useContext(ConfigContext).appConfig.languageData;

    return (
        <div className="status-stripe pns-stripe">
            <div className="container">
                <div className="text">{props.noText ? '' : props.helpText?'User Guide':locales.subscriptions_preferences}</div>
                <div className="go-to-overview pull-right"
                    onClick={() => {
                        if (props.manageSubscription) {
                            historyAPI.push("/proactive-notifications/manage")

                        } else {
                            historyAPI.push("/")
                        }
                    }}>
                    <span><span className={props.manageSubscription ? "manage-text" : "home-text"}><i className="icon-home"></i><span>{props.manageSubscription ? locales.manage_subscriptions : locales.home_redirect}</span></span></span>
                </div>
            </div>
        </div>
    )
}