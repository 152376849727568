import React, { useContext } from 'react';
import { ConfigContext } from "../../App";
import PNSHeader from '../pnsheader/pnsheader';
import parse from 'html-react-parser';
import OMEGA from '../../omega';
import { OMEGA_ENV } from '../../constants';



export default function SlackError() {
    const locales = useContext(ConfigContext).appConfig.languageData;
    OMEGA.init().updateAndTrack('page', {
        hierarchy: "home:slack:failed",
        name: "Slack Subscription - Failed",
        URLHash: encodeURIComponent(window.location.href),
        type: "slack",
        env: OMEGA_ENV
    });

    const renderError = () => {
        const URLHash = window.location.hash;
        if (URLHash === '#sc1') {
            return parse(locales.error_message_discription_sc1)
        } else if (URLHash === '#sc8') {
            return parse(locales.error_message_discription_not_supported)
        } else if (URLHash === '#sc9') {
            return parse(locales.error_message_discription_ims_auth_error)
        } else if (URLHash === '#sc10') {
            return parse(locales.error_message_discription_slack_cancelled)
        }
        else {
            return parse(locales.error_message_discription);
        }
    }

    return (
        <div className="slack-error-container">
            <PNSHeader noText="true" manageSubscription="true" />
            <div className="status-slack-error">
                <p className="error-head">{locales.something_went_wrong}</p>
                <div className="error-image"></div>
                <p className="error-discription">{renderError()}</p>
            </div>

        </div>
    );
}