import React, { useContext } from 'react';
import { ConfigContext } from "../../App";
import { URLS } from "../../constants"
import OMEGA from '../../omega';
import parse from 'html-react-parser';

export default function SlackLanding() {
    const locales = useContext(ConfigContext).appConfig.languageData;

    const eventDefaultValue = {
        element: "Add slack Landing Page",
        type: "Button",
        action: "click",
        widget: {
            name: "Slack",
            type: "panel"
        },
        feature: "proactive notification"
    }

    function addToSlack() {

        OMEGA.init().updateAndTrack("event", [{
            ...eventDefaultValue
        }]);
        if (!window.adobeIMS?.isSignedInUser()) {
            window.adobeid.redirect_uri = window.location.href;
            window.adobeIMS.signIn();
            return;
        }
        window.location.href = URLS.ADD_TO_SLACK_URL + "&code=" + window.adobeIMS?.getAccessToken().token;
    }

    return (
        <>
            <div className="slack-landing">
                <div className="slack-banner">
                    <div className="landing-screen-row">
                        <div className="landing-screen-image-column">
                            <div className="landing-screen-image"></div>
                        </div>
                        <div className="landing-screen-text-column">
                            <p className="stay-up-to-date">{locales.stay_up_to_date}</p>
                            <h1>{locales.web_title}</h1>
                            <p className="about-status-adobe">
                                {parse(locales.about_status_adobe)}
                                <div>
                                    <h3><span className='slack-icon'>{parse(locales.about_status_adobe_title)}</span></h3>
                                    <span>{locales.about_status_adobe_p2}</span>
                                </div>
                            </p>

                        </div>
                    </div>
                </div>


                <h1 className="on-boarding-header">{locales.slack_onboarding_header}</h1>
                <div className="landing-onboading-steps-screen-row">
                    <div className="landing-onboading-steps-screen-text-column-step1">
                        <p className='prerequisite'>{parse(locales.slack_onboading_prerequisite)}</p>
                        <p className="step1-text">{locales.slack_onboarding_first_step}</p>
                        <p className="step-header">{locales.slack_onboarding_first_step_header}</p>
                        <ul>
                            <li>{parse(locales.login_to)}</li>
                            <li>{parse(locales.click_on)}</li>
                            <li>{locales.choose_your_pp_and_po}</li>
                            <li>{locales.choose_your_prl_and_et}</li>
                            <li>{locales.select_slack_as_preferrred}</li>
                            <li>{locales.confirm_and_save}</li>
                        </ul>
                    </div>
                    <div className="landing-onboading-steps-screen-image-column-step1">
                        <div className="customize-your-sub-image"></div>
                    </div>
                </div>


                <div className="landing-onboading-steps-screen-row">
                    <div className="landing-onboading-steps-screen-text-column-step2">
                        <p className="step2-text">{locales.slack_onboarding_second_step}</p>
                        <p className="step-header">{locales.slack_onboarding_second_step_header}</p>
                        <ul>
                            <li>{locales.slack_onboarding_setup_slack_step_3_msg}
                                <ul>
                                    <li>{locales.slack_onboarding_setup_slack_step_4_msg}</li>
                                    <li>{locales.slack_onboarding_setup_slack_step_4_sub1}</li>
                                    <li>{locales.slack_onboarding_setup_slack_step_4_sub3}</li>
                                </ul>

                            </li>
                            <li>{locales.slack_onboarding_setup_slack_step_5_msg}</li>
                            <li>{locales.slack_onboarding_setup_slack_step_6_msg}</li>
                            <li>{parse(locales.slack_onboarding_setup_slack_step_7_msg)}</li>
                        </ul>
                    </div>
                    <div className="landing-onboading-steps-screen-image-column-step2">
                        <div className="setup-slack-image"></div>
                    </div>
                </div>

                <div className="landing-onboading-steps-screen-row">
                    <div className="landing-onboading-steps-screen-text-column-step3" >
                        <p className="step3-text">{locales.slack_onboarding_third_step}</p>
                        <p className="step-header">{locales.slack_onboarding_third_step_header}</p>
                        <p className="step-description">{locales.slack_onboarded_notification_message}</p>
                    </div>
                    <div className="landing-onboading-steps-screen-image-column-step3">
                        <div className="notify-image"></div>
                    </div>
                </div>
            </div>
        </>
    )
}