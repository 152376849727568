export interface Message {
    maintainences: any,
    incidents: any,
    globalComm: any,
}
export interface StatusData {
    maintainences: Array<any>,
    incidents: Array<any>,
    messages: Message
}

export interface EventFilterProp {
    onEventFilter: Function
    active: string
}

export interface CasCadingFilterProp {
    products?: any,
    regions?: number[],
    date?: string[],
    eventType?: string,
    environments?: string[],
    offerings?: string[]
}

export enum FilterKey {
    Products = 'products',
    Regions = 'regions',
    Date = 'date',
    EventType = 'eventType',
    Environments = 'environments',
    Offerings = 'offerings'
}

export interface FilterProp {
    cloud?: CasCadingFilterProp,
    product?: CasCadingFilterProp
}
export interface CloudFilterProp {
    [key: string]: FilterProp
}

export interface SelfSubscription {
    success: boolean;
    timeZone: string;
    timezoneOffset: string;
    subscriptions: [];
}

export interface EventSubscription {
    eventId: string;
    products: string[];
    timezone: string;
}
export enum CreateSubscriptionErrorType {
    ClientError = "ClientError",
    ServerError = "ServerError"
}
export enum Status {
    Canceled = "Canceled",
    Closed = "Closed",
    Completed = "Completed",
    Opened = "Opened",
    Scheduled = "Scheduled",
    Reminder = "Reminder",
    Started = "Started",
    Discovery = "Discovery",
    Dismissed = "Dismissed",
    Updated = "Updated",
    None = "None"
}

export enum StatusType {
    Previous = "Previous",
    Latest = "Latest"
}

export enum StatusLocaleIndex {
    Opened = 1,
    Started = 2,
    Updated = 3,
    Closed = 4,
    Canceled = 5,
    Completed = 6,
    Reopened = 7,
    Dismissed = 8,
    Scheduled = 9,
    Discovery = 10
}

export enum MessageType {
    Canned = "Canned",
    Custom = "Custom",
}

export enum CustomerImpact {
    Most = "Most",
    Some = "Some",
    Unknown = "Unknown"
}

export enum OperationsImpact {
    Availability = "Availability",
    Performance = "Performance",
    Unknown = "Unknown",
}

export enum EventType {
    major = "major",
    minor = "minor",
    potential = "potential",
    maintenance = "maintenance",
    trivial = "trivial",
    global = "Global",
    cloud = "Cloud",
    product = "Product",
    announcement = "announcement"
}

export enum Severity {
    Major = "Major",
    Minor = "Minor",
    Potential = "Potential",
    Trivial = "Trivial"
}

export enum SubscriptionType {
    Product = "1",
    Offering = "2"
}

export interface LocationImpact {
    serviceEnvironments: string[];
    serviceRegions: ServiceRegions[];
}

export interface ServiceImpact {
    productServices?: string[];
    productOfferings?: string[];
}

export interface Offering {
    id: string;
    name: string;
    productServices: string[];
}

export interface Service {
    id: string;
    name: string;
    serviceEnvironments?: string[];
}

export enum ServiceRegions {
    Americas = "Americas",
    Apac = "APAC",
    Emea = "EMEA"
}

export interface Region {
    id: number;
    name: ServiceRegions;
}
export interface Regions {
    Americas?: Region;
    EMEA?: Region;
    APAC?: Region;
}
export interface Environment {
    id: string,
    name: string,
    serviceRegions: ServiceRegions
}

export interface Cloud {
    cloudProducts: string[]
    history?: { [key: number]: ServerHistory };
    id: string
    name: string
}

export interface CloudCommunication {
    history?: { [key: number]: CommunicationsHistory };
    id: string
    name: string
}

export interface ServerHistory {
    showEnvironment: boolean;
    id: string; // message id
    status: Status;
    cmrStatus?: Status;
    messageType: MessageType;
    statusTime?: number;
    messageTime: number;
    severity?: Severity;
    customerImpact: CustomerImpact;
    operationsImpact: OperationsImpact;
    messageToken: string;
    titleToken: string;
    serviceImpact: ServiceImpact;
    cfsId?: string;
    locationImpact: LocationImpact;
}

export interface MessageHistory {
    showEnvironment: boolean;
    id: string; // message id
    status: Status;
    messageType: MessageType;
    statusTime?: number;
    messageTime: number;
    severity?: Severity;
    customerImpact: CustomerImpact;
    operationsImpact: OperationsImpact;
    messageToken: string;
    titleToken: string;
    serviceImpact: ServiceImpact;
    cfsId?: string;
    locationImpact: LocationImpact;
}

export interface History {
    serviceImpact: ServiceImpact;
    locationImpact: LocationImpact;
    productName?: string;
    productId?: string;
    eventType: EventType;
    status: Status;
    severity?: Severity;
    showEnvironment: boolean;
    impactScope: string;
    text: string;
    productOfferings?: string;
    productOfferingsandServices?: string;
    impactedServices?: string;
    environmentImpacted?: string;
    messageType: MessageType;
    updatedDate: string;
    messageTime: number;
    cfsId?: string;
    type?: OperationsImpact;
    cmrStatus?: Status;
    startedOn?: number;
    completedOn?: number;
}

export interface Product {
    endedOn: number;
    history: { [key: number]: ServerHistory };
    id: string;
    name: string;
    startedOn: number;
    cloudId?: string;
    cloudName?: string;
}

export interface ProductCommnication {
    endedOn: number;
    history: { [key: number]: CommunicationsHistory };
    id: string;
    name: string;
    startedOn: number;
    cloudId?: string;
    cloudName?: string;
}

export interface ServerIncident {
    id: string;
    status: Status;
    cmrStatus?: Status;
    openedOn: number;
    closedOn?: number;
    completedOn?: number;
    scheduledDate?: number
    type?: string;
    history?: { [key: number]: ServerHistory };
    startedOn?: number;
    severity?: Severity;
    clouds: { [key: string]: Cloud };
    products: { [key: string]: Product };
    offerings?: { [key: string]: Offering };
    services: { [key: string]: Service };
    environments: { [key: string]: Environment };
    regions: { [key: string]: Region };
}

export interface Communications {
    id: string;
    messageType: EventType;
    messageStatus: Status;
    openedOn: number;
    closedOn: number;
    history?: { [key: number]: CommunicationsHistory };
    clouds?: { [key: string]: CloudCommunication };
    products?: { [key: string]: ProductCommnication };
}

export interface CommunicationsHistory {
    messageId: string;
    messageToken: string;
    titleToken: string;
    messageTime: number;
    status: Status;
}

export interface Incident {
    cloudName?: string;
    cloudId?: string;
    clouds: { [key: string]: Cloud };
    endedOn: number;
    environments?: { [key: string]: Environment };
    eventType: string | '';
    history?: { [key: number]: ServerHistory } | null;
    productId?: string;
    incidentId: string;
    name?: string;
    offerings?: { [key: string]: Offering };
    productsAssociated: Service[];
    products?: { [key: string]: Product };
    productsString: string,
    regions?: { [key: string]: Region };
    regionsInvolved: string;
    services: { [key: string]: Service };
    severity?: Severity | '';
    start: string;
    startedOn: number;
    status: Status;
    previousStatus: Status;
    type?: string;
    updatedTime?: number;
    updated: string;
    ended?: string;
    scheduled?: string;
}

export interface IncidentGroup {
    type: string;
    eventType: string;
    list: Incident[];
}

export interface PreferredLanguage {
    [key: string]: string
}

export interface LanguageMap {
    [key: string]: string
}

export interface LocaleData {
    [key: string]: string
}

export interface DropDownProp {
    value: any;
    label: any;
    disabled: boolean;
}

export interface SelfSubscription {
    success: boolean;
    timeZone: string;
    timezoneOffset: string;
    subscriptions: [];
}


export enum SlackStatus {
    None = 0,
    InstallPending = 1,
    Installed = 2,
    RevokePending = 3,
    Revoked = 4
}

export interface APSResponse {
    id: string
    serverId: string
    profileStatus: string
    profileStatusReason: number
    profileStatusReasonText: string
    appLicenseMode: string
    appProfile: AppProfile
    relationshipProfile?: Object[]
    userProfile: UserProfile
    controlProfile: ControlProfile
    frlProfile: string
    legacyProfile: string
    additionalLegacyProfiles: string
}

export interface AppProfile {
    appId: string
    accessibleItems?: AccessibleItem[]
}

export interface AccessibleItem {
    status: string
    source: Source
    fulfillable_items: FulfillableMap
}

export interface Source {
    owner_id: string
    id: string
    type: string
    parent_id: string
    status_reason: string
    can_access_until: number
}

export interface FulfillableMap {
    [key: string]: FulfillableItem
}

export interface FulfillableItem {
    enabled: boolean
    feature_sets?: Object
    charging_model: Object
}

export interface UserProfile {
    userId: string
    firstName: string
    lastName: string
    email: string
    countryCode: string
    accountType: string
    displayName: string
    authId: string
}

export interface ControlProfile {
    nglAppId: string
    requesterNglAppId: string
    deviceId: string
    deviceDate: string
    nglLibRuntimeMode: string
    creationTimestamp: number
    cacheLifetime: number
    validUptoTimestamp: number
    cacheRefreshControl: CacheRefreshControl
    deviceTokenHash: string
    deviceTokenId: string
    osUserId: string
    createdForVdi: boolean
    cacheExpiryWarningControl: CacheExpiryWarningControl
    logControl: LogControl
}

export interface CacheRefreshControl {
    appRefreshInterval: number
    nglLibRefreshInterval: number
}

export interface CacheExpiryWarningControl {
    warningStartTimestamp: number
    warningInterval: number
}

export interface LogControl {
    level: string
    minFileUploadSize: number
    maxFileUploadSize: number
    uploadInterval: number
    uploadOnSessionStart: boolean
    uploadOnError: boolean
}