
import { useParams, useHistory } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { ServiceRegistry } from "../../models/service-registry";
import { EventService } from "../../models/event-service";


export default function HistoryRedirection() {

  const histroyAPI = useHistory();
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(true);
  const { messageId }: { messageId: string } = useParams();
  const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
  const eventService: EventService = EventService.init();



  useEffect(() => {
   
    const [servicePromise, productPromise]: any = [serviceRegistry.fetch(), eventService.fetch()];
    Promise.all([servicePromise, productPromise])
      .then(() => {
        if (mounted) { 
            let {productId, incidentId, cfsExists} = eventService.getProductandEventIdfromMessageId(messageId),
                cfsMatches = window.location.search.match(/cfs=true/),
                path:any = "";
            if(productId && incidentId){
                path = `/products/${productId}/${incidentId}`;
                path = cfsMatches && cfsExists? `${path}?cfs=true`: path;  
            }   
            histroyAPI.push(path || "/");
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
      return () => {
        setMounted(false);
      }
  }, []);

  return (
    <></>
  );
}
