import React, { useContext, useEffect } from 'react';
import './help.scss'
import { useHistory, useLocation } from 'react-router-dom';
import { ConfigContext } from "../../App";
import parse from 'html-react-parser';


function Help() {
    const locales = useContext(ConfigContext).appConfig.languageData;
    const historyAPI = useHistory();
    function handleClick() {
      historyAPI.push("/help/proactivesubscriptions"); 
    }

    useEffect(() => {
      handleClick();
    }, []);


    return (
      <div className="help-landing">
      <div className="help-banner">
          <div className="landing-screen-row">
              <div className="landing-screen-image-column">
                  <div className="landing-screen-image"></div>
              </div>
              <div className="landing-screen-text-column">
                  <p className="stay-up-to-date">{locales.stay_up_to_date}</p>
                  <h1>{locales.web_title}</h1>
                  <p className="about-status-adobe">
                      {parse(locales.about_status_adobe)}
                      <div>
                      <h3><span className='slack-icon'>{parse(locales.proactive_subscriptions)}</span></h3>
                      <span>{locales.proactive_subs_subtext}</span>
                  </div>
                  </p>
                  

              </div>
          </div>
      </div>

      <h1 className="on-boarding-header">{locales.subscribe_proactive_notifications}</h1>
      <div className="landing-onboading-steps-screen-row">
          <div className="landing-onboading-steps-screen-text-column-step1">
              <p className="step1-text">{locales.slack_onboarding_first_step}</p>
              <p className="step-header">{locales.navigate_manage_subscription}</p>
              <ul>
                  <li>{parse(locales.login_to)}</li>
                  <li>{parse(locales.click_manage_subscription)}</li>
                  <li>{locales.sign_in}</li>
              </ul>
          </div>
          <div className="landing-onboading-steps-screen-image-column-step1">
              <div className="customize-your-sub-image"></div>
          </div>
      </div>


      <div className="landing-onboading-steps-screen-row">
          <div className="landing-onboading-steps-screen-text-column-step2">
              <p className="step2-text">{locales.slack_onboarding_second_step}</p>
              <p className="step-header">{locales.select_product_offering}</p>
              <ul>
                  <li>{locales.create_subscription}</li>
                  <li>{locales.expand_cloud_select_product}</li>
                  <li>{locales.select_desired_offering}</li>
              </ul>
          </div>
          <div className="landing-onboading-steps-screen-image-column-step2">
              <div className="setup-slack-image"></div>
          </div>
      </div>

      <div className="landing-onboading-steps-screen-row">
          <div className="landing-onboading-steps-screen-text-column-step3" >
              <p className="step3-text">{locales.slack_onboarding_third_step}</p>
              <p className="step-header">{locales.select_region_event_type}</p>
              <ul>
                  <li>{locales.select_regional_locations}</li>
                  <li>{locales.select_event_types}</li>
                  <ul>
                      <li>{parse(locales.major_service)}</li>
                      <li>{parse(locales.minor_service)}</li>
                      <li>{parse(locales.maintenances_scheduled_window)}</li>
                      <li>{parse(locales.announcement_are_global)}</li>
                  </ul>
                  
                  <li>{locales.certain_products}</li>
              </ul>
          </div>
          <div className="landing-onboading-steps-screen-image-column-step3">
              <div className="notify-image"></div>
          </div>
      </div>

      <div className="landing-onboading-steps-screen-row">
          <div className="landing-onboading-steps-screen-text-column-step4" >
              <p className="step4-text">{locales.proactive_help_forth_step}</p>
              <p className="step-header">{locales.proactive_help_forth_step_header}</p>
              <ul>
                  <li>{locales.select_email_slack}</li>
                  <li>{locales.additional_step}</li>
              </ul>
          </div>
          <div className="landing-onboading-steps-screen-image-column-step4">
              <div className="step4-image"></div>
          </div>
      </div>

      <div className="landing-onboading-steps-screen-row">
          <div className="landing-onboading-steps-screen-text-column-step5" >
              <p className="step5-text">{locales.proactive_help_fifth_step}</p>
              <p className="step-header">{locales.proactive_help_fifth_step_header}</p>
              <ul>
                  <li>{locales.review_subscription}</li>
                  <li>{locales.if_you_select_slack}</li>
                  <li>{locales.receive_notification}</li>
              </ul>
          </div>
          <div className="landing-onboading-steps-screen-image-column-step5">
              <div className="step5-image"></div>
          </div>
      </div>

  </div>
    );
}
  
export default Help;