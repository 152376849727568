import { ConfigContext } from "../../App";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { EventType } from "../../proptype";
import parse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import OMEGA from '../../omega';
export default function Navigation(props:any) {

    const eventDefaultValue = {
        element: "Help - Navigation",
        type: "Button",
        action: "click",
        widget: {
          name: "Help - Navigation ",
          type: "button"
        },
        feature: "Help - Navigation Done Click"
      }

  const handleResize = () => {
    if (window.matchMedia("only screen and (max-width: 767px)").matches) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    if (window.matchMedia("only screen and (min-width:767px) and (max-width: 1000px)").matches) {
      setIsTab(true)
    } else {
      setIsTab(false)
    }
    
  };
  const histroyAPI = useHistory();
  const [navTitle, setNavTitle] = useState("");
  const [navText, setNavText] = useState("");
  const [eventExpand, setEventExpand] = useState(false);
  const [forceCollapse, setForceCollapse] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTab, setIsTab] = useState<boolean>(false);
  const locales = useContext(ConfigContext).appConfig.languageData;

/*NavStep used in the component
1:Banner
2:Manage Subscriptions
3.ALL | My Events
4:Subscribe
5:Expand/Collapse and EventHistory
*/

  useEffect(() => { 
    if(props.eventExpand === true && props.navstep===5){
    setEventExpand(true);
    }else{
    setEventExpand(false);
    }

 window.addEventListener("resize", handleResize);
    setIsMobile(!!window.matchMedia("only screen and (max-width: 767px)").matches);
    setIsTab(!!window.matchMedia("only screen and (min-width:767px) and (max-width: 1000px)").matches);

    switch(props.navstep){
        case 1: setNavTitle(locales.banner);
                setNavText(locales.banner_text);
                break;
        case 3: setNavTitle(locales.all_myevents);
                setNavText(locales.all_events_text+"<br><br><p>"+locales.my_events_text+"</p>");
                break;
        case 2: setNavTitle(locales.manage_subscriptions);
                var text: any = locales.manage_subscription_text || "";
                setNavText(text); 
                break;
    }


  }, []);

 useEffect(() => { 

    var text: any = "";

    if(props.navstep === 4){
        if(!eventExpand){
            setNavTitle(locales.subscribe);
            text = locales.subscribe_text_event;
            setNavText(text); 
        }
    }
    

    if(props.navstep === 5){
         if(!eventExpand){
            setNavTitle(locales.expand_collapse_banner);
            text = locales.expand_collapse_text || "";
            setNavText(text); 
            
        }else{
            setNavTitle(locales.event_history_banner);
            text = locales.event_history_text || "";
            setNavText(text); 
        }
    }
  }, [eventExpand, forceCollapse]);


function scroll(){
    if(props.navstep ===2){
        window.scrollTo(0,0);
    }
     var scroll;
    if((props.eventType === EventType.major || props.eventType === EventType.minor || props.eventType === EventType.potential)){
        if(props?.eventState && props?.eventState?.toString()?.indexOf("resolved")!==-1){
            scroll = document.getElementsByClassName(`${props.eventType} Closed`)
        }
        else{
            scroll = document.getElementsByClassName(`${props.eventType} Opened`);
        }
    }else{ 
         if(props?.eventState&&props?.eventState?.toString()?.indexOf("resolved")!==-1){
            scroll = document.getElementsByClassName(`${props.eventType} Completed`);
         }else{
            scroll = document.getElementsByClassName(`${props.eventType} Started`);
        }
    }

    if(scroll &&scroll.length>0){
        if(eventExpand && !scroll[0].className.includes("active")){
            scroll[0].className += " active";
        } else if(!eventExpand){
            if(scroll[0].className.includes("active"))
                scroll[0].className = scroll[0].className.replace("active","")
        }
        scroll[0].scrollIntoView();
        if((scroll[0].className.includes("active") && scroll[0].className.includes("Started"))|| (scroll[0].className.includes("active") && scroll[0].className.includes("Completed"))){//CMR
            var parent:any = scroll[0].parentNode;
            if(parent){
                var messageContainer:any = parent.getElementsByClassName('status-message')
                if(messageContainer&&messageContainer.length>0){
                    messageContainer[messageContainer.length-1].scrollIntoView();
                }

            }
        }
    }

}


function setStep(){

    var expand = !eventExpand;
   if(props.navstep!==4) {
        setEventExpand(expand);
        if(props.navstep===5){
            props.setExpand(expand)
        }
    } // If it's at subscribe donot expand

     if(props.eventType ){ 
            if(props.navstep === 4){
                props.navstate(props.navstep+1)
                setForceCollapse(true); // If it's at subscribe donot expand and move to expand/collapse
                props.setExpand(false);
            }
            if(!expand){
                props.navstate(props.navstep+1)
            }
    }
    else{ 
        //if it's not an event, then increment navStep
        if((!props.mobilenav&&props.navstep===3 &&props?.resolvedEvents.length>0 && !props.events.length) || (props.mobilenav&&props.navstep===3 &&props?.type.indexOf("resolved")!==-1) ){
            props.navstate(props.navstep+2);
            setForceCollapse(true); 
            props.setExpand(false);
        }
        else{props.navstate(props.navstep+1)}
   }

}
function omega_capture(element: any, widget_name: any, widget_type: any){
    OMEGA.init().updateAndTrack("event", [{
      ...eventDefaultValue,
      element: element,
      widget: {
        name: widget_name,
        type: widget_type
      },
    }]);
  }

function done(){
return <button onClick={()=>{setStep()}}className="btn primary pull-right">{locales.next}</button>
}

function next(){
return <button onClick={()=>{omega_capture("help user guide completed","Help Menu","Navigation Done");props.navstate(0); histroyAPI.push("/")}}className="btn primary pull-right">{locales.sb_done}</button>
}

function renderModal(){

    return(
    <div className={
        isTab===true&&props.navstep===2?"modal-navigation manage-subs":
        isTab===true&&props.navstep===4?"modal-navigation event-subs":
        isTab===true&&props.navstep===5&&!eventExpand?"modal-navigation right expand-collapse":
        isMobile===true&&props.navstep===2?"modal-navigation right":
        isMobile===true&&props.navstep===4?"modal-navigation left subscribe":
        isMobile===true&&props.navstep===5&&!eventExpand?"modal-navigation right expand-collapse":
        props.navstep===3?"modal-navigation left":
        props.navstep===5?eventExpand?"modal-navigation right-history":"modal-navigation right":
        props.navstep===4?"modal-navigation centre":
        "modal-navigation"} >
        {scroll()}
        <div className="navigation-header">{navTitle}</div> 
        <span className="close"></span>
        <div className={props.navstep===4?"navigation-text issue-text":"navigation-text"}>{parse(navText)}</div>
        <div className="nav-button">
            <button onClick={()=>{omega_capture("help user guide canceled","Help Menu","Navigation Cancel"); props.navstate(0); histroyAPI.push("/")}}className="btn">{locales.cancel}</button>    
            {((props.navstep===5 && eventExpand) || //event history
                (props.mobilenav&&props.eventIndex === undefined)|| //mobile with no events
                (!props.mobilenav&&(props.navstep===3||props.navstep===2)&&(props.events&& !props.events.length))) ? //desktop with no events
                (!props.mobilenav&&(props.navstep===3||props.navstep===2) &&props?.resolvedEvents?.length>0)? //desktop with resolved events
                    done():
                    next():
                done()}
        </div>
    </div>
    )
}



  return (
   
   props.navstep!==0?

    <div className="container">  
    <div className={isMobile===true&&(props.navstep===5||props.navstep===2)&&!eventExpand?"row justify-content-end":"row"}>

    {isMobile===true?
        renderModal()
        :
        isTab ===true?
            <div className={props.rightalign?
                            props.navstep===5?"col-md-4 offset-md-8 info-col":
                            props.navstep===4?"col-md-4 offset-md-8 help-col":
                            "col-md-4 offset-md-7 help-col":""}>  
                {renderModal()}
            </div>
        :
        <div className={props.rightalign?
                        props.navstep===5?"col-md-4 offset-md-8 info-col":
                        "col-md-4 offset-md-9 help-col":
                        ""}>  
            {renderModal()}
        </div>

    }

   
    </div>
    </div>
    :<></>
    );
}
