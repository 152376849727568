import React from 'react';
export default function Spinner(props: any) {
    return (
        <div className={props.isButton ? "btn-loader" : "loader"}>
            <svg className="tracker" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r={props.size ? props.size : "20"} fill="none" strokeWidth="3"></circle>
            </svg>
            <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r={props.size ? props.size : "20"} fill="none" strokeWidth="3"></circle>
            </svg>
        </div>
    );
}