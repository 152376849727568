import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { EventService } from '../../models/event-service';
import { ConfigContext } from "../../App";
import { ServiceRegions, SubscriptionType } from '../../proptype';
import { isBizible } from '../../utils';
declare global {
    interface Window {
        location: Location,
        adobeid: any
    }
}
export default function ProductTile(props: any) {

    const locales = useContext(ConfigContext).appConfig.languageData;
    const histroyAPI = useHistory();
    const eventService: EventService = EventService.init();
    eventService.setLocales(locales);
    function joinEvents(events: any) {
        let result: any = "";
        for (var i = 0; i < events.length; i++) {
            result += events[i] === "1" ? locales.event_type_major : events[i] === "2" ? locales.event_type_minor : events[i] === "5" ? locales.event_type_messages : locales.event_type_maintence;
            if (i !== events.length - 1) {
                result += ", ";
            }
        }

        return result;
    }

    function printRegions(regions: any, index?: any) {
        if (regions && regions.length) {
            return (
                <>
                    <div className="rigion-selected">
                        <b>{locales.region_locations}: </b>
                        {regions.sort((a: any, b: any) => a.name.trim().localeCompare(b.name.trim(), undefined, { sensitivity: 'base' })).map((region: any, index: number) => {
                            return (
                                <span key={region.id}>{region.name}{regions.length === index + 1 ? "" : ", "}</span>
                            )
                        })
                        }
                    </div>
                </>
            );
        }
        return <></>
    }

    function concatenateRegions(offerings: any) {

        let allRegions: any = {}, allEnvironments: any = {}, allRegAndEnv = {};
        offerings.forEach((offering: any, index: any) => {
            offering.regions.forEach((region: any, regionIndex: any) => {
                if (!allRegions.length || !(allRegions[regionIndex] && allRegions[regionIndex].name === region.name)) {
                    allRegions[region.id] = region;
                }
            })
            if (isBizible(offering) && offering?.environments?.length) {
                allRegions[1] = {
                    id: 1,
                    name: ServiceRegions.Americas
                };
            } else {
                offering.environments.forEach((env: any, envIndex: any) => {
                    if (!allEnvironments.length || !(allEnvironments[envIndex] && allEnvironments[envIndex].name === env.name)) {
                        allEnvironments[env.id] = env;
                    }
                })
            }
        })
        allRegAndEnv = Object.assign({}, allRegions, allEnvironments);

        return printRegions(Object.values(allRegAndEnv).sort((a: any, b: any) => a.name.localeCompare(b.name)));

    }


    function printProductOfferings(offerings: any, type?: any) {
        if (offerings && offerings.length) {
            offerings = offerings.sort((a: any, b: any) => a.name.localeCompare(b.name));
            return (
                <>
                    <span className="product-offerings">
                        <b>{locales.product_offering}: </b>
                    </span>
                    {offerings.map((offering: any, index: any) => {
                        return (
                            <span key={offering.id}>
                                {offering.name}{offerings.length === index + 1 ? "" : ", "}
                            </span>
                        )
                    })
                    }
                    {type && type === "2" ?
                        concatenateRegions(offerings)
                        : ""}

                </>
            );

        }

        return <></>

    }

    function getProductTiles(subscription: any) {
        return (
            <>
                <div key={subscription.product.id}>
                    <div className="row service relative">
                        <div className="col-md-4">
                            <div className="product-name">{subscription.product.name}</div>
                            <span className="cloud-name">{subscription.cloudName}</span>
                            <hr className="horizontal" />
                        </div>
                        <div className="col-md-7">
                            {subscription.product.type === SubscriptionType.Product
                                ?
                                <>
                                    {printProductOfferings(subscription.productOfferings)}
                                    {printRegions(subscription.regions)}
                                    {printRegions(subscription.environments)}

                                </>
                                : <>
                                    {printProductOfferings(subscription.productOfferings, "2")}
                                </>
                            }
                            <div className="selected-events">
                                <b>{locales.event_types}: </b>{joinEvents(subscription.eventType)}
                            </div>
                        </div>
                        {(props.showAll && props.subscription?.product?.id) ? <div className="col-md-1 "><i className="delete-icon" onClick={() => { histroyAPI.push("/proactive-notifications/product/unsubscribe/" + props.subscription.product.id) }}></i></div> : ""}
                    </div>
                </div>

            </>)
    }

    return (
        <div className="product-list">
            {getProductTiles(props.subscription)}
        </div>
    );
}