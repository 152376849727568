import React, { useEffect, useState, ElementRef, useContext } from 'react';

import { ServiceRegistry } from '../../models/service-registry';
import { EventService } from '../../models/event-service';
import { FilterStorage } from '../../models/filterstorage';
import Select from 'react-select';
import moment from "moment-timezone";
import { LOCALEMAP, REGIONS } from '../../constants';
import { DateRangePicker } from "react-date-range";
import { removeDuplicate } from '../../utils';
import { ConfigContext } from "../../App";
import { DropDownProp } from '../../proptype';
import OMEGA from '../../omega';
const currentLocale: any = LOCALEMAP[window.locale];
moment.locale(currentLocale);

const rdrLocales = require('react-date-range/dist/locale');

export default function Filter(props: any) {
    const eventDefaultValue = {
        element: "Search dropdown",
        action: "change",
        type: "List items",
        feature: props.cloudName + "-" + props.type + " page" + " filters"
    }
    const [state, setState] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }]);
    const [dateRange, setDateRange] = useState([moment(new Date()).format('DD/MM/YYYY'), moment(new Date()).format('DD/MM/YYYY')]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentDateRange, setCurrentDateRange] = useState('today');
    const [services, setServices] = useState<DropDownProp[]>([]);
    const [environments, setEnvironments] = useState<DropDownProp[]>([]);
    const [regions, setRegions] = useState(REGIONS.map((region: any) => {
            return {
                value: region.id, label: region.name
            };
        }));
    const [mounted, setMounted] = useState(true);
    const [serviceAndEnvironements, setServiceAndEnvironements] = useState<any>({});
    const locales = useContext(ConfigContext).appConfig.languageData;

    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
    const eventService: EventService = EventService.init();
    eventService.setLocales(locales);
    const filterInstance = FilterStorage.init(props.cloud);
    const [isDatePickOpen, setIsDatePickOpen] = useState(false)
    const today = new Date();
    const minDate = new Date((new Date()).setDate(today.getDate() - 39));
    const controlRefs: any = {
        productRef: null,
        regionRef: null,
        serviceRef: null,
        environmentRef: null
    };
    const dateFilters = [{
        key: 'today',
        value: locales.current
    }, {
        key: '7_days',
        value: locales.last_7_days
    }, {
        key: '40_days',
        value: locales.last_40_days
    }, {
        key: 'custom',
        value: locales.custom
    }];

    useEffect(() => {
        if (mounted) {
            filterInstance.setContext({
                type: props.type,
                cloud: props.cloud,
            });
            clearAll();
        }
    }, [props.cloud, props.clearAll]);

    useEffect(() => {

        
        if (mounted) {
            if (controlRefs?.serviceRef && props?.defaultOffering) {
                controlRefs.serviceRef.setState({
                    inputValue: "",
                    menuIsOpen: false,
                    value: [{
                        value: props.defaultOffering.id,
                        label: props.defaultOffering.name
                    }]
                });
                filterInstance.setValue('offerings', [props.defaultOffering.id]);
                props.onFilter();
            }

            if (props.updateDate) {
                let filter = filterInstance.getValue();
                if (filter?.date?.length) {
                    let startDate = new Date(filter.date[0]),
                        endDate = new Date(filter.date[1]),
                        _startDate = moment(startDate).format('YYYY-MM-DD'),
                        _endDate = moment(endDate).format('YYYY-MM-DD');
                    setCurrentDateRange('custom');
                    setDateRange([_startDate, _endDate]);
                    setState([{
                        startDate: startDate,
                        endDate: endDate,
                        key: 'selection'
                    }]);
                }
            }
        }
       

    }, [props.defaultOffering, props.updateDate]);

    

    useEffect(() => {
        if (mounted) {
            if(props.customDate &&  props.customDate!==""){
                setCustomDate();
            }  //changed on product page
        }
    },[props.customDate]);

    useEffect(() => {
        if (mounted) {
            if(props.events && props.events.length){
                updateServicesAndEnvs(filterInstance.getValue());

            }

        }
    },[props.events]);

    


    function updateServicesAndEnvs(filter:any){
         if (props.product) {
            let serviceAndEnvironements: any = serviceRegistry.getProductServicesAndEnvironments(props.product);
            setServiceAndEnvironements(serviceAndEnvironements);
            if (serviceAndEnvironements.services.length) {
                setServices(serviceAndEnvironements.services.map((service: any) => { return { value: service.id, label: service.name } }));
            }
            if (serviceAndEnvironements.environments.length) {
                let environments: any = [];
                if (filter?.regions?.length) {
                    let regions = filter?.regions || []
                    environments = serviceAndEnvironements
                        .environments.filter((environment: any) => environment.regionId && environment.regionId.some((region: any) => -1 !== regions.indexOf(region)));
                } else {
                    environments = serviceAndEnvironements.environments;
                }
                
                updateEnvironmentDropdown(environments);
            }
            updateRegionDropdown();
        }
    }
    useEffect(() => {
        

        setLoading(true);
        const [servicePromise]: any = [serviceRegistry.fetchCached()];
        const filter = filterInstance.getValue();
        if (filter?.date?.length) {
            let newDateRange = {
                startDate: new Date(filter.date[0]),
                endDate: new Date(filter.date[1]),
                key: 'selection'
            };

            setState([newDateRange]);
            setDateRange([filter.date[0], filter.date[1]]);
        }
        setCustomDate(true); // redirected from cloud page
        document.addEventListener('click', closeDatePicker);
        servicePromise
            .then(() => {
                if (mounted) {
                   updateServicesAndEnvs(filter);
                    if (filter?.products?.length && controlRefs?.productRef) {
                        controlRefs.productRef.setState({
                            inputValue: "",
                            menuIsOpen: false,
                            value: buildProducts(filter.products)
                        });
                    }
                }
            })
            .catch((error: any) => {
                setLoading(false);
                setError(true);
            });

        return () => {
            setMounted(false);
            document.removeEventListener('click', closeDatePicker)
        }
    }, []);

    function getAllEventEnvironmentsOrRegions(type: string){

        var allEventEnvsOrRegions:any = [];
  
            props.events.forEach((dayEvent: any, index: number) => {
                dayEvent.incidents.forEach((incident: any, hindex: number) => {
                    var incidentinfo = type==="environments" ? incident.environments : incident.regions;
                    if(incidentinfo){
                        Object.values(incidentinfo).forEach((value:any) => {
                            if(!allEventEnvsOrRegions.some((envorregvalue:any) => envorregvalue.id === value.id)){
                                allEventEnvsOrRegions.push(value);
                            }
                        })
                    }
                })
            })
        return allEventEnvsOrRegions;

    }

    function updateEnvironmentDropdown(updatedEnv: any){
      
        var eventEnvs = getAllEventEnvironmentsOrRegions("environments");
        if(updatedEnv && updatedEnv?.length){
            var newenvList = updatedEnv.map((environment: any) => { 
                let disabled:boolean = true;
                if(props.events && props.events.length){
                    
                    eventEnvs.forEach((env: any) => {
                        if(env.id === environment.id){
                            disabled = false;
                        }
                    }) 
                }
                return { value: environment.id, label: environment.name, isDisabled:disabled}
            })
            setEnvironments(newenvList);
        }
    }


    function updateRegionDropdown(){
        var eventRegions = getAllEventEnvironmentsOrRegions("regions");
         if(regions && regions?.length){
            var newregionList = regions.map((region: any) => { 
                let disabled:boolean = true;
                if(props.events && props.events.length){
                    
                    eventRegions.forEach((eventregion: any) => {
                        if(eventregion.id === region.label){
                            disabled = false;
                        }
                    }) 
                }
                return { value: region.label, label: region.value, isDisabled:disabled}
            })
            if(newregionList.length){
                setRegions(newregionList);
            }  
        }
    }

    function setCustomDate(clearEvents?:boolean){
        if(props.customDate && props.customDate!==""){
            setCurrentDateRange('custom');
            updateDate(props.customDate, props.customDate);  
            setState([{
                        startDate: new Date(props.customDate),
                        endDate: new Date(props.customDate),
                        key: 'selection'
                    }]);
            if(clearEvents){
                props.clearEventFilter("all");
            }
        }
    }

    function clearAll(ignoreEvent?: boolean) {
        if (ignoreEvent === false) {
            props.clearEventFilter("all");
        }
        let filter = filterInstance.getValue();
        let newDateRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        };
        Object.values(controlRefs).forEach((controlRef: any) => {
            if (controlRef) {
                controlRef.setState({
                    inputValue: "",
                    menuIsOpen: false,
                    value: []
                });
            }
        });

        if ((filter?.regions?.length || filter?.offerings?.length) && serviceAndEnvironements?.environments) {
            updateEnvironmentDropdown(serviceAndEnvironements.environments);
        }
        if (props.clearOffering && typeof props.clearOffering === 'function') {
            props.clearOffering(null);
        }

        setState([newDateRange]);
        let today = moment().format('YYYY-MM-DD');
        setDateRange([today, today]);
        filterInstance.resetAll(props.cloud);
        setCurrentDateRange('today');
        props.onFilter(ignoreEvent === false ? 'all' : "");

    }

    function closeDatePicker(e: Event) {
        let el: any = e.target;

        if (!el.closest('.date-range')) {
            setIsDatePickOpen(false);
        }
    }

    function getproductNames() {
        let productList = serviceRegistry.getProductList(props.cloud);
        if (productList && productList.length) {
            return productList.map((product: any) => {
                return ({
                    value: product.id, label: product.name
                });
            });
        }
        return [];
    }


    function buildProducts(products: string[]) {
        return serviceRegistry.getProductList(props.cloud).filter((product: any) => {
            return products.includes(product.id);
        }).map((product: any) => {
            return ({
                value: product.id, label: product.name
            });
        });
    }

    function onSelect(e: any, from: string) {
        let filter = filterInstance.getValue();

        if (e.length) {
            e.forEach((item: any) => {
                filterInstance.add(from, item.value);
                OMEGA.init().updateAndTrack("event", [{
                    ...eventDefaultValue,
                    attributes: {
                        item1: item.label
                    },
                    widget: {
                        name: (from === "products" || from === "offerings") ? from + " select" : props.type + " page" + " " + from + " select",
                        type: "form"
                    },
                    search: {
                        term: item.label
                    }
                }]);
            });
        }
        else {
            filterInstance.setValue(from, []);
        }

        if (from === 'offerings') {
            let services: any = e.map((item: any) => item.value);

            if (serviceAndEnvironements?.environments?.length) {
                if (e.length) {
                    let serviceList: any = [], environmentList: any = [];
                    serviceList = serviceAndEnvironements.services.filter((service: any) => -1 !== services.indexOf(service.id))
                    environmentList = serviceList.reduce((environments: any, service: any) => {
                        if (service?.serviceEnvironments?.length) {
                            Array.prototype.push.apply(environments, service.serviceEnvironments);
                        }
                        return environments;
                    }, []);

                    environmentList = removeDuplicate(environmentList, 'id');
                    let regions = filter?.regions;
                    if (regions?.length) {
                        environmentList = environmentList.filter((env: any) => env.regionId.some((region: any) => -1 !== regions?.indexOf(region)))
                    }
                    filterInstance.clear('environments');
                    if (controlRefs.environmentRef) {
                        controlRefs.environmentRef.setState({
                            inputValue: "",
                            menuIsOpen: false,
                            value: []
                        });
                    }
                    updateEnvironmentDropdown(environmentList);
                } else {
                    if (serviceAndEnvironements?.environments?.length && filter?.regions?.length) {
                        let environmentList = serviceAndEnvironements.environments.filter((env: any) => env.regionId.some((region: any) => -1 !== filter?.regions?.indexOf(region)))
                         updateEnvironmentDropdown(environmentList);
                    } else {
                        updateEnvironmentDropdown(serviceAndEnvironements.environments);
                    }
                }

                if (props.clearOffering && typeof props.clearOffering === 'function') {
                    props.clearOffering(null);
                }
            }
        }

        if (from === 'regions' && serviceAndEnvironements?.environments?.length) {
            if (e.length) {
                let regions: any = e.map((item: any) => item.value),
                    environments: any = [], environmentList: any = [];


                if (serviceAndEnvironements?.services?.length && filter?.offerings?.length) {
                    let serviceList = serviceAndEnvironements.services.filter((servcie: any) => -1 !== filter?.offerings?.indexOf(servcie.id));

                    environmentList = serviceList.reduce((environments: any, service: any) => {
                        if (service?.serviceEnvironments?.length) {
                            Array.prototype.push.apply(environments, service.serviceEnvironments);
                        }
                        return environments;
                    }, []);

                    environmentList = removeDuplicate(environmentList, 'id');
                } else {
                    environmentList = serviceAndEnvironements.environments;
                }

                environments = environmentList.filter((environment: any) => environment.regionId && environment.regionId.some((region: any) => -1 !== regions?.indexOf(region)));

                filterInstance.clear('environments');
                if (controlRefs.environmentRef) {
                    controlRefs.environmentRef.setState({
                        inputValue: "",
                        menuIsOpen: false,
                        value: []
                    });
                }
                updateEnvironmentDropdown(environments);
            } else {
                if (serviceAndEnvironements?.services?.length && filter?.offerings?.length) {
                    let serviceList = serviceAndEnvironements.services.filter((servcie: any) => -1 !== filter?.offerings?.indexOf(servcie.id));

                    let environmentList = serviceList.reduce((environments: any, service: any) => {
                        if (service?.serviceEnvironments?.length) {
                            Array.prototype.push.apply(environments, service.serviceEnvironments);
                        }
                        return environments;
                    }, []);

                    environmentList = removeDuplicate(environmentList, 'id');
                    updateEnvironmentDropdown(environmentList);

                } else {
                    updateEnvironmentDropdown(serviceAndEnvironements.environments);
                }
            }
        }
        props.onFilter();
  
    }
    function updateDate(startDate: Date, endDate: Date) {
        let _startDate = moment(startDate).format('YYYY-MM-DD'),
            _endDate = moment(endDate).format('YYYY-MM-DD');
        setDateRange([_startDate, _endDate]);
        filterInstance.clear('date');
        filterInstance.setValue('date', [_startDate, _endDate]);
        props.onFilter();
    }
    function selectedDates(e: any) {
        let startDate: Date = new Date(e.selection.startDate), endDate: Date = new Date(e.selection.endDate);
        setState([e.selection]);
        updateDate(startDate, endDate);
        OMEGA.init().updateAndTrack("event", [{
            ...eventDefaultValue,
            attributes: {
                start_Date: startDate,
                end_Date: endDate
            },
            widget: {
                name: props.type + " page" + " " + "custom select",
                type: "date picker"
            },
            search: {
                term: `startDate: ${startDate} - endDate: ${endDate}`
            }
        }]);
    }

    return (
        <div className="filter-panel">
            <span className="filter-title-panel">
                <div className="clearfix">
                    <span className="filter-title">{locales.filter_by}</span>
                    <a href="#/" onClick={() => { clearAll() }} className="clear-all">{locales.clear_all}</a>
                    <a href="#/" onClick={() => { clearAll(false) }} className="clear-all-mobile">{locales.clear_all}</a>
                </div>
            </span>
            {
                !props.product ?
                    <div data-testid="product-filter"className="control-styles products">
                        <Select
                            ref={(ref: ElementRef<any>) => {
                                if (ref) {
                                    controlRefs.productRef = ref;
                                }
                            }}
                            isMulti
                            placeholder={locales.product_placeholder}
                            options={getproductNames()}
                            onChange={(e: any) => { onSelect(e, 'products') }}
                            noOptionsMessage={() => locales.product_no_item}
                        />
                    </div>
                    : <></>
            }


            {
                services.length ?
                    <div className="control-styles">
                        <Select
                            ref={(ref: ElementRef<any>) => {
                                if (ref) {
                                    controlRefs.serviceRef = ref;
                                }
                            }}
                            isMulti
                            placeholder={locales.offerings_placeholder}
                            options={services}
                            onChange={(e: any) => { onSelect(e, 'offerings') }}
                            noOptionsMessage={() => locales.offerings_no_item}
                        />
                    </div> : <></>
            }

            <div data-testid="region-filter" className="control-styles">
                <Select
                    ref={(ref: ElementRef<any>) => {
                        if (ref) {
                            controlRefs.regionRef = ref;
                        }
                    }}
                    isMulti
                    placeholder={locales.region_placeholder}
                    options={regions}
                    onChange={(e: any) => { onSelect(e, 'regions') }}
                    noOptionsMessage={() => locales.region_no_item}

                />
            </div>

            {
                environments.length ?
                    <div className="control-styles">
                        <Select
                            ref={(ref: ElementRef<any>) => {
                                if (ref) {
                                    controlRefs.environmentRef = ref;
                                }
                            }}
                            isMulti
                            placeholder={locales.environments_placeholder}
                            options={environments}
                            onChange={(e: any) => { onSelect(e, 'environments') }}
                            noOptionsMessage={() => locales.environments_no_item}
                        />
                    </div> : <></>
            }


            <div className="control-styles">
                <div className="date-range">

                    <input readOnly type="text" placeholder="Select Date" value={`${moment(dateRange[0], "YYYY-MM-DD").format('L')} - ${moment(dateRange[1], "YYYY-MM-DD").format('L')}`} onClick={() => {
                        setIsDatePickOpen(!isDatePickOpen);
                    }} />
                    <div className={`picker ${currentDateRange} ${isDatePickOpen ? 'show' : ''}`} >
                        <ul className="date-custom-list">
                            {
                                dateFilters.map((dateFilter: any, index: number) => {
                                    return (
                                        <li key={index} className={currentDateRange === dateFilter.key ? 'selected' : ''} onClick={() => {
                                            let startDate: Date, endDate: Date = new Date();
                                            if (dateFilter.key === '7_days') {
                                                startDate = new Date(new Date().setDate(today.getDate() - 6));
                                            } else if (dateFilter.key === '40_days') {
                                                startDate = new Date(new Date().setDate(today.getDate() - 39));
                                            } else {
                                                startDate = new Date();
                                                endDate = startDate;
                                            }
                                            setCurrentDateRange(dateFilter.key);
                                            if (dateFilter.key !== 'custom') {
                                                setIsDatePickOpen(false);
                                                updateDate(startDate, endDate);
                                                setState([{
                                                    startDate: startDate,
                                                    endDate: endDate,
                                                    key: 'selection'
                                                }]);
                                                OMEGA.init().updateAndTrack("event", [{
                                                    ...eventDefaultValue,
                                                    attributes: {
                                                        start_Date: startDate,
                                                        end_Date: endDate
                                                    },
                                                    widget: {
                                                        name: props.type + " page" + " " + dateFilter.key + " select",
                                                        type: "date picker"
                                                    },
                                                    search: {
                                                        term: `startDate: ${startDate} - endDate: ${endDate}`
                                                    }
                                                }]);
                                            }
                                        }}>{dateFilter.value}</li>
                                    )
                                })
                            }
                        </ul>
                        <div className={currentDateRange === 'custom' ? "date-range clearfix active" : "date-range clearfix"}>
                            <DateRangePicker
                                onChange={selectedDates}
                                minDate={minDate}
                                maxDate={today}
                                inputRanges={[]}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                dateDisplayFormat={currentLocale === "ja" ? "yoMMMdo" : "MMM d, yyyy"}
                                locale={rdrLocales[window.locale === "zh-tw" ? "zhTW" : window.locale === "zh-cn" ? "zhCN" : currentLocale || 'enUS']}
                                staticRanges={[]}
                                ranges={state}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}