import React, { useContext } from 'react';
import { ConfigContext } from "../../App";
import PNSHeader from '../pnsheader/pnsheader';
import OMEGA from '../../omega';
import { OMEGA_ENV } from '../../constants';
import parse from 'html-react-parser';

export default function SlackSuccess() {
    const locales = useContext(ConfigContext).appConfig.languageData;

    OMEGA.init().updateAndTrack('page', {
        hierarchy: "home:slack:success",
        name: "Slack Subscription - Success",
        url: encodeURIComponent(window.location.href),
        type: "slack",
        env: OMEGA_ENV
    });
    return (
        <div className='slack-success-container'>
            <PNSHeader noText="true" manageSubscription="true" />

            <div className="status-slack-success">
                <p className="success-header">{locales.slack_success_confirmation}</p>
                <p className="success-description-f">{locales.slack_success_confirmation_f_discription}</p>
                <div className="success-image"></div>
                <div className="div1">
                    <div className="success-description-s">
                        <p>{parse(locales.slack_success_confirmation_s_discription)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}