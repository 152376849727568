import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { EventService } from '../../models/event-service';
import { ConfigContext } from "../../App";
import { INCIDENTPRIORITIES } from "../../constants";

declare global {
    interface Window {
        location: Location,
        adobeid: any
    }
}
export default function ProductTable(props: any) {

    const locales = useContext(ConfigContext).appConfig.languageData;
    const histroyAPI = useHistory();
    const eventService: EventService = EventService.init();
    eventService.setLocales(locales);

    function isFilteredEvent(eventName: any) {
        if (props.eventFilter === 'all' || eventName.includes(props.eventFilter)) {
            return true;
        }

        return false;
    }

    function getEventsArray(events: any) {
        let eventArray = [], order = ['major', 'minor', 'potential', 'trivial', 'maintenance','announcement', 'major_closed', 'minor_closed', 'potential_closed', 'trivial_closed', 'major_dismissed', 'minor_dismissed', 'potential_dismissed', 'trivial_dismissed','maintenance_completed','closed_announcement','canceled_maintenance'];
        if (events) {

            for (let event in events) {
                if (events.hasOwnProperty(event)) {
                    let count = events[event],
                        localePropKey: any = (
                            event.includes('announcement') ? 'inProgressAnnouncement':
                            event.includes('closed_announcement') ? 'closedAnnouncement':
                            event.includes('completed') ? 'resolved_maintenance'
                                : (event.includes('canceled')) ? 'canceled_maintenance' : (event.includes('closed') || event.includes('dismissed')) ? 'closedCSO'
                                    : event === 'maintenance' ? 'maintenance_event' : 'inProgressCSO') + (count > 1 ? 's' : ''),
                        localizedString: any = locales[localePropKey as keyof typeof locales];



                    let eventType = (event.includes('completed') || event.includes('canceled')) ? 'maintenance' : (event.includes('closed') || event.includes('dismissed')) ? event.split("_")[0] : event;

                    eventArray.push({
                        eventName: event,
                        eventCount: count,
                        eventCountString: (event.includes('completed')) ? localizedString.replace('{resolvedCount}', count) : (event.includes('canceled')) ? localizedString.replace('{canceledCount}', count) : eventType === "maintenance" ? localizedString.replace('{maintenanceCount}', count) : localizedString.replace('{count}', count).replace('{eventType}', eventType === "trivial" ? locales[eventType] : locales[(eventType + '_banner') as keyof typeof locales])
                    });
                }
            }
        }

        eventArray.sort((a, b) => {
            var left = order.indexOf(a.eventName),
                right = order.indexOf(b.eventName);
            return left - right;
        })

        return eventArray;
    }

    function hasValue(obj: any) {
        return (obj && typeof obj === 'object' && Object.keys(obj).length);
    }

    function redirect(service: any, filterDate?:any, eventName?: any, statusName?: any) {
     
        if (props.page === "products") {
            if(filterDate){
                histroyAPI.push({
                pathname: `/products/${service.id}`,
                state: {
                    filterDate: filterDate
                }})
            }else{
                histroyAPI.push(`/products/${service.id}`)
            }
        }
        else {
            props.onSetCustomDate(filterDate);
            props.onRedirect(service);
            props.currentTab("events");
        }
    }


    function renderProductTable() {
        if (props.isHistory) {
            return (
                <div className="col-md-9 product-list-container animate__animated animate__fadeIn">
                    <div className="product-table-scroller">
                        <table className="product-table">
                            <thead>
                                <tr>
                                    <th className="sticky-col no-content">.</th>
                                    {props.productList.historyDates.map((date: string, hindex: number) => {
                                        return <th key={hindex}>{date}</th>
                                    })}
                                    <th>{locales.scheduled_history}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.productList.productHistoryData.map((product: any) => {
                                    return (
                                        <tr key={product.id} className="row-click">
                                            <td className="sticky-col" onClick={() => { redirect(product)}}>
                                                <div className="link valign id-{id}}" >{product.name}</div>
                                            </td>
                                            {product.historyData.map((event: any, eindex: number) => {
                                                return (
                                                    <td key={eindex} onClick={() => { redirect(product, event.filterDate)}}>
                                                        <span className={isFilteredEvent(event.status) ? `event-icon ${event.status}` : `event-icon ${event.status} de-highlight`}  ></span>
                                                    </td>
                                                );
                                            })}

                                            <td className="maintenance-text"  onClick={() => { redirect(product)}} >
                                                {
                                                    product.formattedMaintenanceDate ?
                                                        <span className="maintenance-link">{product.formattedMaintenanceDate}</span> :
                                                        '-'
                                                }

                                            </td>
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>

                </div>
            );
        }

        return (
            <div className="col-md-9 product-list-container animate__animated animate__fadeIn current-data">
                <div className="product-table-scroller">
                    <table className="product-table">
                        <thead>
                            <tr>
                                <th className="sticky-col no-content">.</th>
                                <th>{locales.current}</th>
                                <th className="a-center">{locales.scheduled_history}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.productList.map((product: any, pindex: any) => {
                               
                                return (
                                    <tr className="row-click" key={pindex} onClick={() => { redirect(product) }}>
                                        <td className="sticky-col">
                                            <div className={`link valign id-${product.id}`}>{product.name}</div>
                                        </td>
                                        <td>
                                            <div className="event-list-order">
                                                <ul>
                                                    {
                                                        (hasValue(product.events)) ?

                                                            getEventsArray(product.events).map((eventObj: any, index: number) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <i className={isFilteredEvent(eventObj.eventName) ? `event-icon ${(eventObj.eventName.includes("closed") || eventObj.eventName.includes("dismissed")) ? 'resolved' : (eventObj.eventName.includes("completed")) ? 'resolved_maintenance' : (eventObj.eventName.includes("canceled")) ? 'canceled_maintenance' : eventObj.eventName}` : `event-icon ${((eventObj.eventName.includes("closed")) || (eventObj.eventName.includes("dismissed"))) ? 'resolved' : (eventObj.eventName.includes("completed")) ? 'resolved_maintenance' : (eventObj.eventName.includes("canceled")) ? 'canceled_maintenance': eventObj.eventName} de-highlight`}></i>
                                                                        <span className="text">{eventObj.eventCountString}</span>
                                                                    </li>
                                                                );
                                                            }) :
                                                            <li>
                                                                <i className={isFilteredEvent('available') ? 'event-icon available' : 'event-icon available de-highlight'}></i>
                                                                <span className="text">{locales.available}</span>
                                                            </li>
                                                    }
                                                </ul>
                                            </div>
                                        </td>
                                        <td className="maintenance-text a-center">
                                            {
                                                product.formattedMaintenanceDate ?
                                                    <span className="maintenance-link">
                                                        {product.formattedMaintenanceDate}
                                                    </span> : '-'
                                            }


                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                </div>
            </div>
        );

    }


    return (
        <div className="product-tab-content">
            {renderProductTable()}
        </div>
    );
}