import React, { useEffect, useState, useContext, ElementRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { EventService } from '../../models/event-service';
import { ServiceRegistry } from '../../models/service-registry';
import EventFilter from '../eventfilter/eventfilter';
import moment from 'moment-timezone';
import { FilterStorage } from '../../models/filterstorage';
import EventTile from '../eventtile/eventtile';
import Filter from '../filter/filter';
import { CasCadingFilterProp, EventType, FilterKey, Incident, Status } from '../../proptype';
import Select from 'react-select';
import Spinner from '../spinner/spinner';
import parse from 'html-react-parser';
import { clearPageState, isHistory, isToday, toggle_visibility, HideFooter, Subscribed, isCommunication } from '../../utils';
import { ConfigContext } from "../../App";
import { SubscriptionService } from '../../models/subscription-service';
import ProductTable from '../producttable/producttable';
import { IMS_EVENT, OMEGA_ENV, SUBMENU } from '../../constants';
import BreadCrumbs from '../breadcrumbs/breadcrumbs';
import { AxiosPromise } from 'axios';
import Banner from '../banner/banner';
import ContentLoader from 'react-content-loader';
import OMEGA from '../../omega';
import GenericError from '../genericerror/genericerror';
// import * as PDFJS from "pdfjs-dist/build/pdf";
// import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
const PDFJS = require("pdfjs-dist/build/pdf");
const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry");
declare global {
    interface Window {
        pdfjsLib: any,
        PDFJS:any
    }
}

export default function Events() {

    const eventDefaultValue = {
        element: "Events",
        action: "click",
        type: "List items"
    }
    const MyLoader = (props: any) => (
        <ContentLoader
            speed={2}
            width={150}
            height={47}
            backgroundColor="#f3f3f3"
            foregroundColor="#dcdcdc"
            {...props}
        >
            <rect x="0" y="20" rx="3" ry="3" width="100%" height="20" />
        </ContentLoader>
    );

    const locales = useContext(ConfigContext).appConfig.languageData;
    const updateMenu = useContext(ConfigContext).updateMenu;

    const STATUS_FILTER_VALUES = new Map([
        ['all', {value: 'all', label: locales.all_status}],
        [Status.Opened, {value: Status.Opened, label: locales.status[1]}],
        [Status.Closed, {value: Status.Closed, label: locales.status[4]}],
        [Status.Scheduled, { value: Status.Scheduled, label: locales.status[9]}],
        [Status.Started, {value: Status.Started, label: locales.status[2]}],
        [Status.Completed, { value: Status.Completed, label: locales.status[6]}],
        ]);
    let statusRef: any = null;

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [scheduledMaintenences, setScheduledMaintenences] = useState<Incident[]>([]);
    const [eventFilter, setEventFilter] = useState('all');
    const [statusFilter, setStatusFilter] = useState(STATUS_FILTER_VALUES.get('all'));
    const [productName, setProductName] = useState('');
    const [cloudId, setCloudId] = useState('');
    const [cloudName, setCloudName] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [filterCount, setFilterCount] = useState(0);
    const [loadingSubscription, setLoadingSubscription] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [currentTab, setCurrentTab] = useState("services");
    const [isServices, setIsServices] = useState(false);
    const [isFilterChanged, setFilterChanged] = useState(null);
    const [showCFS, setShowCFS] = useState(false);
    const [cfsError, setCfsError] = useState(false);
    const [cfsLoading, setCfsLoading] = useState(false);
    const [loadHistory, setLoadHistory] = useState(false);
    const [openEvent, setOpenEvent] = useState(false);
    const [lastCFSEvent, setLastCFSEvent] = useState('');
    const histroyAPI = useHistory();
    const [filterClicked, setFilterClicked] = useState(false);
    const [mounted, setMounted] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const [clearAll, setClearAll] = useState(false);
    const [customDate,setCustomDate] = useState("");
    const [selectedOffering, setSelectedOfferings] = useState(null);

    const { eventId, productId }: any = useParams();

    var filterObj: FilterStorage = FilterStorage.init(cloudId);
    const eventService: EventService = EventService.init();
    eventService.setLocales(locales);
    localStorage.removeItem('filterObj');
    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
    const subscriptionService: SubscriptionService = SubscriptionService.init();

    useEffect(() => {


        if (statusRef) {
            let filteredStatus:any = STATUS_FILTER_VALUES.get(statusFilter?.value||'all');
            statusRef.setState({
                inputValue: "",
                menuIsOpen: false,
                value: filteredStatus.length ? filteredStatus[0] : statusFilter
            });
        }
        filterObj = FilterStorage.init(cloudId);
        filterObj.setContext({
            type: 'product',
            cloud: cloudId
        });
        let incidents: any = eventService.getIncidentsByDate(filterObj.getValue() || {
            products: productId,
            date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            offerings: [],
            regions: [],
            environments: []
        }, 'all', eventFilter, statusFilter);

        setEvents(incidents);


    }, [eventFilter, statusFilter, isFilterChanged ]);


    useEffect(() => {
        if ((loadHistory || openEvent) && !loading && typeof eventId === 'string') {

            const _element = document.querySelector(`.id-${eventId}`);
            if (_element) {
                _element.classList.add('active');
                setTimeout(() => {
                    _element.scrollIntoView({
                        behavior: 'smooth'
                    });
                }, 200)
                setOpenEvent(false);
                setLoadHistory(false);
            }

        }
    }, [loadHistory, openEvent, loading]);

    useEffect(() => {
        if (isUpdated) {
            onFilterChange();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    useEffect(() => {
        if(currentTab==="services"){setCustomDate("");}
    }, [currentTab]);


     useEffect(() => {
        if (clearAll) {
            onFilterChange();
            setClearAll(false);
        }
    }, [clearAll]);

    useEffect(() => {
        if (window.adobeIMS) {
            getAllData();
        } else {
            document.addEventListener(IMS_EVENT, getAllData);
        }
        eventService.registerForUpdate(() => {
            setIsUpdated(true);
        })
        return () => {
            setMounted(false);
            document.removeEventListener(IMS_EVENT, getAllData);
        }

    }, []);


    function getAllData() {

        let cfsMatches = window.location.search.match(/cfs=true/)
        const [servicePromise, productPromise]: any = [serviceRegistry.fetch(), eventService.fetch()];
        window.scrollTo(0, 0);

        Promise.all([servicePromise, productPromise])
            .then(() => {

                if (mounted) {
                    if (window.adobeIMS?.isSignedInUser()) {
                        let state: any = histroyAPI.location.state;
                        let safe: boolean = !!state?.page;
                        clearPageState(createBrowserHistory());
                        setLoadingSubscription(true);
                        const subscriptionPromise: AxiosPromise = subscriptionService.getSubscriptions("event", safe);
                        subscriptionPromise
                            .then((result: any) => result.data)
                            .then((result: any) => {
                                let currentSubs: any = result;
                                setSubscriptions(currentSubs);
                                setLoadingSubscription(false);
                            }).catch((error: any) => {
                                setLoadingSubscription(false);
                                setSubscriptionError(true);
                            })
                    }
                    let productName = serviceRegistry.getProductName(productId);
                    setProductName(productName);
                    let hierarchy_web = "";
                    if (window.location.hash) {
                        hierarchy_web = `home:notification:${window.location.hash}`
                    }
                    else {
                        hierarchy_web = `home:cloud:product`
                    }

                    OMEGA.init().updateAndTrack('page', {
                        hierarchy: hierarchy_web,
                        name: productName,
                        url: encodeURIComponent(window.location.href),
                        type: "home",
                        env: OMEGA_ENV
                    });

                    if (window.location.hash) {
                        histroyAPI.push(window.location.pathname);
                    }

                    let cloud: any = serviceRegistry.getCloudId(productId);
                    let currentMenu = SUBMENU.filter((menu: any) => menu.id === cloud);
                    updateMenu(currentMenu[0].name);

                    if (cloud) {
                        filterObj = FilterStorage.init(cloud);
                        filterObj.setContext({
                            type: 'product',
                            cloud: cloud
                        });
                        filterObj.resetAll(cloud);

                        setCloudId(cloud);
                        setCloudName(serviceRegistry.getCloudName(productId));

                        if (eventId) {
                            if (cfsMatches?.length) {
                                let cfsId = eventService.findLatestCFS(eventId, productId);
                                if (cfsId) {
                                    openCFS(cfsId, eventId);
                                }
                            }
                            let incident = eventService.getIncident(eventId);
                            if (incident) {
                                let eventEndDate,
                                    offsetDay = moment(new Date().setDate(new Date().getDate() - 39));

                                if (incident.eventType === EventType.maintenance) {
                                    eventEndDate = incident.endedOn && moment(moment.unix(incident.endedOn).format('YYYY-MM-DD'));
                                } else {
                                    if (incident.products && incident.products[productId]) {
                                        eventEndDate = incident.products[productId].endedOn && moment(moment.unix(incident.products[productId].endedOn).format('YYYY-MM-DD'))
                                    }
                                }

                                if (eventEndDate && [Status.Closed, Status.Completed, Status.Canceled].includes(incident.status) && offsetDay.isSameOrBefore(eventEndDate, 'days')) {
                                    filterObj.setValue('date', [eventEndDate.format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
                                    setLoadHistory(true);
                                }
                                setOpenEvent(true);
                            }
                            setCurrentTab('events');
                        }

                        let filter = filterObj.getValue();
                        if (filter?.date && !filter.date.length) {
                            filterObj.setValue('date', [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
                        }
                        let customHistotyDate: any = moment().format('YYYY-MM-DD');
                          if (histroyAPI.location && histroyAPI.location.state ){
                            var currentstate: any = histroyAPI.location.state;
                                if (currentstate?.filterDate){
                                    customHistotyDate = currentstate.filterDate;
                                    filterObj.setValue('date', [customHistotyDate,customHistotyDate])
                                    setCustomDate(currentstate.filterDate); 
                                    setEventFilter("all");
                                }
                            }
                        filterObj.setValue('products', productId);
                        let incidents: any = eventService.getIncidentsByDate(filterObj.getValue() || {
                            products: productId,
                            date: [customHistotyDate, customHistotyDate ],
                            offerings: [],
                            regions: [],
                            environments: []
                        }, 'all', 'all', statusFilter);
                        let scheduledMaintenances: any = eventService.getScheduledMaintenance(filter || {});
                        setEvents(incidents);
                        setScheduledMaintenences(scheduledMaintenances);
                    }

                    if (serviceRegistry.getOfferingsbyProduct(productId)?.length) {
                        setIsServices(true);
                    } else {
                        setCurrentTab("events");
                    }

                    if (histroyAPI.location && histroyAPI.location.state) {
                        setCurrentTab("events");
                        let state: any = histroyAPI.location.state;
                        let eventType = state.eventType;
                        setEventFilter(eventType);
                        if (state.statusType === Status.Scheduled) {
                            setStatusFilter(STATUS_FILTER_VALUES.get(Status.Scheduled))
                            setEventFilter("all");
                        }
                    }

                  

                    setFilterCount(getFilterCount());

                    setLoading(false);
                    histroyAPI.replace({});

                }
            }).catch(error => {

                setLoading(false);
                setError(true);
            })

        return () => {
            setMounted(false);
        }
    }


    function getFilterCount(_eventFilter?: string) {

        filterObj = FilterStorage.init(cloudId);
        filterObj.setContext({
            type: 'product',
            cloud: cloudId
        });

        let filter: CasCadingFilterProp | undefined = filterObj.getValue(), count = 0;
        if (filter) {
            Object.keys(filter).forEach((key: string) => {
                if (filter && key !== FilterKey.Products && filter[key as keyof CasCadingFilterProp]?.length) {
                    count++;
                }

                if (key === FilterKey.Date && filter?.date?.length) {
                    if (isToday(moment(filter.date[0])) && isToday(moment(filter.date[1]))) {
                        count--;
                    }
                }
            });
        }
        if (_eventFilter) {
            count += _eventFilter !== 'all' ? 1 : 0;
        } else {
            count += eventFilter !== 'all' ? 1 : 0;
        }

        return count;
    }

    function closeCFS() {
        setCfsError(false);
        setShowCFS(false);
        if (lastCFSEvent) {
            let element = document.querySelector('.collapsible.id-' + lastCFSEvent);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        }
    }

    function openCFS(cfsId: string, currentEventId: string) {
        if (!window.adobeIMS?.isSignedInUser()) {
            window.adobeid.redirect_uri = window.location.origin + `/products/${productId}/${eventId}?cfs=true`
            window.adobeIMS.signIn();
            return;
        }
        setCfsLoading(true);
        let pdfData: any = null;
        let isSkmsCfs = eventService.isSkmsCfs(currentEventId, productId);
        eventService.getCFS(cfsId, currentEventId, isSkmsCfs)
            .then((response: any) => response.data)
            .then((cfsObject: any) => {
                pdfData = atob(cfsObject.response.fileContent)

                // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
//                 window.pdfjsLib.GlobalWorkerOptions.workerSrc =
//   require("pdfjs-dist/legacy/build/pdf.js");
                // const pdfjsLib = require("../../../node_modules/pdfjs-dist/legacy/build/pdf.worker");
    // const pdfjsLib = require("pdfjs-dist/legacy/build/pdf.js");
                window.PDFJS = PDFJS;
                 PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
                var loadingTask = PDFJS.getDocument({ data: pdfData });
                loadingTask.promise.then(function (pdf: any) {
                    let canvasHolder: any = document.querySelector('.cfs-container'),
                        oldCanvas = document.querySelector('.cfs-container canvas');

                    // to remove all existing canvas 
                    while (oldCanvas) {
                        canvasHolder.removeChild(oldCanvas);
                        oldCanvas = document.querySelector('.cfs-container canvas');
                    }

                    let generatePDFPage = function (pageNumber: any, pdfObj: any) {
                        pdfObj.getPage(pageNumber).then(function (page: any) {
                            var scale = 1.5;
                            var viewport = page.getViewport({ scale: scale });
                            var canvas = document.createElement('canvas');

                            document.getElementById('the-canvas');
                            var context = canvas.getContext('2d');
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;
                            canvas.classList.add('the-canvas');
                            canvasHolder.appendChild(canvas);

                            var renderContext = {
                                canvasContext: context,
                                viewport: viewport
                            };
                            var renderTask = page.render(renderContext);
                            renderTask.promise.then(function () {
                                if (pageNumber === 1) {
                                    window.scrollTo(0, 0);
                                    setShowCFS(true);
                                    setLastCFSEvent(eventId);
                                    setCfsLoading(false);
                                }
                                if (pageNumber < pdfObj.numPages) {
                                    generatePDFPage(++pageNumber, pdfObj)
                                }
                            });
                        });
                    }

                    generatePDFPage(1, pdf)

                }, function (reason: any) { });

            })
            .catch((error: any) => {
                if (pdfData) {
                    const byteString = pdfData;
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const int8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < byteString.length; i++) {
                        int8Array[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([int8Array], { type: 'application/pdf' });
                    let _navigator: any = window.navigator
                    if (_navigator?.msSaveBlob) {
                        _navigator.msSaveBlob(blob, "cfs-" + eventId + ".pdf");
                    }
                } else {
                    setCfsError(true);
                    setShowCFS(true);
                }
                setCfsLoading(false);
                window.scrollTo(0, 0);
                setLastCFSEvent(eventId);
            });
    }


    function onFilterChange(_eventType?: any) {

        filterObj = FilterStorage.init(cloudId);
        filterObj.setContext({
            type: 'product',
            cloud: cloudId
        });
        filterObj.setValue('products', productId);
        let incidents: any = eventService.getIncidentsByDate(filterObj.getValue() || {}, 'all', 'all', statusFilter);
        setScheduledMaintenences(eventService.getScheduledMaintenance(filterObj.getValue() || {}));
        setFilterChanged(incidents);
        setEvents(incidents);
        setFilterCount(getFilterCount(_eventType));


    }

    function onUpdateStatusFilter(status: any) {
        if (status.value === Status.Scheduled) {
            setEventFilter('all');
            setFilterCount(getFilterCount('all'));

        }
        OMEGA.init().updateAndTrack("event", [{
            ...eventDefaultValue,
            feature: cloudName + "-" + "product page" + " filters",
            attributes: {
                status: status.value
            },
            widget: {
                name: "Status_filter",
                type: "form"
            },
            search: {
                term: status.value
            }
        }]);
        setStatusFilter(status);

    }
    function updateStatusFilter(status: string) {
        setStatusFilter(STATUS_FILTER_VALUES.get(status));
        if (statusRef) {
            statusRef.setState({
                inputValue: "",
                menuIsOpen: false,
                value: STATUS_FILTER_VALUES.get(status)
            });
        }

        OMEGA.init().updateAndTrack("event", [{
            ...eventDefaultValue,
            attributes: {
                status: STATUS_FILTER_VALUES.get(status)
            },
            widget: {
                name: "Status filter",
                type: "form"
            },
            search: {
                term: STATUS_FILTER_VALUES.get(status)
            }
        }]);
    }
    function onUpdateEventFilter(eventType: any) {

        updateStatusFilter('all');
        setEventFilter(eventType);
        setFilterCount(getFilterCount(eventType));

        OMEGA.init().updateAndTrack("event", [{
            ...eventDefaultValue,
            feature: cloudName + "-" + "product page" + " filters",
            attributes: {
                event_Type: eventType
            },
            widget: {
                name: "event_type_filter",
                type: "panel"
            },
            search: {
                term: eventType
            }
        }]);

    }

    function showScheduledEvents() {
        return ((statusFilter && statusFilter.value === 'all') && (eventFilter === 'all' || eventFilter === 'maintenance')) || (statusFilter  && statusFilter.value === Status.Scheduled)
    }

    function renderEventList() {
        HideFooter(false);

        filterObj = FilterStorage.init(cloudId);
        filterObj.setContext({
            type: 'product',
            cloud: cloudId
        });

        let filter = filterObj.getValue(), filterExists = false;
        if ((filter?.regions && filter?.regions?.length > 0) || (filter?.offerings && filter?.offerings?.length > 0) || eventFilter !== "all" || (statusFilter && statusFilter.value !== STATUS_FILTER_VALUES.get('all')?.value)) {
            filterExists = true;
        }
        return (
            <div className="product-list-container">
                {
                    (statusFilter && statusFilter.value !== Status.Scheduled) ?
                        events.map((dayEvent: any, index: number) => {
                            return (
                                <div className="day-container" key={index}>
                                    <h3>{dayEvent.formatedDate}</h3>
                                    {
                                        dayEvent.incidents.map((incident: any, hindex: number) => <EventTile openCFS={openCFS} event={incident} productId={productId} key={hindex} isHistory={true} isSubscribed={Subscribed(incident, subscriptions)} page="event" subscriptionLoading={loadingSubscription} error={subscriptionError} />)
                                    }
                                    {
                                        (!(dayEvent?.incidents?.length)) ? <span className="status-msg"><i className="event-icon available "></i>{filterExists ? <i>{locales.no_event_found}</i> : <i>{locales.all_available}</i>}</span> : ''
                                    }
                                </div>
                            )
                        }) : ''
                }
                {
                    (showScheduledEvents() && scheduledMaintenences.length) ?
                        <div className="day-container scheduled-list" >
                            <h3>{locales.scheduled_history}</h3>
                            {
                                scheduledMaintenences.map((maintenence: any, hindex: number) => <EventTile event={maintenence} productId={productId} key={hindex} isHistory={true} isSubscribed={Subscribed(maintenence,subscriptions)} page="event" subscriptionLoading={loadingSubscription} error={subscriptionError} />)
                            }
                        </div> : ''
                }
                {
                    (statusFilter && statusFilter.value === Status.Scheduled && !scheduledMaintenences.length) ?
                        <div className="day-container scheduled-list" ><h3>{locales.scheduled_history}</h3><span className="status-msg"><i className="event-icon available "></i>{locales.no_cmr_found}</span></div> : ''
                }
            </div>
        );
    }

    function renderProductTable() {

        filterObj = FilterStorage.init(cloudId);
        filterObj.setContext({
            type: 'product',
            cloud: cloudId
        });

        let filter = filterObj.getValue(),
            date = moment().format('YYYY-MM-DD'),
            history: boolean = false;

        if (filter?.date && filter.date.length === 2) {
            history = isHistory([moment(filter?.date[0]), moment(filter?.date[1])]);
        } else {
            filterObj.setValue('date', [date, date])
        }

        let historyList: any = [], offeringData: any = [];


        if (history) {
            historyList = eventService.filterProductsByDate(serviceRegistry, filter || {}, productId, "offering");
        } else {
            offeringData = eventService.filteredOfferings(serviceRegistry, filter || {}, productId);
        }
        return (
            <div>
            <div className="product-tab-content product-list-container comm-container">{renderCommunications(eventFilter, offeringData)}</div>
            <ProductTable
                eventFilter={eventFilter}
                filterObj={filterObj}
                productName={productName}
                onStatusFilter={onUpdateStatusFilter}
                onEventFilter={onUpdateEventFilter}
                onRedirect={setSelectedOfferings}
                currentTab={setCurrentTab}
                onSetCustomDate = {setCustomDate}
                isHistory={history}
                productList={history ? historyList : offeringData}
                page={"services"}
                showCanceledMaintenace={true}
            />
            </div>
        );

    }

    function renderCommunications(_eventType?: any, productList?: any){
        if (_eventType === "announcement" || _eventType === "all"){
            return events.map((dayEvent: any, index: number) => {

                if (dayEvent.formatedDate == "Current"){
                    return dayEvent.incidents.map((incident: any, hindex: number) => {
                        if (isCommunication(incident.eventType) ){
                            return (
                                <div key={hindex}>
                                {
                                    <EventTile event={incident} key={index} isHistory={false} isSubscribed={Subscribed(incident,subscriptions)} subscriptionLoading={loadingSubscription}
                                    />
                                }
                                </div>
                            );
                        }
                    })

                }
            });
        }
    }

    function renderServices() {
        HideFooter(false);
        return (
            <div>
                {renderProductTable()}
            </div>
        );
    }

    function renderServiceFilter() {

        if (!loading && cloudId) {
           
            return (
               <Filter
                    product={productId}
                    type='product'
                    cloudName={cloudName}
                    defaultOffering={selectedOffering}
                    cloud={cloudId}
                    clearAll={clearAll}
                    onFilter={onFilterChange}
                    clearOffering={setSelectedOfferings}
                    updateDate={loadHistory}
                    customDate={customDate}
                    clearEventFilter={setEventFilter}
                    events={events}
                />
            )
        } else {
            return (
                <>
                    <div className="fake-title">{locales.filter_by}</div>
                    <Spinner size="10" />
                </>
            );
        }

    }

    function displayEventFilter() {

        return (
            <div id="event-filter">
                <EventFilter active={eventFilter} onEventFilter={onUpdateEventFilter} />
            </div>
        );

    }

    function renderProductDetail() {
        return (
            currentTab === "events" ?
                <div>
                    <div className="col-md-3 offset-md-9 status-filter">
                        <Select
                            ref={(ref: ElementRef<any>) => {
                                if (ref) {
                                    statusRef = ref;
                                }
                            }}
                            defaultValue={STATUS_FILTER_VALUES.get('all')}
                            placeholder={locales.all_status}
                            options={Array.from(STATUS_FILTER_VALUES.values())}
                            onChange={onUpdateStatusFilter}
                            noOptionsMessage={() => locales.search_no_results}

                        />
                    </div>
                    {loading ? <>{
                        HideFooter(true)
                    }<Spinner /></> :  renderEventList()}
                </div>
                :
                <div>
                    {loading ? <>{
                        HideFooter(true)
                        }<Spinner /> </>: renderServices()}
                </div>
        );
    }

    function getOfferingNames(){
    var filter = filterObj.getValue();
    var offerings="";

    if(filter?.offerings && filter?.offerings?.length>0){
        filter.offerings.forEach((offering:any,index:number,offeringarr:any)=>{
            if(index === offeringarr.length - 1)
            offerings += serviceRegistry.getOfferingNameofOffer(offering);
            else
            offerings += serviceRegistry.getOfferingNameofOffer(offering) + ", ";
        })
    }
    return offerings;

    }

    return (
        <div className="event-wrapper product-page">
            <Banner isParentLoading={loading} id={productId} isProduct={true} productName={productName} allEvents={events} offerings={getOfferingNames()}/>

            <div className={`overlay ${(showCFS || cfsLoading) ? 'active' : ''}`}>
                {cfsLoading ? <Spinner /> : ''}
            </div>
            <div className={`cfs-container ${showCFS ? 'active' : ''}`}>
                <div className="close-cfs" onClick={closeCFS}>×</div>
                <div className={`cfs-error ${cfsError ? 'active' : ''}`} onClick={(e: any) => {
                    if (e.target?.classList?.contains('home-link')) {
                        histroyAPI.push('/');
                    }
                }}>
                    {parse(locales.cfs_error)}
                    <a href="#/" className="close-csf-error" onClick={closeCFS}>×</a>
                </div>
            </div>

            <div className="container">
                {
                    error ? <GenericError /> :
                        <>
                            <BreadCrumbs cloudName={cloudName} productName={productName} isLoading={loading} page={"product"} offerings={getOfferingNames()} productId={productId}  cloudId={cloudId}  clear={setClearAll} currentTab={setCurrentTab}/>
                            {
                                productName ? <h1 className="product-name title">{productName}</h1> : <MyLoader />
                            }
                            <ul className="tabs">
                                {isServices
                                    ? <li className={currentTab === "services" ? `active` : ""} onClick={() => { setCurrentTab("services"); }}>{locales.services_tab}</li>
                                    : ""
                                }
                                <li className={currentTab === "events" ? `active` : ""} onClick={() => { setStatusFilter(STATUS_FILTER_VALUES.get('all'));setCurrentTab("events"); }}>{locales.events_tab}</li>
                            </ul>
                            <div id="filter-button">
                                <button className="btn" onClick={() => { setFilterClicked(!filterClicked); toggle_visibility("side-panel", "event-filter") }}>
                                    <i className={filterClicked || filterCount ? "event-icon filter active" : "event-icon filter"}></i> {filterCount ? <span>({filterCount})</span> : ''}
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-md-3 side-panel">
                                    <div id="side-panel">
                                        <div className="mobile-eventfilter">{displayEventFilter()}</div>
                                        <hr className="mobile-divider" />
                                        {renderServiceFilter()}
                                    </div>
                                </div>
                                <div className="col-md-9 event-container">
                                    <div className="desktop-eventfilter">{displayEventFilter()}</div>
                                    {renderProductDetail()}
                                </div>
                            </div>
                        </>
                }

            </div>
        </div>
    )
}
