import { ConfigContext } from "../../App";
import { EventFilterProp } from '../../proptype'
import React,{ useContext } from 'react';

export default function EventFilter(props: EventFilterProp) {

    const locales = useContext(ConfigContext).appConfig.languageData;

    const eventFilterList: any = [{
        name: locales.all_event,
        cssClassName: 'all'
    }, {
        name: locales.major,
        cssClassName: 'major'
    }, {
        name: locales.minor,
        cssClassName: 'minor'
    }, {
        name: locales.potential,
        cssClassName: 'potential'
    }, {
        name: locales.maintenance,
        cssClassName: 'maintenance'
    },{
        name: locales.announcement,
        cssClassName: 'announcement'
    }];

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="event-filters desktop">
                    <ul className="filter-container">
                        {eventFilterList.map((eventType: any, index: number) =>
                            <li className={props.active === eventType.cssClassName ? `${eventType.cssClassName} event-icon active` : `${eventType.cssClassName} event-icon`} key={index} onClick={() => { props.onEventFilter(eventType.cssClassName); }} >{eventType.name}</li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}
